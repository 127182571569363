import React, { useState, useEffect, useRef } from "react";
import { styles } from "./styleShowProducts";
import { Grid, IconButton, MenuItem, Select } from "@material-ui/core/";
import CardForProduct from "./Card_for_product_redesign";
import CardForProductLine from "./Card_for_product_Line";
// import { BsListTask, MdViewComfy } from "react-icons/all"
import BsListTask from "./icons/lineList/GroupComponent";
import MdViewComfy from "./icons/gridlist/NounMenuComponent";
import SwipeableViews from "react-swipeable-views";
import { useSelector } from "react-redux";
import allStyles from "./showProductsGrid.module.css";
import NoProductsFound from "./noProductsFound";
import "./pagesButtons.css";
import SearchOutlinedIcon from "../searchIcon/GroupComponent";
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ChevronRightIcon from "./icons/arrowright icon/PathComponent";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { getSubArrs, usePrevious } from "../../helpers/Functions";
import {
  PageButtons,
  PaginationOutlined,
} from "../../helpers/helperComponents/helperComponents";
import { Names } from "../../staticTexts";
import FilterIcon from "./icons/filterIcon/NounFilterComponent";
import langs from "../../stylesForLangs/langs.module.css";
import { useLocation } from "react-router-dom";
import FiltersForMobile from "./FiltersforMobile";
import QuickView from "./QuickView/QuickView";
import StickyBox from "react-sticky-box/dist/esnext";
import Chips from "../../helpers/helperComponents/chips/chip";
import { useSpring, animated } from "react-spring";

const theme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "xxs", "sm", "md", "lg", "xl", "xxl"],
    values: { xs: 0, xxs: 350, sm: 568, md: 760, lg: 960, xl: 1200, xxl: 1779 },
  },
});
const GridWithXXL = ({ xxl, ...other }) => {
  const xxlClass = `MuiGrid-grid-xxl-${xxl}`;
  return <Grid className={xxlClass} {...other} />;
};

export default function ShowProducts(props) {
  const classes = styles();
  const [products, setProducts] = useState([]);
  const [productsPages, setProductsPages] = useState([]);
  const [viewMode, setViewMode] = useState(0);
  const productsFromRedux = useSelector((state) => state.products);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [openFilters, setOpenFilters] = useState(false);
  const [quickView, setQuickView] = useState({ bool: false, product: null });
  const [sortOrder, setSortOrder] = useState("NewToOld");
  const [sortValues, setSortValues] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const searchRef = useRef("");
  const searchRefsm = useRef("");
  const chipsContRef = useRef();
  const mobileChpsRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productsFromRedux) {
      setLoading(true);
      let filtered = JSON.parse(JSON.stringify(productsFromRedux));
      if (props.id) {
        filtered = filtered.filter((product) => {
          return (
            product.parentId === props.id ||
            props.clickedChildCategories.includes(product.parentId)
          );
        });
      } else if (location.parentId) {
        filtered = filtered.filter((product) => {
          return (
            product.parentId === location.parentId ||
            props.clickedChildCategories.includes(product.parentId)
          );
        });
      }
      if (props.selectedBrand.length) {
        filtered = filtered.filter((product) => {
          return props.selectedBrand.includes(product.brand);
        });
      }
      if (props.selectedBadge.length) {
        filtered = filtered.filter((product) => {
          return props.selectedBadge.includes(product.badge);
        });
      }
      if (props.searchWords && props.searchWords.length) {
        filtered = filtered.filter((product) => {
          return props.searchWords.some((item) =>
            product.name[props.flag]
              .toLocaleLowerCase()
              .includes(item.toLocaleLowerCase())
          );
          // return product.name[props.flag]
          //   .toLocaleLowerCase()
          //   .includes(props.searchWord.toLocaleLowerCase());
        });
      }
      setPage(0);
      setTimeout(() => {
        setLoading(false);
        switch (sortOrder) {
          case "NewToOld":
            setProducts(filtered.sort((a, b) => b.id - a.id));
            break;
          case "OldToNew":
            setProducts(filtered.sort((a, b) => a.id - b.id));
            break;
          case "DESC":
            setProducts(filtered.sort((a, b) => b.productPrice - a.productPrice));
            break;
          case "ASC":
            setProducts(filtered.sort((a, b) => a.productPrice - b.productPrice));
            break;
          default:
            break;
        }
      }, 400);
    }
  }, [
    productsFromRedux,
    props.selectedBrand,
    props.selectedBadge,
    props.searchWords,
    props.id,
    location.parentId,
  ]);

  useEffect(() => {
    if (sortOrder && products && products.length) {
      let clone = JSON.parse(JSON.stringify(products));
      switch (sortOrder) {
        case "NewToOld":
          setProducts(clone.sort((a, b) => b.id - a.id));
          break;
        case "OldToNew":
          setProducts(clone.sort((a, b) => a.id - b.id));
          break;
        case "DESC":
          setProducts(clone.sort((a, b) => b.productPrice - a.productPrice));
          break;
        case "ASC":
          setProducts(clone.sort((a, b) => a.productPrice - b.productPrice));
          break;
        default:
          break;
      }
    }
  }, [sortOrder]);

  useEffect(() => {
    if (viewMode === 0) {
      setProductsPages(getSubArrs(products, 20));
    } else if (viewMode === 1) {
      setProductsPages(getSubArrs(products, 13));
    }
  }, [viewMode, products]);

  useEffect(() => {
    setSortValues([
      { name: Names.NewToOld, value: "NewToOld" },
      { name: Names.OldToNew, value: "OldToNew" },
      { name: Names.ASC, value: "ASC" },
      { name: Names.DESC, value: "DESC" },
    ]);
  }, [props.flag]);

  function handleSearchconfirm(e, type, ref) {
    let x = e ? e.which || e.keyCode : 0;
    // let inpVal = e ? e.target.value : inputVal
    if (
      (type === "click" || x === 13) &&
      ref.current.value &&
      !props.searchWords.includes(ref.current.value)
    ) {
      let clone = JSON.parse(JSON.stringify(props.searchWords));
      clone.push(ref.current.value);
      props.setSearchWords(clone);
      ref.current.value = "";
    }
  }

  useEffect(() => {
    console.log(`chipsContRef`, chipsContRef);
  }, [chipsContRef]);

  return (
    <section className={allStyles.section}>
      <QuickView flag={props.flag} open={quickView} setOpen={setQuickView} />
      <div classname={allStyles.filtersMobile}>
        <FiltersForMobile
          clickedChildCategories={props.clickedChildCategories}
          setClickedChildCategories={props.setClickedChildCategories}
          currentCategory={props.currentCategory}
          setCurrentCategory={props.setCurrentCategory}
          id={props.id}
          setId={props.setId}
          selectedBrand={props.selectedBrand}
          setSelectedBrand={props.setSelectedBrand}
          selectedBadge={props.selectedBadge}
          setSelectedBadge={props.setSelectedBadge}
          flag={props.flag}
          open={openFilters}
          setOpen={setOpenFilters}
        />
      </div>
      <section
        className={classes.section}
        style={{
          height:
            productsPages && productsPages.length > 1
              ? "calc(100% - 40px)"
              : "100%",
        }}
      >
        {/* <div
          style={{ display: "flex", alignItems: "flex-start", height: "100%" }}
        > */}
        <div style={{ width: "100%", margin: "3px 0 0 0", height: "100%" }}>
          <StickyBox offsetTop={120} offsetBottom={10}>
            <div className={allStyles.productModes}>
              <span className={allStyles.showMode}>
                <button
                  className={allStyles.filterButtonfortablet}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenFilters(true);
                  }}
                >
                  <FilterIcon />
                  &nbsp; &nbsp;
                  {Names.filter[props.flag]}
                </button>
                {/* <button className={viewMode === 0 ? allStyles.button : allStyles.buttonnot} onClick={() => { setPage(0); setViewMode(0) }}>
                            <MdViewComfy viewMode={viewMode} />
                        </button>
                        <button className={viewMode === 1 ? allStyles.button : allStyles.buttonnot} onClick={() => { setPage(0); setViewMode(1) }} >
                            <BsListTask viewMode={viewMode} />
                        </button> */}
              </span>
            </div>
            <span
              className={`${allStyles.pageName} ${
                props.flag === "am"
                  ? langs.armenianHeaders600
                  : langs.foreignHeaders600
              }`}
            >
              {Names.products[props.flag]}
            </span>
            <div className={allStyles.forSm}>
              <div className={allStyles.smFilters}>
                <div className={allStyles.filterByPrice}>
                  <span className={allStyles.selectLabel}>
                    {Names.sort[props.flag]}
                  </span>
                  <div className={allStyles.select}>
                    <Select
                      value={sortOrder}
                      disableUnderline
                      // margin="dense"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSortOrder(e.target.value);
                      }}
                      // displayEmpty
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {sortValues.map((item) => (
                        <MenuItem value={item.value}>
                          <span style={{ marginRight: "20px" }}>
                            {item.name[props.flag]}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <button
                  className={allStyles.filterButton}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenFilters(true);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 4V6H20L15 13.5V22H9V13.5L4 6H3V4H21ZM6.404 6L11 12.894V20H13V12.894L17.596 6H6.404Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp; &nbsp;
                  {Names.filter[props.flag]}
                </button>
              </div>
              <div
                className={`${allStyles.searchBar} ${allStyles.searchInputContainer}`}
              >
                <input
                  className={allStyles.searchInput}
                  placeholder={
                    props.flag === "us"
                      ? "Search..."
                      : props.flag === "ru"
                      ? "Поиск..."
                      : "Որոնել..."
                  }
                  ref={searchRefsm}
                  onKeyPress={(e) => {
                    handleSearchconfirm(e, "", searchRefsm);
                  }}
                  // value={searchWord}
                  // onChange={(e) => {
                  //   setSearchWord(e.target.value);
                  // }}
                />
                <IconButton
                  style={{
                    outline: "none",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleSearchconfirm(undefined, "click", searchRefsm);
                  }}
                  size="small"
                >
                  <SearchOutlinedIcon />
                </IconButton>
              </div>
            </div>
            <div className={allStyles.filtersSearched}>
              <div style={{ fontSize: "25px" }} ref={chipsContRef}>
                {/* {props.currentCategory && props.currentCategory.name
                  ? props.currentCategory.name[props.flag]
                  : location.name
                  ? location.name
                  : props.flag === "us"
                  ? "All"
                  : props.flag === "ru"
                  ? "Все"
                  : "Բոլորը"} */}
                <Chips
                  searchs={props.searchWords}
                  setSearchs={props.setSearchWords}
                  brands={props.selectedBrand}
                  setBrands={props.setSelectedBrand}
                  clickedChildCategories={props.clickedChildCategories}
                  badges={props.selectedBadge}
                  setBadges={props.setSelectedBadge}
                  categories={props.currentCategory}
                  setCategories={props.setCurrentCategory}
                  setClickedChildCategories={props.setClickedChildCategories}
                  flag={props.flag}
                  setId={props.setId}
                />
              </div>
              <div className={allStyles.sortSearchContainer}>
                <div className={allStyles.filterByPrice}>
                  <span className={allStyles.selectLabel}>
                    {Names.sort[props.flag]}
                  </span>
                  <div className={allStyles.select}>
                    <Select
                      value={sortOrder}
                      disableUnderline
                      // margin="dense"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSortOrder(e.target.value);
                      }}
                      // displayEmpty
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {sortValues.map((item) => (
                        <MenuItem value={item.value}>
                          <span style={{ marginRight: "20px" }}>
                            {item.name[props.flag]}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  className={`${allStyles.searchBar} ${allStyles.searchInputContainer}`}
                >
                  <input
                    className={allStyles.searchInput}
                    placeholder={
                      props.flag === "us"
                        ? "Search..."
                        : props.flag === "ru"
                        ? "Поиск..."
                        : "Որոնել..."
                    }
                    ref={searchRef}
                    onKeyPress={(e) => {
                      handleSearchconfirm(e, "", searchRef);
                    }}
                    // value={searchWord}
                    // onChange={(e) => {
                    //   setSearchWord(e.target.value);
                    // }}
                  />

                  <IconButton
                    style={{
                      outline: "none",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      handleSearchconfirm(undefined, "click", searchRef);
                    }}
                    size="small"
                  >
                    <SearchOutlinedIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <div
              ref={mobileChpsRef}
              className={`${allStyles.categoryAsHeader}`}
            >
              <Chips
                searchs={props.searchWords}
                setSearchs={props.setSearchWords}
                brands={props.selectedBrand}
                setBrands={props.setSelectedBrand}
                clickedChildCategories={props.clickedChildCategories}
                badges={props.selectedBadge}
                setBadges={props.setSelectedBadge}
                categories={props.currentCategory}
                setCategories={props.setCurrentCategory}
                setClickedChildCategories={props.setClickedChildCategories}
                flag={props.flag}
                setId={props.setId}
              />
            </div>
            <div className={allStyles.allProductsContainer}>
              {/* <SwipeableViews
                index={viewMode}
                slideStyle={{
                  overflow: "hidden",
                  position: "sticky",
                  top: "55px",
                }}
              > */}
              {/* {viewMode === 0 ? ( */}
              <SwipeableViews
                enableMouseEvents
                index={page}
                slideStyle={{ overflow: "hidden" }}
              >
                {products.length ? (
                  productsPages.map((productarr) => {
                    // if (productsPages.length > 1) {
                    //   return (
                    //     <MuiThemeProvider theme={theme}>
                    //       <Grid key={page} container spacing={0}>
                    //         {productarr.map((product) => {
                    //           return (
                    //             <GridWithXXL
                    //               key={Math.random()}
                    //               container
                    //               justify="center"
                    //               item
                    //               xxl={3}
                    //               lg={3}
                    //               md={3}
                    //               sm={3}
                    //               xs={6}
                    //               xxs={12}
                    //             >
                    //               <div
                    //                 key={product.id}
                    //                 className={allStyles.cardContainerForHover}
                    //               >
                    //                 <CardForProduct
                    //                   setQuickView={setQuickView}
                    //                   product={product}
                    //                   flag={props.flag}
                    //                 />
                    //               </div>
                    //             </GridWithXXL>
                    //           );
                    //         })}
                    //       </Grid>
                    //     </MuiThemeProvider>
                    //   );
                    // } else {
                      return (
                        <MuiThemeProvider theme={theme}>
                          <Grid key={page} container spacing={0}>
                            {productarr.map((product) => {
                              return (
                                <CardContainer
                                  setQuickView={setQuickView}
                                  product={product}
                                  flag={props.flag}
                                  loading={loading}
                                  productarr={productarr}
                                  productsPages={productsPages}
                                  page={page}
                                />
                              );
                            })}
                          </Grid>
                        </MuiThemeProvider>
                      );
                    // }
                  })
                ) : (
                  <NoProductsFound flag={props.flag} />
                )}
                {/* </SwipeableViews> */}
                {/* ) : (
                  <></>
                )} */}

                {/* {viewMode === 1 ? (
                  <SwipeableViews
                    enableMouseEvents
                    index={page}
                    slideStyle={{ overflow: "hidden" }}
                  >
                    {products.length ? (
                      productsPages.map((productarr) => {
                        return (
                          <div style={{ padding: "0px 5px" }}>
                            {productarr.map((product) => {
                              return (
                                <CardForProductLine
                                  product={product}
                                  flag={props.flag}
                                />
                              );
                            })}
                          </div>
                        );
                      })
                    ) : (
                      <NoProductsFound flag={props.flag} />
                    )}
                  </SwipeableViews>
                ) : (
                  <></>
                )} */}
              </SwipeableViews>
            </div>
          </StickyBox>
        </div>
      </section>
      {/* <PageButtons
      productsPages={productsPages}
      page={page}
      setPage={setPage}
      /> */}
      {productsPages && productsPages.length > 1 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PaginationOutlined
            productsPages={productsPages}
            page={page}
            setPage={setPage}
          />
        </div>
      )}
      {/* {PageButtons(productsPages, page, setPage)} */}
    </section>
  );
}

function CardContainer({
  setQuickView,
  product,
  flag,
  loading,
  page,
  productsPages
}) {
  const productsPagesPrev = usePrevious(productsPages)
  const [props, set, stop] = useSpring(() => ({
    from: { transform: "translate3d(0,-40px,0)", opacity: 1 },
    to: { transform: "translate3d(0, 0px,0)", opacity: 1 },
  }));
  // useEffect(()=>{

  // },[productsPages])
  useEffect(() => {
    loading
      ? set({
          from: { transform: "translate3d(0, 0px,0)", opacity: 1 },
          to: { transform: "translate3d(0,-40px,0)", opacity: 1 },
        })
      : set({
          from: { transform: "translate3d(0,-40px,0)", opacity: 1 },
          to: { transform: "translate3d(0, 0px,0)", opacity: 1 },
        });
  }, [loading]);
  if (productsPages[page].some((it) => it.id === product.id)) {
    return (
      <GridWithXXL
        key={product.id}
        container
        justify="center"
        item
        xxl={3}
        lg={3}
        md={3}
        sm={3}
        xs={6}
        xxs={12}
      >
        <animated.div
          style={props}
          key={product.id}
          className={allStyles.cardContainerForHover}
        >
          <CardForProduct
            setQuickView={setQuickView}
            product={product}
            flag={flag}
          />
        </animated.div>
      </GridWithXXL>
    );
  } else {
    return null
  }
}
