/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounSearchComponent = ({ svgRef }) => (
  <svg width={19} height={19} viewBox="0 0 19.477 19.515" ref={svgRef}>
    <defs>
      <style>{".aaaa{fill:#707070;}"}</style>
    </defs>
    <path
      className="aaaa"
      d="M22.428,20.291a1.54,1.54,0,0,0-1.792-.259l-.712-.712a8.006,8.006,0,1,0-.6.6l.712.712a1.544,1.544,0,0,0,.259,1.792l2.634,2.634a1.51,1.51,0,0,0,1.058.453,1.473,1.473,0,0,0,1.058-.453,1.525,1.525,0,0,0,0-2.137Zm-8.441.82a7.124,7.124,0,1,1,7.124-7.124A7.131,7.131,0,0,1,13.987,21.111Z"
      transform="translate(-6 -6)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounSearchComponent svgRef={ref} {...props} />
));
export default ForwardRef;
