import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";

import {
  GrFacebookOption,
  GrLinkedinOption,
  GrGooglePlus,
  GrTwitter,
  FaTelegramPlane,
} from "react-icons/all";
import allstyles from "../../productInfo/ProductInfo.module.css";
import ImageGallery from "react-image-gallery";
import langs from "../../../stylesForLangs/langs.module.css";
import { Names } from "../../../staticTexts";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import loadData from "../../../helpers/loadData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuickView(props) {
  const brandsFromRedux = useSelector((state) => state.brands);
  const galleryRef = useRef();
  const [brandName, setbrandName] = useState("");
  const [productInfo, setProductInfo] = useState({});
  const imageSizeOriginal = "480x480/";
  const imageSizeThumbnail = "150x150/";

  const handleClose = () => {
    props.setOpen({ ...props.open, bool: false });
  };

  useEffect(() => {
    if (props.open.product && props.open.product.id) {
      loadData("itemsInfo", JSON.stringify({ id: props.open.product.id })).then(
        (data) => {
          setProductInfo(data[0].toolbar);
        }
      );
    }
  }, [props.open.bool, props.open.product]);

  useEffect(() => {
    if (props.open.product && props.open.product.brand && brandsFromRedux) {
      brandsFromRedux.forEach((item) => {
        if (item.id === props.open.product.brand) {
          setbrandName(item.name);
        }
      });
    }
  }, [brandsFromRedux, props.open]);

  // useEffect(()=>{

  // },[props, productsFromRedux])

  return (
    <div>
      <Dialog
        open={props.open.bool}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        maxWidth="lg"
        MenuProps={{
          disableScrollLock: true,
        }}
        // BackdropComponent={<div style={{width:"100%", height:"100vh", backgroundColor:"rgba(0,0,0,0.5)"}} ></div>}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                width: "100%",
                minHeight: "100px",
                display: "flex",
                position: "relative",
              }}
            >
              <IconButton
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <span
                    className={`${
                      props.flag === "am"
                        ? langs.armenianHeaders600
                        : langs.BarlowBold
                    } ${allstyles.nameSM}`}
                  >
                    {props.open.product && props.open.product && props.open.product.name[props.flag]}
                  </span>
                  <div
                    style={{
                      height: "fit-content",
                      maxWidth: "100%",
                      position: "relative",
                    }}
                  >
                    <ImageGallery
                      slideDuration={200}
                      thumbnailPosition={
                        window.innerWidth < 601 ? "bottom" : "left"
                      }
                      // slideOnThumbnailOver={true}
                      ref={galleryRef}
                      // renderCustomControls={_renderCustomControls}
                      //   onMouseOver={() => {
                      //     setShowImageControllButtons({
                      //       index: galleryRef.current.getCurrentIndex(),
                      //       bool: true,
                      //     });
                      //   }}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      className="imageGallery"
                      showNav={false}
                      items={
                        props.open.product ?
                        props.open.product.Image.map((image) => {
                          let index = image.indexOf("(");
                          if (index !== -1) {
                          } else {
                          }
                          return {
                            original: `${process.env.REACT_APP_API_URL}/${imageSizeOriginal}/${image}`,
                            thumbnail: `${process.env.REACT_APP_API_URL}/${imageSizeThumbnail}/${image}`,
                          };
                        })
                      :
                      []
                      }
                    />

                    {/* images gallery */}
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div
                    className={allstyles.allInfo}
                    style={{ marginLeft: "0px" }}
                  >
                    <div className={allstyles.nameInfoContainer}>
                      {/* <span className={allstyles.nameInfoContainer} > */}
                      <span
                        className={`${
                          props.flag === "am"
                            ? langs.armenianHeaders600
                            : langs.BarlowBold
                        } ${allstyles.name}`}
                      >
                        {props.open.product &&
                          props.open.product && props.open.product.name[props.flag]}
                      </span>

                      <span
                        className={`${
                          props.flag === "am"
                            ? langs.NunitoRegular
                            : langs.NunitoRegular
                        } ${allstyles.shortText}`}
                      >
                        {productInfo && productInfo[props.flag] && productInfo[props.flag][0][`aboutText${props.flag.toLocaleUpperCase()}`]}
                        {/* {props.open.product && props.open.product.shortText[props.flag]} */}
                        <div className={allstyles.containerScroll}></div>
                      </span>
                      <div className={allstyles.infoContainer}>
                        {/* <div className={allstyles.guarantee}> */}

                        <span
                          className={
                            props.flag === "am"
                              ? langs.armenianHeaders600
                              : langs.BarlowBold
                          }
                        >
                          <span
                            className={`${
                              props.flag === "am"
                                ? langs.armenianHeaders600
                                : langs.NunitoRegular
                            }`}
                          >
                            {Names.brand[props.flag]}
                          </span>{" "}
                          :{" "}
                          <span style={{ fontWeight: "100" }}>{` ${
                            brandName && brandName.us.toLocaleUpperCase()
                          } `}</span>
                        </span>
                        <div
                          className={`${
                            props.flag === "am"
                              ? langs.armenianHeaders600
                              : langs.BarlowBold
                          } ${allstyles.year}`}
                        >
                          <span
                            className={`${
                              props.flag === "am"
                                ? langs.armenianHeaders600
                                : langs.NunitoRegular
                            }`}
                          >
                            {Names.guarantee[props.flag]}
                          </span>{" "}
                          :{" "}
                          <span style={{ fontWeight: "100" }}>
                            {" "}
                            {`${
                              props.open.product && props.open.product.guarantee
                            } `}{" "}
                            {Names.year[props.flag]}{" "}
                          </span>
                        </div>
                        {/* </div> */}
                      </div>
                      <span className={`${allstyles.guarantee}`}>
                      <span
                        className={`${
                          props.flag === "am"
                            ? langs.DejaVuSansWF
                            : langs.NunitoRegular
                        }`}
                      >
                        {Names.share[props.flag]} :
                      </span>



                      <FacebookShareButton
                        style={{outline: "none"}}
                        url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${props.open.product ? props.open.product.path : window.location.pathname}`}
                        quote={productInfo && productInfo[props.flag] && productInfo[props.flag][0][`aboutText${props.flag.toLocaleUpperCase()}`]}
                        hashtag={`#${props.open.product && props.open.product.name[props.flag]}`}
                      >
                        <svg style={{marginLeft: "20px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0ZM19.3229 18.2687V27.7896H15.3836V18.2691H13.4155V14.9881H15.3836V13.0182C15.3836 10.3416 16.4949 8.75 19.6522 8.75H22.2808V12.0313H20.6377C19.4087 12.0313 19.3274 12.4899 19.3274 13.3456L19.3229 14.9877H22.2994L21.9511 18.2687H19.3229Z" fill="#3B5998"/>
                        </svg>
                      </FacebookShareButton>
                      <LinkedinShareButton
                        style={{outline: "none"}}
                        url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${props.open.product ? props.open.product.path : window.location.pathname}`}
                        quote={productInfo && productInfo[props.flag] && productInfo[props.flag][0][`aboutText${props.flag.toLocaleUpperCase()}`]}
                        hashtag={`#${props.open.product && props.open.product.name[props.flag]}`}
                      >
                         <svg style={{marginLeft: "14px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0ZM8.39681 14.4945H12.3627V26.4103H8.39681V14.4945ZM12.626 10.8082C12.6002 9.63988 11.7648 8.75 10.4081 8.75C9.05135 8.75 8.16439 9.63988 8.16439 10.8082C8.16439 11.9524 9.02514 12.8679 10.3566 12.8679H10.3819C11.7648 12.8679 12.626 11.9524 12.626 10.8082ZM22.1039 14.2142C24.7136 14.2142 26.6701 15.9176 26.6701 19.5777L26.67 26.4098H22.7042V20.0349C22.7042 18.4336 22.1304 17.3409 20.6947 17.3409C19.599 17.3409 18.9464 18.0776 18.6598 18.789C18.5549 19.044 18.5292 19.3992 18.5292 19.7554V26.4102H14.5628C14.5628 26.4102 14.6151 15.6124 14.5628 14.4943H18.5292V16.1821C19.0556 15.3707 19.9983 14.2142 22.1039 14.2142Z" fill="#0077B5"/>
                        </svg>
                      </LinkedinShareButton>
                      {/*

                    <FacebookShareButton
                      style={{outline: "none"}}
                      url={`${process.env.REACT_APP_URL}${props.open.product ? props.open.product.path : window.location.pathname}`}
                      quote={productInfo && productInfo[props.flag] && productInfo[props.flag][0][`aboutText${props.flag.toLocaleUpperCase()}`]}
                      hashtag={`#Unitech #${props.open.product && props.open.product.name[props.flag]}`}
                    >
                      <button className={allstyles.shareButton} >
                        <GrGooglePlus fontSize="16px" />
                      </button>
                    </FacebookShareButton>
                    */}

                      <TelegramShareButton
                        style={{outline: "none"}}
                        url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${props.open.product ? props.open.product.path : window.location.pathname}`}
                        quote={productInfo && productInfo[props.flag] && productInfo[props.flag][0][`aboutText${props.flag.toLocaleUpperCase()}`]}
                        hashtag={`#${props.open.product && props.open.product.name[props.flag]}`}
                      >
                        <svg style={{marginLeft: "14px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM14.2962 25.5207C14.7218 25.5207 14.9164 25.3314 15.1514 25.1025L15.1712 25.0832L17.268 23.0444L21.6341 26.2702C22.4389 26.7143 23.0198 26.4843 23.2202 25.523L26.0909 11.9951C26.3849 10.8167 25.6418 10.2823 24.8719 10.6318L8.015 17.1317C6.86436 17.5933 6.87107 18.2352 7.80526 18.5212L12.1311 19.8714L22.1459 13.5532C22.6187 13.2665 23.0526 13.4206 22.6965 13.7367L14.5816 21.0597L14.5934 21.0684L14.2962 25.5207Z" fill="#31A8DC"/>
                        </svg>
                      </TelegramShareButton>

                      <TwitterShareButton
                        style={{outline: "none"}}
                        url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${props.open.product ? props.open.product.path : window.location.pathname}`}
                        quote={productInfo && productInfo[props.flag] && productInfo[props.flag][0][`aboutText${props.flag.toLocaleUpperCase()}`]}
                        hashtag={`#${props.open.product && props.open.product.name[props.flag]}`}
                      >
                        <svg style={{marginLeft: "14px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0ZM17.0126 14.8298L16.9759 14.2242C16.8657 12.6548 17.8327 11.2212 19.3629 10.6651C19.9259 10.4674 20.8807 10.4427 21.505 10.6157C21.7498 10.6898 22.215 10.937 22.5455 11.1594L23.1453 11.5673L23.8063 11.3572C24.1735 11.246 24.6632 11.0606 24.8835 10.937C25.0916 10.8258 25.2752 10.764 25.2752 10.8011C25.2752 11.0112 24.8223 11.7279 24.4428 12.1234C23.9287 12.6795 24.0756 12.7289 25.1161 12.3582C25.7404 12.1481 25.7526 12.1481 25.6302 12.3829C25.5567 12.5065 25.1773 12.939 24.7733 13.3344C24.0878 14.0141 24.0511 14.0883 24.0511 14.6567C24.0511 15.5342 23.6349 17.3631 23.2187 18.3641C22.4476 20.2425 20.795 22.1827 19.1425 23.159C16.8167 24.5308 13.7198 24.8768 11.1125 24.0735C10.2434 23.8016 8.75 23.1096 8.75 22.986C8.75 22.9489 9.20291 22.8995 9.75375 22.8871C10.9044 22.8624 12.055 22.5411 13.0343 21.9727L13.6953 21.5772L12.9364 21.3177C11.8592 20.9469 10.8922 20.0942 10.6473 19.291C10.5739 19.0315 10.5984 19.0191 11.2839 19.0191L11.9938 19.0067L11.394 18.7225C10.6841 18.3641 10.0353 17.7586 9.71703 17.1407C9.48445 16.6958 9.19067 15.5712 9.27636 15.4847C9.30084 15.4477 9.5579 15.5218 9.85168 15.6207C10.6963 15.9296 10.8065 15.8555 10.3168 15.3364C9.39877 14.3972 9.11723 13.0008 9.5579 11.6785L9.76599 11.0853L10.5739 11.8886C12.2264 13.5075 14.1727 14.4714 16.4006 14.7556L17.0126 14.8298Z" fill="#55ACEE"/>
                        </svg>
                      </TwitterShareButton>
                    </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary" disabled={disable} autoFocus>
            Close
          </Button>
          <Button color="secondary" disabled={haveProduct || disable} >
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
