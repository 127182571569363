/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import "../../../news/style.css"


const PathComponent = ({ svgRef }) => (
  <svg width={26} height={20} viewBox="0 0 11.774 21.651" ref={svgRef}>
    {/* <defs>
      <style>{".arrow{fill:#ff3b3b;stroke:#ff3b3b;stroke-width:0.5px;}"}</style>
    </defs> */}
    <path
      className="arrow"
      d="M1.448,10.575,11,1.023a.6.6,0,0,0-.847-.847L.176,10.154a.6.6,0,0,0,0,.847l9.978,9.973a.6.6,0,0,0,.421.177A.584.584,0,0,0,11,20.974a.6.6,0,0,0,0-.847Z"
      transform="translate(11.474 21.401) rotate(180)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
