import React, { useState, useEffect, useCallback } from 'react'
import style from "./downloads.module.css"
import { Grid } from '@material-ui/core'
import FilterIcon from './icons/NounFilterComponent'
import SelectCategory from "./selectCategory"
import SelectProduct from "./selectProduct"
import { FiSearch } from "react-icons/fi"
import DownloadTable from '../productInfo/tableDownloads copy'
import loadData from '../../helpers/loadData'
import { CircleLoader } from 'react-spinners';
import langs from "../../stylesForLangs/langs.module.css"
import { Names } from "../../staticTexts"
import {MakeBreadcrumbs} from '../../helpers/helperComponents/helperComponents'
import "./autocomplateShadow.css"
import { useSelector } from 'react-redux'
import Banner from "../banner/banner"
import img from "./downloads.png"



export default function Downloads(props) {

    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [search, setSearch] = useState("")
    const [searchVal, setSearchVal] = useState("")
    const [allResults, setAllResults] = useState([])
    const [products, setProducts] = useState([])
    const [initDownloads, setInitDownloads] = useState("initial")
    const [filteredResults, setFilteredResults] = useState([])
    const [fetching, setFetching] = useState(false)
    const categoriesFromRedux = useSelector(state=>state.categories)


    useEffect(()=>{
        
        if(selectedProduct && !search){
            
            setFetching(true)
            loadData("itemsInfo", JSON.stringify({id:selectedProduct})).then((data)=>{
            data = data.filter(item=>item.active)
                if(data && data[0] && data[0].downloads){
                    data[0].downloads[`downloadtable${props.flag.toLocaleUpperCase()}`] = data[0].downloads[`downloadtable${props.flag.toLocaleUpperCase()}`].filter(item=>item.url&&item.name)
                    data[0].downloads[`downloadtable${props.flag.toLocaleUpperCase()}`] = data[0].downloads[`downloadtable${props.flag.toLocaleUpperCase()}`].map(item=>{ 
                        return Object.assign(item,{id: data[0].id})
                })
                    setAllResults(data[0].downloads[`downloadtable${props.flag.toLocaleUpperCase()}`])
                }
                setFetching(false)
            })
        }else if(selectedProduct && search){
            setFetching(true)
            loadData("itemsInfo", JSON.stringify({id:selectedProduct})).then((data)=>{
                data = data.filter(item=>item.active)

                if(data && data[0] && data[0].downloads){
                    let productsDownload = data[0].downloads[`downloadtable${props.flag.toLocaleUpperCase()}`]
                    setFilteredResults(productsDownload.filter(item=>item.name && item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
                    setFetching(false)
                }
                
            })
        }else if(!selectedProduct && search){
            setFetching(true)
            Array.isArray(initDownloads) && setFilteredResults(initDownloads.filter(item=>item.name && item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
            setFetching(false)
        }else{

        }
    },[search, selectedProduct, initDownloads, props.flag])


    

    useEffect(()=>{
        setFetching(true)
        loadData("itemsInfo", JSON.stringify({type:"itemInfo"})).then((data)=>{
            let allDownloads=[];
            data = data.filter(item=>item.active)
            if(data && data[0] && data[0].downloads){
                data.forEach(el => {
                    el.downloads[`downloadtable${props.flag.toLocaleUpperCase()}`].map(item=>{ 
                        return Object.assign(item,{id: el.id})
                    })
                    allDownloads.push(...el.downloads[`downloadtable${props.flag.toLocaleUpperCase()}`])
                });
                setInitDownloads(allDownloads)
            }
            setFetching(false)
        })
    },[props.flag])

    const getChildCategories = useCallback((id)=>{
        let res = categoriesFromRedux.filter(item=>{
            return item.parentId === id
        })
        return res.map(i=>i.id)
    },[categoriesFromRedux])
   
      
    useEffect(() => {
        setSelectedProduct(null)

        setAllResults({})
        let selectedCategories = getChildCategories(selectedCategory)
        selectedCategories.push(selectedCategory)

        fetch(`${process.env.REACT_APP_API_URL}/Categories`, {
        credentials: "include"
        }).then(data => {
        return data.json()
        }).then((data => {
        data = data.filter(item => {
            return (item.type === "item" && item.active && selectedCategories.includes(item.parentId))
        })
        setProducts(data)
        
        }))
    }, [selectedCategory, getChildCategories])



    function handleKeys(e){
        if(e.keyCode===13){
            setSearch(searchVal)
        }
    }


    return (
        <div className={style.container}>
            <Banner page={Names.download[props.flag]} link={"/downloads.png"} />

            <div className={style.breadcrumbs}>
                {MakeBreadcrumbs([{name: Names.download[props.flag], path:"/aboutUs"}],props.flag)}
            </div>
            <div className={`${style.filters} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`} >
                <div className={style.pageName}>
                    {Names.download[props.flag]}
                </div>
                <div className={style.selectsCont}>
                <div style={{ marginTop: "17px" }} className={style.selects}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <SelectCategory 
                                flag={props.flag} 
                                setSelectedCategory={setSelectedCategory} 
                                selectedCategory={selectedCategory} 
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <SelectProduct 
                                categories={products} 
                                disable={!selectedCategory} 
                                flag={props.flag} 
                                setSelectedProduct={setSelectedProduct} 
                                selectedProduct={selectedProduct} 
                                selectedCategory={selectedCategory}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={style.searchContainer}>
                            <input 
                                onKeyUp={(e)=>{handleKeys(e)}}
                                value={searchVal}
                                onChange={(e)=>{setSearchVal(e.target.value)}}
                                className={style.search}
                                placeholder={Names.searchPlaceholder[props.flag]}
                            />
                            <button className={style.iconButton} onClick={(e)=>{e.preventDefault(); setSearch(searchVal)}}>
                                <FiSearch />
                            </button>
                            </div>
                        </Grid>
                    </Grid>
                    </div>
                    <div className={style.icon} >
                        <svg width="125" height="128" viewBox="0 0 125 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M90.1687 60.3374L90.4999 11.2319C90.5153 8.94514 89.6206 6.74111 88.0124 5.11289C86.4047 3.48467 84.2155 2.55753 81.9258 2.54209C65.0734 2.42843 29.4252 2.188 29.4252 2.188L2.52826 28.7318L2.05815 98.4356C2.04273 100.722 2.93696 102.926 4.54517 104.555C6.15288 106.183 8.3421 107.11 10.6318 107.125L44.9157 107.357" fill="white"/>
                            <path d="M90.1687 60.3374L90.4999 11.2319C90.5153 8.94514 89.6206 6.74111 88.0124 5.11289C86.4047 3.48467 84.2155 2.55753 81.9258 2.54209C65.0734 2.42843 29.4252 2.188 29.4252 2.188L2.52826 28.7318L2.05815 98.4356C2.04273 100.722 2.93696 102.926 4.54517 104.555C6.15288 106.183 8.3421 107.11 10.6318 107.125L44.9157 107.357" stroke="#32406E" stroke-width="4.03" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.49998 29.4999L30.2564 31.0442L30.2563 4.18525" fill="#A1A7BC"/>
                            <path d="M4.49998 29.4999L30.2564 31.0442L30.2563 4.18525" stroke="#32406E" stroke-width="0.0180995" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.3887 47.6201L65.1888 47.902" stroke="#979EB7" stroke-width="3.03" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.3271 59.877L65.1273 60.1589" stroke="#979EB7" stroke-width="3.03" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.2017 72.1416L49.7393 72.3206" stroke="#979EB7" stroke-width="3.03" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M77.9067 121.748C92.8877 121.849 105.114 109.786 105.215 94.8049C105.316 79.8239 93.2536 67.5975 78.2726 67.4964C63.2915 67.3954 51.0651 79.458 50.964 94.439C50.863 109.42 62.9256 121.647 77.9067 121.748Z" fill="#D2EBFC"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M78.2725 67.5003C93.2438 67.6013 105.316 79.837 105.215 94.8047C105.114 109.778 92.8779 121.85 77.9067 121.749C62.936 121.648 50.8631 109.412 50.9641 94.4388C51.065 79.4711 63.3019 67.3994 78.2725 67.5003ZM78.2493 70.9531C91.3146 71.0412 101.851 81.7185 101.762 94.7815C101.674 107.85 90.9953 118.384 77.93 118.296C64.8647 118.208 54.3287 107.531 54.4168 94.4621C54.5049 81.3991 65.184 70.865 78.2493 70.9531Z" fill="#32406E"/>
                            <path d="M105.5 113.5L122.007 125.363" stroke="#32406E" stroke-width="4.03" stroke-linecap="round" stroke-linejoin="bevel"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className={style.results}>
                {/* <div className={`${style.textDownload} ${props.flag==="am" ? langs.armenianHeaders : langs.foreignHeaders600}`} >{Names.download[props.flag]}</div> */}
                {fetching ?
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", minHeight:"50vh"}}>
                       <CircleLoader color={"#9F1A1E"}/>
                    </div>
                    :
                    <>
                    <div className={style.descriptionTable} >
                        <div className={style.description} > <div className={style.descriptionMargin}  > Description</div></div>
                        <div className={style.date} >Date</div>
                    </div>
                    <DownloadTable 
                        flag={props.flag}
                        table={
                               selectedProduct && !Object.keys(allResults).length ? [] : Object.keys(allResults).length && !search ? allResults : 
                                search ? filteredResults : initDownloads 
                            } 
                    />
                    </>
                    }
            </div>
        </div>
    )
}
