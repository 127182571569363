// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../ttf/Mardoto-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../ttf/Mardoto-Medium.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Mardoto-Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: 'Mardoto-Medium';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n.Card_for_product_Line_line__1ik_T{\n  width:100%;\n  height:-moz-fit-content;\n  height:fit-content;\n  margin:10px 0;\n  cursor:pointer;\n  display:flex;\n  justify-content:flex-start;\n  transition-duration:0.2s;\n  border:1px solid rgba(128,128,128, 0.1);\n  box-sizing:border-box;\n}\n\n.Card_for_product_Line_line__1ik_T:hover{\n  transition-duration:0.2s;\n  box-shadow: 0px 2px 10px 0px rgba(186,186,186,0.57);\n}\n\n.Card_for_product_Line_line__1ik_T:hover .Card_for_product_Line_productName__2Ayds{\n  color: #f0544f;\n}\n\n.Card_for_product_Line_cont__2csG7{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.Card_for_product_Line_button__2yJGY:hover {\n\n  width: 64px;\nheight: auto;\nbackground: #f0544f;\n/* border: 0.1px solid rgba(112, 112, 112, 0.3); */\ncolor: #fff;\n}\n\n.Card_for_product_Line_button__2yJGY{\ntransition-duration: 0.3s;\n  width: 64px;\nheight: auto;\nbackground: #fff;\n/* border: 0.1px solid rgba(112, 112, 112, 0.2); */\ndisplay: flex;\n  justify-content: center;\n  align-items: center;\n  color: #f0544f;\n}\n.Card_for_product_Line_productName__2Ayds{\n  width:100%;\n  font-family:Mardoto-Regular;\n  color: #202020;\n  font-size:18px;\n}\n.Card_for_product_Line_productShortText__3GDIA{\n  /* width:100%; */\n  text-overflow: ellipsis;\n  padding-right: 65px;\n  font-size:12px;\n  \n}\n.Card_for_product_Line_brand__2zQjU{\n  font-family: 'Mardoto-Medium'\n}", ""]);
// Exports
exports.locals = {
	"line": "Card_for_product_Line_line__1ik_T",
	"productName": "Card_for_product_Line_productName__2Ayds",
	"cont": "Card_for_product_Line_cont__2csG7",
	"button": "Card_for_product_Line_button__2yJGY",
	"productShortText": "Card_for_product_Line_productShortText__3GDIA",
	"brand": "Card_for_product_Line_brand__2zQjU"
};
module.exports = exports;
