// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".searchbar_container__3p9pf {\n    width: 0px;\n    /* height: 20px; */\n    border: none;\n    display: flex;\n    justify-content: space-between;\n    padding: 5px 10px;\n    margin-right: 13px;\n    border-radius: 19px;\n    box-shadow: 0 0px 5px 0px black inset;\n    position: relative;\n    z-index: 1000;\n\n}\n.searchbar_container__3p9pf::after{\n    content: '';\n    position: absolute;\n    right: -13px;\n    top: 3px;\n    width: 100%;\n    height: 25px;\n    border-right: 1px solid rgb(27 27 27 / 26%);\n}\n\n.searchbar_input__2hFjk{\n    border:none;\n    outline: none;\n    background-color: inherit;\n    width: 100%;\n    position: relative;\n    z-index: 1000;\n}", ""]);
// Exports
exports.locals = {
	"container": "searchbar_container__3p9pf",
	"input": "searchbar_input__2hFjk"
};
module.exports = exports;
