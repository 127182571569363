import React, { useState, useEffect } from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";
import loadData from "../../helpers/loadData";
import style from "./services.module.css";
import { CircleLoader } from "react-spinners";
import langs from "../../stylesForLangs/langs.module.css";
import { MakeBreadcrumbs } from "../../helpers/helperComponents/helperComponents";
import { Names } from "../../staticTexts";
import Banner from "../banner/banner"

const styles = theme => ({
  text: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
      display:"flex",
      justifyContent:"center"
    },
  },
  empty: {
    [theme.breakpoints.down('sm')]: {
      order: 3,
      display:"none"
    },
  },
});

export default function (props) {
  const classes = makeStyles(styles)()
  const [contentGlob, setContentGlob] = useState({});

  const [headers, setHeaders] = useState({ am: [], ru: [], us: [] });
  const [contents, setContents] = useState({ am: [], ru: [], us: [] });
  const [files, setFiles] = useState([]);

  const [fileDefault, setFileDefault] = useState("");
  const [fileDefault2, setFileDefault2] = useState("");
  const [fileDefault3, setFileDefault3] = useState("");
  const [fileDefault4, setFileDefault4] = useState("");
  const [isFetched, setisFetched] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData("staticpages", JSON.stringify({ id: "services" })).then((res) => {
      try {
        setFileDefault(res[0].content.Images[0]);
        setFileDefault2(res[0].content.Images[1]);
        setFileDefault3(res[0].content.Images[2]);
        setFileDefault4(res[0].content.Images[3]);
        setContentGlob(res[0].content.contentGlob);
        setFiles(res[0].content.Images);

        setHeaders({
          am: [
            res[0].content.am[0].header,
            res[0].content.am[1].header,
            res[0].content.am[2].header,
            res[0].content.am[3].header,
          ],
          ru: [
            res[0].content.ru[0].header,
            res[0].content.ru[1].header,
            res[0].content.ru[2].header,
            res[0].content.ru[3].header,
          ],
          us: [
            res[0].content.us[0].header,
            res[0].content.us[1].header,
            res[0].content.us[2].header,
            res[0].content.us[3].header,
          ],
        });

        setContents({
          am: [
            res[0].content.am[0].content,
            res[0].content.am[1].content,
            res[0].content.am[2].content,
            res[0].content.am[3].content,
          ],
          ru: [
            res[0].content.ru[0].content,
            res[0].content.ru[1].content,
            res[0].content.ru[2].content,
            res[0].content.ru[3].content,
          ],
          us: [
            res[0].content.us[0].content,
            res[0].content.us[1].content,
            res[0].content.us[2].content,
            res[0].content.us[3].content,
          ],
        });

        setisFetched(true);
      } catch (error) {
        console.log(error);
        alert("Cannot get Data open Console");
      }
    });
  }, []);

  return (<>
  <Banner page={Names.footer.Services[props.flag]} link={"/services.png"} gradient={"none"} textColor={"#8EA3B8"}  />
    <section className={style.fullsectionss}>
      <div className={style.breadcrumbs}>
        {MakeBreadcrumbs(
          [{ name: Names.footer.Services[props.flag], path: "/aboutUs" }],
          props.flag
        )}
      </div>

      {isFetched ? (
        <div className={style.fullsection}>
          <div className={style.pageName}>
            {Names.footer.Services[props.flag]}
          </div>
            <div className={style.contentGlob} >
                {contentGlob[props.flag]}
            </div>
          {headers &&
            headers[props.flag] &&
            headers[props.flag].map((item, i, arr) => {
              return (
                <Grid container spacing={5}>
                  {i % 2 === 0 ? (
                    <>
                      {" "}
                      <Grid item md={3} xs={12}  className={classes.img}>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/originals/${files[i]}`}
                          className={style.img}
                          alt="im"
                        />
                      </Grid>
                      <Grid item md={6} xs={12} className={classes.text}>
                        <div className={style.textsContainer}>
                          {i !== 0 && <div className={style.divaidertop} />}
                          {i !== arr.length - 1 && (
                            <div className={style.divaiderbottom} />
                          )}
                          <div className={style.header}>{item}</div>
                          <div className={style.content}>
                            {contents[props.flag][i]}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3} xs={12} className={classes.empty} ></Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} xs={12}className={classes.empty}></Grid>
                      <Grid item md={6} xs={12}className={classes.text}>
                        <div className={style.textsContainer}>
                          {i !== 0 && <div className={style.divaidertop} />}
                          {i !== arr.length - 1 && (
                            <div className={style.divaiderbottom} />
                          )}
                          <div className={style.header}>{item}</div>
                          <div className={style.content} style={i === arr.length - 1 ? { paddingBottom: "0px"} : {}} >
                            {contents[props.flag][i]}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3} xs={12} className={classes.img} >
                        <img
                          src={`${process.env.REACT_APP_API_URL}/originals/${files[i]}`}
                          className={style.img}
                          alt="im"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircleLoader color={"#9F1A1E"} />
        </div>
      )}
    </section>
    </>
  );
}
