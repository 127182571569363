/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounArrowComponent = ({ svgRef }) => (
  <svg width={15} height={8} viewBox="0 0 15.647 8.784" ref={svgRef}>
    <defs>
      <style>{".downn{fill:#1f242a;fill-rule:evenodd;}"}</style>
    </defs>
    <g transform="translate(0 0)">
      <path
        className="downn"
        d="M8.5,8.5l6.863-6.84a.99.99,0,0,0,0-1.382.99.99,0,0,0-1.382,0l-6.16,6.16L1.663.281a.99.99,0,0,0-1.382,0,.99.99,0,0,0,0,1.382L7.121,8.5A.99.99,0,0,0,8.5,8.5Z"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounArrowComponent svgRef={ref} {...props} />
));
export default ForwardRef;
