import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
// import Eye from "./subFotterIcons/Group 1554.svg"
// import Download from "./subFotterIcons/Group 1552.svg"
// import Job from "./subFotterIcons/Group 1553.svg"
import Download from "./subFotterIcons/downloadsIcon/NounDownloadComponent"
import Job from "./subFotterIcons/JobIcon/NounJobComponent"
import LastSeen from "./subFotterIcons/servicesIcon/HeadphonesComponent"
import ProductsIcon from "./subFotterIcons/productsIcon/NounProductsComponent"
import styles from "./style.module.css"
import Langs from '../../stylesForLangs/langs.module.css'
import { useHistory } from "react-router-dom"
import loadData from "../../helpers/loadData"
import "./styleForSubfooter.css"
import MailIcon from "./subFotterIcons/mail/NounEmailComponent"
import MapIcon from "./subFotterIcons/map/NounLocationComponent"
import CallIcon from "./subFotterIcons/call/GroupComponent"
import {useSelector} from "react-redux"
import {Names} from "../../staticTexts"





export default function SubFooter(props) {
    const history = useHistory()
    const flag = useSelector(s=>s.flag)
    const [address, setAddress] = useState({ am: "", ru: "", us: "" })
    const [phone, setPhone] = useState("")
    const [mail, setMail] = useState("")
    useEffect(() => {
        // window.scrollTo(0, 0)
        loadData("staticpages", JSON.stringify({ id: "contacts" })).then(res => {
            setAddress(res[0].address)
            setMail(res[0].mail)
            setPhone(res[0].phone)
        })
    }, [])

    return (
        <Grid container justify="flex-start" alignItems="baseline" spacing={0} style={{maxWidth:"1366px", margin:"0 auto", marginTop: "-20px"}} >
            {/* <Paper className={`${classes.container} ${"grids"}`}> */}
                <Grid item item sm={4} xs={12}>
                        <a style={{color:"inherit", textDecoration:"none"}} href={`tel:${phone}`}>
                   <div className={styles.containerBox} >
                       <span className={styles.iconContainer} >
                       <CallIcon/>
                       </span>
                        <div className={styles.containerBoxnested}>
                            <div className={styles.nestedHeader}>
                                {Names.Call_Us[flag]}
                            </div>
                            <div style={{color:"#fff"}} >
                            {phone}
                            </div>
                        </div>
                   </div>
                            </a>
                </Grid>
                <Grid item sm={4} xs={12}>
                <a style={{color:"inherit", textDecoration:"none"}} href={`mailto:${mail}`}>
                   <div className={styles.containerBox} >
                       <span className={styles.iconContainer} >
                       <MailIcon/>
                       </span>
                        <div className={styles.containerBoxnested}>
                            <div className={styles.nestedHeader}>
                                  {Names.Write_to_us[flag]}
                            </div>
                            <div style={{color:"#fff"}} >
                            {mail}
                            </div>
                        </div>
                   </div>
                   </a>
                </Grid>
                <Grid item sm={4} xs={12}>
                <a style={{color:"inherit", textDecoration: "none" }} target="_blank" rel="noopener noreferrer"  href={`/contacts`}>

                   <div className={styles.containerBox} >
                       <span className={styles.iconContainer} >
                       <MapIcon/>
                       </span>
                        <div className={styles.containerBoxnested}>
                            <div className={styles.nestedHeader}>
                                {Names.Find_us[flag]}
                            </div>
                            <div style={{color:"#fff"}}>
                                {address[flag]}
                            </div>
                        </div>
                   </div>
                   </a>
                </Grid>
             
        </Grid>
    )
}

