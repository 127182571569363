/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounPhoneComponent = ({ svgRef }) => (
  <svg width={20} height={20} viewBox="0 0 21.75 21.75" ref={svgRef}>
    <defs>
      <style>
        {
          ".aqwe,.qwea{fill:none;stroke:#fff;stroke-width:1.2px;}.a{fill-rule:evenodd;}"
        }
      </style>
    </defs>
    <g transform="translate(0.609 0.6)">
      <g transform="translate(0 0)">
        <path
          className="aqwe"
          d="M4.727,40.054l1.68-1.94a.8.8,0,0,0,.041-1.005L4.055,33.848a.793.793,0,0,0-.878-.3A4.085,4.085,0,0,0,.36,39.3,22.028,22.028,0,0,0,12.769,51.7a4.085,4.085,0,0,0,5.745-2.817.794.794,0,0,0-.3-.878l-3.261-2.392a.8.8,0,0,0-1.005.041l-1.94,1.68a15.637,15.637,0,0,1-7.283-7.283Z"
          transform="translate(-0.005 -31.519)"
        />
        <path
          className="qwea"
          d="M175.107,9.793a.846.846,0,1,0,1.691,0A9.792,9.792,0,0,0,167.006,0a.846.846,0,1,0,0,1.691,8.1,8.1,0,0,1,8.1,8.1Z"
          transform="translate(-156.257 0)"
        />
        <path
          className="qwea"
          d="M171.826,61.712a.846.846,0,1,0,1.691,0,6.5,6.5,0,0,0-6.5-6.5.846.846,0,0,0,0,1.691,4.813,4.813,0,0,1,4.81,4.81Z"
          transform="translate(-156.267 -51.919)"
        />
        <path
          className="qwea"
          d="M168.576,113.657a.844.844,0,0,0,1.687,0,3.2,3.2,0,0,0-3.212-3.212.844.844,0,0,0,0,1.687,1.528,1.528,0,0,1,1.52,1.52Z"
          transform="translate(-156.304 -103.867)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounPhoneComponent svgRef={ref} {...props} />
));
export default ForwardRef;
