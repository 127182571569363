/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounPhoneComponent = ({ svgRef }) => (
  <svg width={17.712} height={17.712} viewBox="0 0 17.712 17.712" ref={svgRef}>
    <defs>
      <style>
        {
          ".ac,.bc{fill:none;stroke:#4a4a4a;stroke-width:0.5px;}.a{fill-rule:evenodd;}"
        }
      </style>
    </defs>
    <g transform="translate(0.256 0.25)">
      <g transform="translate(0 0)">
        <path
          className="ac"
          d="M3.96,38.992l1.407-1.625a.669.669,0,0,0,.034-.842l-2-2.731a.665.665,0,0,0-.735-.249A3.422,3.422,0,0,0,.3,38.357,18.45,18.45,0,0,0,10.7,48.751a3.422,3.422,0,0,0,4.812-2.36.665.665,0,0,0-.249-.735l-2.731-2a.669.669,0,0,0-.842.034l-1.625,1.407a13.1,13.1,0,0,1-6.1-6.1Z"
          transform="translate(-0.005 -31.843)"
        />
        <path
          className="bc"
          d="M173.654,8.2a.708.708,0,1,0,1.417,0,8.2,8.2,0,0,0-8.2-8.2.708.708,0,1,0,0,1.417A6.785,6.785,0,0,1,173.654,8.2Z"
          transform="translate(-157.866 0)"
        />
        <path
          className="bc"
          d="M170.907,60.656a.708.708,0,1,0,1.417,0,5.447,5.447,0,0,0-5.446-5.446.708.708,0,0,0,0,1.417,4.031,4.031,0,0,1,4.029,4.029Z"
          transform="translate(-157.875 -52.454)"
        />
        <path
          className="bc"
          d="M168.191,113.136a.707.707,0,0,0,1.413,0,2.684,2.684,0,0,0-2.69-2.69.707.707,0,0,0,0,1.413,1.28,1.28,0,0,1,1.273,1.273Z"
          transform="translate(-157.913 -104.936)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounPhoneComponent svgRef={ref} {...props} />
));
export default ForwardRef;
