/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounEmailComponent = ({ svgRef }) => (
  <svg width={22} height={11} viewBox="0 0 22.301 11.186" ref={svgRef}>
    <defs>
      <style>{".a{fill:#fff;}"}</style>
    </defs>
    <g transform="translate(-2.7 -26.3)">
      <path
        className="a"
        d="M11.573,37.486h11.28A2.155,2.155,0,0,0,25,35.338V28.447A2.155,2.155,0,0,0,22.853,26.3H11.573a2.155,2.155,0,0,0-2.147,2.147v6.891A2.171,2.171,0,0,0,11.573,37.486Zm-1.558-9.038a1.556,1.556,0,0,1,1.558-1.558h11.28a1.556,1.556,0,0,1,1.558,1.558v6.891A1.556,1.556,0,0,1,22.853,36.9H11.573a1.556,1.556,0,0,1-1.558-1.558Zm1.7,6.419,3.611-3.186L11.738,28.9a.286.286,0,0,1,.354-.448l5.145,3.988,5.121-4.012a.286.286,0,0,1,.354.448l-3.54,2.785,3.587,3.209a.294.294,0,0,1,.024.4.256.256,0,0,1-.212.094.268.268,0,0,1-.189-.071L18.7,32.011l-1.3,1.015a.228.228,0,0,1-.33,0l-1.251-.968-3.729,3.233a.268.268,0,0,1-.189.071.256.256,0,0,1-.212-.094A.292.292,0,0,1,11.715,34.866ZM8.293,31.893a.273.273,0,0,1-.283.283H4.682a.283.283,0,0,1,0-.566H8.01A.287.287,0,0,1,8.293,31.893ZM2.7,29.675a.273.273,0,0,1,.283-.283h5a.283.283,0,0,1,0,.566H3.007A.279.279,0,0,1,2.7,29.675Zm5.593,4.437a.273.273,0,0,1-.283.283H5.815a.283.283,0,1,1,0-.566H8.01A.272.272,0,0,1,8.293,34.111Z"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounEmailComponent svgRef={ref} {...props} />
));
export default ForwardRef;
