// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bodyMain_bodymain__2hyFs{\n    max-width: 1920px; \n    margin: 0 auto;\n    padding: 0px 50px\n}\n\n.bodyMain_section__2gm7b{\n    height: 80vh;\n    overflow-y: auto;\n}\n\n.bodyMain_head__3ZaFA{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.bodyMain_headname__536Nu{\n    font-size: 30px;\n    text-transform: capitalize;\n}\n.bodyMain_headerDivider__22qHh{\n    width: 90%;\n    height: 49%;\n    border-bottom: 1px solid rgba(112,112,122,0.2);\n}\n.bodyMain_scrollContainer__3a5ig{\n    width: 100%;\n    height: 0vh;\n    overflow: hidden;\n}\n.bodyMain_scrollContainerActive__1rSGU{\n    width: 100%;\n    height: 100vh;\n}\n\n@media only screen and (max-width: 600px) {\n    .bodyMain_bodymain__2hyFs{\n        padding: 0px 15px\n    }\n  }\n  @media only screen and (max-width:1025px) and (min-width: 601px) {\n    .bodyMain_bodymain__2hyFs{\n        padding: 0px 15px\n    }\n  }\n", ""]);
// Exports
exports.locals = {
	"bodymain": "bodyMain_bodymain__2hyFs",
	"section": "bodyMain_section__2gm7b",
	"head": "bodyMain_head__3ZaFA",
	"headname": "bodyMain_headname__536Nu",
	"headerDivider": "bodyMain_headerDivider__22qHh",
	"scrollContainer": "bodyMain_scrollContainer__3a5ig",
	"scrollContainerActive": "bodyMain_scrollContainerActive__1rSGU"
};
module.exports = exports;
