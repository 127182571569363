import React from 'react'
import style from './CardForJob.module.css'
import DateIcon from './DateIcon/NounMonthlycalendarComponent'
import TimeIcon from "./timeIcon/NounTimeComponent"
import Dotdotdot from 'react-dotdotdot'
import {useHistory} from "react-router-dom"
import langs from "../../stylesForLangs/langs.module.css"
import {getDate} from "../../helpers/Functions"


const JobTypes = [
    {am: "Լրիվ դրույք", us: "Full Time", ru:"НА ПОСТОЯННОЙ ОСНОВЕ"},
    {am: "Կես դրույք", us: "Part Time", ru:"Неполная занятость"},
    {am: "Ազատ գրաֆիկ", us: "Free schedule", ru:"свободный график"},
]

export default function CardForJob(props) {
    const history = useHistory()

   

    return <>
        {props.job && <div className={style.container} onClick={()=>{props.job.path && history.push(`${props.job.path}`)}}>
            <span className={`${style.title} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
                {props.job.title[props.flag]}
            </span>
                <Dotdotdot clamp={3}>
                    <span className={`${style.content} ${props.flag==="am" ? langs.armenianTexts : langs.foreignHeaders}`}>
                        {props.job.content[props.flag]}
                    </span>
                </Dotdotdot>
            <div className={`${style.options} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
            <span className={`${style.date} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9999 4.46569C24.9999 4.10099 24.8551 3.75135 24.5973 3.49348C24.3394 3.23577 23.9898 3.09082 23.6252 3.09082C19.3951 3.09082 6.60478 3.09082 2.37472 3.09082C2.01012 3.09082 1.66045 3.23577 1.40262 3.49348C1.14479 3.75135 1 4.10099 1 4.46569C1 8.03788 1 17.5825 1 21.1547C1 21.5192 1.14479 21.8689 1.40262 22.1267C1.66045 22.3846 2.01012 22.5294 2.37472 22.5294C6.60478 22.5294 19.3951 22.5294 23.6252 22.5294C23.9898 22.5294 24.3394 22.3846 24.5973 22.1267C24.8551 21.8689 24.9999 21.5192 24.9999 21.1547C24.9999 17.5825 24.9999 8.03788 24.9999 4.46569Z" fill="white" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <rect x="4" y="11" width="18" height="10" rx="1" fill="#FBC5C7"/>
            <path d="M6.1297 7.62366C6.91542 7.62366 7.55237 6.98671 7.55237 6.20099C7.55237 5.41527 6.91542 4.77832 6.1297 4.77832C5.34398 4.77832 4.70703 5.41527 4.70703 6.20099C4.70703 6.98671 5.34398 7.62366 6.1297 7.62366Z" stroke="#E75D60" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M19.866 7.62366C20.6517 7.62366 21.2887 6.98671 21.2887 6.20099C21.2887 5.41527 20.6517 4.77832 19.866 4.77832C19.0803 4.77832 18.4434 5.41527 18.4434 6.20099C18.4434 6.98671 19.0803 7.62366 19.866 7.62366Z" stroke="#E75D60" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M1 9.36523H25" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M6 14H7.5" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M6 18H7.5" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M12 14H13.5" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M12 18H13.5" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M18 14H19.5" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M18 18H19.5" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M6.13281 5.9352V1" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            <path d="M19.8682 5.9352V1" stroke="#E75D60" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            </svg>
                &nbsp;&nbsp;
                <span className={style.Deadline} >Deadline: </span>
                &nbsp;
                <span className={style.dateType} >
                    {getDate(props.job.Deadline, props.flag)}
                </span>
            </span>
            &nbsp;&nbsp;
            <span className={`${style.date} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" fill="white" stroke="#37C9AA" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
                <path d="M13 22C17.9706 22 22 17.9706 22 13C22 8.02944 17.9706 4 13 4C8.02944 4 4 8.02944 4 13C4 17.9706 8.02944 22 13 22Z" fill="#D3EFE9"/>
                <path d="M13.0066 4.50977V12.9967L9 17.0025" stroke="#37C9AA" stroke-width="2" stroke-miterlimit="1.5" stroke-linecap="round"/>
            </svg>
                    &nbsp;&nbsp;
                    <span className={style.jobType} >
                {JobTypes[props.job.JobType-1][props.flag]}
                </span>
            </span>
            </div>
                {/* <button className={`${style.button} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`} onClick={()=>{props.job.path && history.push(`${props.job.path}`)}} >
                {props.flag === "us" ? "MORE" : props.flag==="ru" ? "БОЛЕЕ" : "ԱՎԵԼԻՆ"}
                </button> */}
        </div>}
    </>
}
