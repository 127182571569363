/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounEmailComponent = ({ svgRef }) => (
  <svg width={61} height={52} viewBox="0 0 71.265 35.746" ref={svgRef}>
    <defs>
      <style>{".qweewqqwe{fill:#fff;}"}</style>
    </defs>
    <g transform="translate(-2.7 -26.3)">
      <path
        className="qweewqqwe"
        d="M31.055,62.046H67.1a6.888,6.888,0,0,0,6.863-6.863V33.163A6.888,6.888,0,0,0,67.1,26.3H31.055a6.888,6.888,0,0,0-6.863,6.863v22.02A6.937,6.937,0,0,0,31.055,62.046ZM26.078,33.163a4.972,4.972,0,0,1,4.977-4.977H67.1a4.972,4.972,0,0,1,4.977,4.977v22.02A4.972,4.972,0,0,1,67.1,60.16H31.055a4.972,4.972,0,0,1-4.977-4.977Zm5.43,20.512L43.046,43.494,31.583,34.6a.913.913,0,0,1,1.131-1.433l16.44,12.745,16.365-12.82A.913.913,0,0,1,66.65,34.52l-11.312,8.9L66.8,53.675a.938.938,0,0,1,.075,1.282.819.819,0,0,1-.679.3.856.856,0,0,1-.6-.226L53.83,44.55l-4.148,3.243a.729.729,0,0,1-1.056,0l-4-3.092L32.714,55.032a.856.856,0,0,1-.6.226.819.819,0,0,1-.679-.3A.934.934,0,0,1,31.508,53.675Zm-10.935-9.5a.871.871,0,0,1-.9.9H9.035a.9.9,0,0,1,0-1.81H19.668A.917.917,0,0,1,20.573,44.173ZM2.7,37.084a.871.871,0,0,1,.9-.9H19.592a.9.9,0,0,1,0,1.81H3.68A.891.891,0,0,1,2.7,37.084ZM20.573,51.262a.871.871,0,0,1-.9.9H12.654a.9.9,0,1,1,0-1.81h7.013A.869.869,0,0,1,20.573,51.262Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounEmailComponent svgRef={ref} {...props} />
));
export default ForwardRef;
