import React, { useState, useEffect } from 'react';
import loadData from "../helpers/loadData"
import Card from "./card_for_Customers"
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import style from "./customers.module.css"
import NavigateNextIcon from './icons/rightIcon/PathComponent';
import NavigateBeforeIcon from './icons/lefticon/PathComponent';
import { Names } from "../staticTexts"


const styles = makeStyles(() => ({
    section: {
        width: "100%",
        height: "fit-content",
        color: "grey",
        margin: "100px 0px -100px"
    },
    header: {
        position:"relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "28px",
        width: "100%",
        color: "#464646",
        fontWeight: "400",
        padding: "20 0",
    },

    products: {
        width: "100%",
        height: "fit-content",
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "no-wrap",
        overflowX: "hidden",
        overflowY: "hidden",
    },
}))









export default function ScrollableTabsButtonAuto(props) {
    const classes = styles();
    const [index, setIndex] = useState(0);
    const [customers, setCustomers] = useState([])
    const [customersAll, setCustomersAll] = useState([])
    const [count, setCount] = useState(3)
    

    useEffect(() => {
        loadData("Customers", JSON.stringify({ type: "customer" })).then((allcustomers) => {
            setCustomersAll(allcustomers)
            setCustomers(renderAllCustomers(allcustomers, resize()))
        })
        return ()=>{
            window.removeEventListener("resize", resize)
        }
    }, [])

    useEffect(() => {
        setCustomers(renderAllCustomers(customersAll, count))
        resize()
    }, [count, customersAll])

    function resize() {
        if (window.innerWidth <= 600) {
            setCount(3)
            return 3
        } else if (window.innerWidth < 1300) {
            setCount(4)
            return 4
        } else if (window.innerWidth > 1300 && window.innerWidth < 1920) {
            setCount(6)
            return 6
        } else if (window.innerWidth >= 1800) {
            setCount(8)
            return 8
        }
    }
    window.addEventListener('resize', resize)


    const renderAllCustomers = (brand, n = 6) => {
        if (!brand || !brand.length) { return }
        let count = 0
        let render = [[]];
        for (let i = 0; i <= brand.length; i++) {
            if (render[count] && render[count].length === n) {
                count += 1;
            }
            if (!Array.isArray(render[count])) {
                render[count] = [];
            }
            brand[i] && render[count].push(brand[i]);
        }
        render = render.filter(item=>item.length)
        return render;
    }


    function indexDown() {
        if (index - 1 < 0) {
            return
        }
        setIndex(index - 1);
    }
    function indexUp() {
        if (!customers[index + 1]) {
            return
        }
        setIndex(index + 1)
    }

    return (
        <section className={classes.section}>
            <div className={style.headerCustomer}>
                <span style={{ textTransform: "uppercase" }}>{Names.customers[props.flag]}</span>
            </div>
            {customers && customers.length && <div className={classes.products}>
                {customers && customers.length > 1 &&<button className={style.prevNextbutton} onClick={indexDown} ><NavigateBeforeIcon /></button>}
                <div style={{width:"100%"}}>
                <SwipeableViews index={index} onChangeIndex={(i) => { setIndex(i) }} disableLazyLoading enableMouseEvents slideStyle={{ overflow: "hidden" }} >
                    {customers.map((brand) => {
                        return <div key={brand.id} className={brand.length > 1 ? style.container_more : style.container_1}>
                            {brand.map((br, i) => {
                                return <Card  key={i} mainpage={true} flag={props.flag} brand={br} />
                            })}
                        </div>
                    })}
                </SwipeableViews>
                </div>
                {customers && customers.length > 1 &&<button className={style.prevNextbutton} onClick={indexUp} ><NavigateNextIcon /></button>}
            </div>}
            {customers && customers.length > 1 &&
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-5px",
                    marginTop: "15px",
                }}>
                    {customers.map((item, i) => {
                        return <span key={item.id} className={index === i ? "downButtonsClicked" : "downButtons"} onClick={() => { setIndex(i) }}  ></span>
                    })}
                </div>}
        </section>
    )
}

