import React from 'react'

import { withGoogleMap, GoogleMap, Marker, withScriptjs } from "react-google-maps"



function Map(){


    return <GoogleMap
    defaultZoom={15}
    defaultCenter={{lat:40.1714825, lng:44.52750112}}
    >
        <Marker
        
    title={'Unitech'}
    position={{lat:40.1714825, lng:44.52750112}} />
    {/* <InfoWindow
          marker={marker}
          visible={titleShow}>
            <div>
              <h1>{"Unitech"}</h1>
            </div>
        </InfoWindow> */}
    </GoogleMap>
}




const WrappedMap = withScriptjs(withGoogleMap(Map))
export default  function (props){
    return(
        <div style={{width:"100%", height:"100%", borderRadius:"10px", overflow:"hidden"}} >
            <WrappedMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAR9JSqq8FGHx0ddoh10p7NE9eMd9H-pKE`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                defaultOptions={props.defaultOptions}
            />
        </div>
    )
}