import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dotdotdot from 'react-dotdotdot'
// import TodayIcon from '@material-ui/icons/Today';
import TodayIcon from "../bodyMain/newsCardicons/PathComponent"
import ViewsIcon from "../bodyMain/newsCardicons/viewsicon/NounViewComponent"
import {useSelector} from "react-redux"
import {useHistory} from "react-router-dom"

import styles from "./cardforNewsHoe.module.css"

import langs from "../../stylesForLangs/langs.module.css"
import {getDate} from "../../helpers/Functions"
import {Names} from "../../staticTexts"




export default function MediaControlCard(props) {
  const [date, setDate] = useState(null)
  const [header, setHeader] = useState("")
  const history = useHistory()
  const [imgsrc, setImgsrc] = useState("")


  const flag = useSelector(state=>state.flag) 
  const [body, setBody] = useState("")



  useEffect(()=>{
    let getHeader;
    let getBody;
    let date = new Date(+props.news.id)
    setDate(`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`)
    if(props.news.news[flag]){
    let nesinfo = JSON.parse(props.news.news[flag])
    nesinfo && nesinfo.blocks.forEach((info)=>{
      if(info.text.length>3 && info.text.length<15){
        getHeader = info.text
          }else if (info.text.trim() && info.text.length > 50){
            getBody = info.text
          }
    })
    setImgsrc(nesinfo.entityMap[0] && nesinfo.entityMap[0].data.url)
    getHeader ? setHeader(getHeader) : setHeader("No text for this language")
    getBody ?  setBody(getBody) : setBody("No text for this language")
  }else{
    setHeader("No text for this language")
    setBody("No text for this language")
  }
  },[flag, props.news.id, props.news.news])

  return <Card style={props.mainPage && {margin:0} } className={styles.roott} onClick={()=>{
    history.push("/" + props.news.path)
    
    }} >
    {/* <span className={styles.buttons}>
      <Fab onClick={()=>{props.setEditNews({bool:true, news: props.news.news, id:`${props.news.id}`})}}>< EditIcon /></Fab>
    </span> */}
    {imgsrc && <CardMedia
      className={styles.cover}
      image={imgsrc}
    >
    </CardMedia>}
    <div className={styles.details}>
      <CardContent  className={`${flag === "am" ? langs.armenianHeaders : langs.foreignHeaders} ${styles.content}`}>
        <span className={styles.headerName}>
          {header}
        </span>
        
        <span variant="subtitle1" color="textSecondary">
          <div className={styles.textContainer}>
            <Dotdotdot clamp={2}>
              {body}
            </Dotdotdot>
          </div>
        </span>
        <span variant="subtitle1" color="textSecondary">
          <div className={styles.dateContainer}>
            {/* <TodayIcon />  */}
            <span className={styles.read}>
            <span className={styles.line} />
             {Names.readMore[flag]}
            </span>
            
          </div>
        </span>
        <span className={styles.hoverViewMore}>
          <div className={styles.readMoreContainer}>
          <span >{date && getDate(date, flag)}</span>
            {/* <span style={{
              float: "right"
            }}>
              <ViewsIcon /> {props.news.views ? props.news.views : 0}
            </span> */}
          </div>
        </span>
      </CardContent>
    </div>

  </Card>

}