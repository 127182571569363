// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.Error404_cont__2ZUwv{\n    width: 100%;\n    height: 86vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n.Error404_img__27vAI{\n    height: 323px;\n    width: 360px;\n    border-radius: 0px;\n\n}\n.Error404_E404__3CnS7{\n     \n    font-size: 72px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 88px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #3A9EDC;\n    margin-top: 30px;\n}\n\n.Error404_errtext__3UIBV{\n     \n    font-size: 18px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 22px;\n    letter-spacing: 0em;\n    text-align: left;\n\n}\n\n.Error404_contactback__mGBz6{\n     \n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #6D6D6D;\n    margin-top: 20px;\n\n}\n\n@media only screen and (max-width: 600px){\n    .Error404_cont__2ZUwv{\n        width: 100%;\n        height: 86vh;\n        padding: 0px 16px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-direction: column;\n    }\n    .Error404_img__27vAI{\n        height: 240px;\n        width: auto;\n        border-radius: 0px;\n    \n    }\n}", ""]);
// Exports
exports.locals = {
	"cont": "Error404_cont__2ZUwv",
	"img": "Error404_img__27vAI",
	"E404": "Error404_E404__3CnS7",
	"errtext": "Error404_errtext__3UIBV",
	"contactback": "Error404_contactback__mGBz6"
};
module.exports = exports;
