import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { useSelector } from "react-redux";
import styless from "./badgesList.module.css";
// import { Button } from 'reactstrap';

import Collapse from "@material-ui/core/Collapse";
import ArrowDown from "./categories icons/arrowDown/NounArrowComponent";

import { Names } from "../../staticTexts";
import langs from "../../stylesForLangs/langs.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    // padding:"10px",
    marginTop: "25px",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 25px",
    backgroundColor: "#fff",
    padding: "0px 12px",
    boxShadow: "0px 4px 4px 0px rgb(0 0 0 / 6%)",
    borderRadius: "10px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "inherit",
    // textTransform:"uppercase",
    color: "#1F242A",
    fontSize: "24px",
  },
  textAM: {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  categoriesContainer: {
    padding: "25px 34px",
  },
  buttonFilter: {
    boxSizing: "border-box",
    boxShadow: "none",
    backgroundColor: "#fff",
    border: "0.2px solid lightgray",
    transitionDuration: "0",
    transitionProperty: "box-shadow",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.2)",
      border: "none",
    },
  },
}));

export default function NestedList(props) {
  const classes = useStyles();
  const badgesFromRedux = useSelector((state) => state.badges);
  const [collapseAll, setCollapseAll] = useState(true);
  // const [clickedIndex, setClickedIndex] = useState("")

  // const handleClick = (key, name) => {
  //   let i = index.indexOf(key);
  //     if(i === -1){
  //       setIndex([...index, key]);
  //     }else{
  //         let clone = JSON.parse(JSON.stringify(index))
  //         clone.splice(i,1)
  //         setIndex(clone)
  //     }
  //   props.setId(key)
  //   props.setCurrentCategory(name)
  // };

  function handleClick(badge) {
    let clone = [...props.selectedBadge];
    let index = clone.indexOf(badge.id);
    if (index === -1) {
      clone.push(badge.id);
    } else {
      clone.splice(index, 1);
    }
    props.setSelectedBadge(clone);
  }

  useEffect(() => {});

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {props.mobileFilter ? null : <div className={styless.listTitle}>
        <div
          className={
            props.flag === "am"
              ? langs.armenianHeaders600
              : langs.foreignHeaders600
          }
        >
          {`${Names.badge[props.flag]}`}
        </div>
        {collapseAll ? (
          <div
            className={"rightrot"}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setCollapseAll(!collapseAll);
            }}
          >
            <ArrowDown />
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setCollapseAll(!collapseAll);
            }}
          >
            <ArrowDown />
          </div>
        )}
      </div>}
      <Collapse in={collapseAll}>
        <div className={classes.categoriesContainer}>
          {badgesFromRedux &&
            badgesFromRedux.map((badges) => {
              return (
                <>
                  <label className={styless.container}>
                    <span className={styless.text}>
                      {badges.name[props.flag]}
                    </span>
                    <input
                      type="checkbox"
                      checked={props.selectedBadge.indexOf(badges.id) !== -1}
                      onChange={() => {
                        handleClick(badges);
                      }}
                    />
                    <span className={styless.checkmark}></span>
                  </label>
                </>
              );
            })}
        </div>
      </Collapse>
    </List>
  );
}
