import React from "react"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import usFlag from "./flagIcons/usFlag.png"
import ruFlag from "./flagIcons/ruFlag.png"
import amFlag from "./flagIcons/amFlag.png"
import { useSelector, useDispatch } from "react-redux"
import {CHANGE_FLAG} from "../redux/actions/languageAction/languageAction"

import { styles } from "./style";

export default function SelectLanguage(props){
    const dispatch = useDispatch()
    const classes = styles();
    const flag = useSelector(state => state.flag)
    
    function handleChange(value){
      dispatch(CHANGE_FLAG(value.target.value))
     }
                
    
    
    
    
    return           <Select
                        value={flag}
                        disableUnderline
                        // margin="dense"
                        style={{margin:"0px 0px 0px 10px"}}
                        onChange={handleChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label',  }}
                        IconComponent={()=>{return null}}
                     >
                        <MenuItem value={"us"}> <img height="28px" src={usFlag} alt="flag" /></MenuItem>
                        <MenuItem value={"ru"}> <img height="30px" src={ruFlag} alt="flag" /></MenuItem>
                        <MenuItem value={"am"}> <img height="30px" src={amFlag} alt="flag" /></MenuItem>
                     </Select>
}