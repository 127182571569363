import React, { useState, useEffect, useRef } from 'react';
// import Typography from '@material-ui/core/Typography';
// import loadData from "../helpers/loadData"
import CardForProduct from "./cardFORrelatedProducts"
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { styles } from "./style"
// import Paper from '@material-ui/core/Paper';

// import EditIcon from '@material-ui/icons/Edit';
// import CategoriesEditDialog from "./mainPageCategoriesEditDialog"
// import ProductsEditDialog from "./ShowProductsWithFiltersDIALOG"
// import LineLoad from "../loadingCircular"
// import {Paper} from "@material-ui/core"
import { BsChevronLeft, BsChevronRight } from "react-icons/all"


// import Divider from '@material-ui/core/Divider';
import "./scrollRelated.css"
// const useStyles = makeStyles((theme) => ({
//     container: {
//       display: 'grid',
//       gridTemplateColumns: 'repeat(12, 1fr)',
//       gridGap: theme.spacing(1),
//     },
// }));
// import allstyles from "./cardforRelated.module.css"







export default function ScrollableTabsButtonAuto(props) {
    const classes = styles();
    const [related, setRelated] = useState([])
    let relatedRef = useRef()


    useEffect(()=>{
        if(props.RelatedProducts){
            setRelated(props.allProducts.filter(item=>props.RelatedProducts.includes(item.id)))
        }
    },[props.RelatedProducts, props.allProducts])





    function scrollRight() {
        relatedRef.current.scrollBy(299 , 0)
        return
    }
    function scrollLeft() {
        relatedRef.current.scrollBy(-299 , 0)
        return
    }


    return (
        <>
        
        <div style={{ border: "none" }}>
            {/* <ProductsEditDialog
                selected={props.selected}
                setSelected={props.setSelected}
                currentID={props.currentID}
                relatedProducts={props.relatedProducts}
                setRelatedProducts={props.setRelatedProducts}
                RelatedProducts={props.RelatedProducts}
                setRelatedProducts={props.setRelatedProducts}
                open={addRelatedProductsDialog}
                setOpen={setAddRelatedProductsDialog}
                flag={props.flag}
            /> */}

            <div style={{ position: "relative"}}>
            {/* {!!props.RelatedProducts.length && <button className={classes.buttonNavLeft} onClick={(e) => { e.preventDefault(); scrollLeft() }}>
                    <BsChevronLeft />
                </button>
                } */}
                <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", alignItems:"center" }} ref={relatedRef} >
                        {related.length ? related.map((product, i) => {
                            return  <div className={"cardContainerForHoverRelated"} >
                                <CardForProduct
                                    product={product}
                                    related={true}
                                    key={i}
                                    flag={props.flag}
                                    />
                                    </div>
                        })
                            :
                            <span style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%", height:"199px"}}>
                                No Related Products
                            </span>
                        }
                </div>
                {/* {!!props.RelatedProducts.length &&<button className={classes.buttonNavRight} onClick={(e) => { e.preventDefault(); scrollRight() }}>
                    <BsChevronRight />
                </button>} */}
            </div>


        </div>
        </>

    )
}
