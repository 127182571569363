import React, {  useEffect } from "react"
import "./style.css"
import CardForNews from "./cardForNews copy"
import SwipeableViews from 'react-swipeable-views';
import ArrowRight from "../bodyMain/newsCardicons/arrow/PathComponent"
import {Names} from "../../staticTexts"
import { useSelector } from "react-redux";
import langs from "../../stylesForLangs/langs.module.css"

export default function (props) {
    const { index, setIndex, allNews } = props;
    const flag = useSelector(s=>s.flag)


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    const handleChangeIndex = (index) => {
        setIndex(index);
    };






    return <section className="fullsection">
        <div className={` ${"headerz"} ${flag}M  `}>
            <span className={`headname ${flag}M `}>{Names.footer.News[flag]}</span>
        </div> 

        {allNews.length ? <div style={{display:"flex"}}>
            {/* <div className="navscont" >
            <div onClick={() => { if (index - 1 >= 0) { setIndex(index - 1) } }} className={`navscontchild rightrot`} >
                <ArrowRight  />
            </div>
            </div> */}
            <div style={{width:"100%", margin:"0 auto"}} >
            <SwipeableViews index={index} onChangeIndex={handleChangeIndex} disableLazyLoading enableMouseEvents slideStyle={{ overflow: "hidden" }} >
                {allNews.map((brand, i) => {
                    return <div key={i} className={brand.length > 1 ? "container_more" : "container_1"}>
                        {brand.map((br, i) => {
                            return <div className="containerCardForNews"><CardForNews key={i} mainpage={true}  news={br} /></div>
                        })}
                    </div>

                })}
            </SwipeableViews>
            </div>
            {/* <div className="navscont">
            <div onClick={() => { if (index + 1 < allNews.length) { setIndex(index + 1) } }} className="navscontchild" >
                <ArrowRight className="rightrot" />
            </div>
            </div> */}
        </div>

            : <div>no News</div>
        }
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            marginBottom: "15px",
            marginTop: "15px",
        }}>
            {/* <span onClick={() => { if (index - 1 >= 0) { setIndex(index - 1) } }}  className={`arrowButtons rightrot navscontchild`}>
                  <ArrowRight /> 
                </span> */}
            {allNews.map((item, i) => {
                
                return <span key={i} className={index === i ? "downButtonsClicked" : "downButtons"} onClick={() => { setIndex(i) }} />
            })}
            
            {/* <span onClick={() => { if (index + 1 < allNews.length) { setIndex(index + 1) } }}  className={`arrowButtons arrowRight navscontchild`}>
                    <ArrowRight /> 
                  </span> */}

        </div>


    </section>
}