// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* .svg path{\n    stroke: black !important;\n    fill: white !important;\n}\n.not path{\n    stroke: white !important;\n    fill: black !important;\n} */\n\n\n.cssforsvg_svg__3CXwj path{\n    /* stroke: white !important; */\n    fill: white !important;\n}\n.cssforsvg_not__FO_GB path{\n    \n    /* stroke: black !important; */\n    fill: black !important;\n}\n\n", ""]);
// Exports
exports.locals = {
	"svg": "cssforsvg_svg__3CXwj",
	"not": "cssforsvg_not__FO_GB"
};
module.exports = exports;
