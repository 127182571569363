/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounMenuComponent = ({ svgRef }) => (
  <svg width={26} height={20} viewBox="0 0 26.328 20.635" ref={svgRef}>
    <defs>
      <style>{".attt{fill:#4a4a4a;}"}</style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0)">
        <path
          className="attt"
          d="M14.067,973.362a1.067,1.067,0,0,0,0,2.135H38.26a1.067,1.067,0,0,0,0-2.135Zm0,9.25a1.067,1.067,0,1,0,0,2.135H38.26a1.067,1.067,0,1,0,0-2.135Zm0,9.25a1.067,1.067,0,1,0,0,2.135H38.26a1.067,1.067,0,1,0,0-2.135Z"
          transform="translate(-13 -973.362)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounMenuComponent svgRef={ref} {...props} />
));
export default ForwardRef;
