/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PathComponent = ({ svgRef }) => (
  <svg width={42} height={30} viewBox="0 0 42.098 21.116" ref={svgRef}>
    <defs>
      <style>{".mobileDrawer{fill:#32406E;}"}</style>
    </defs>
    <path
      className="mobileDrawer"
      d="M19.45,47.416H40.745A4.069,4.069,0,0,0,44.8,43.362V30.354A4.069,4.069,0,0,0,40.745,26.3H19.45A4.069,4.069,0,0,0,15.4,30.354V43.362A4.1,4.1,0,0,0,19.45,47.416ZM16.51,30.354a2.937,2.937,0,0,1,2.94-2.94H40.745a2.937,2.937,0,0,1,2.94,2.94V43.362a2.937,2.937,0,0,1-2.94,2.94H19.45a2.937,2.937,0,0,1-2.94-2.94Zm3.208,12.117,6.816-6.014L19.762,31.2a.539.539,0,1,1,.668-.846l9.712,7.529,9.667-7.573a.539.539,0,0,1,.668.846l-6.682,5.257,6.771,6.059a.554.554,0,0,1,.045.757.484.484,0,0,1-.4.178.506.506,0,0,1-.356-.134L32.9,37.081,30.454,39a.431.431,0,0,1-.624,0L27.469,37.17l-7.039,6.1a.506.506,0,0,1-.356.134.484.484,0,0,1-.4-.178A.552.552,0,0,1,19.718,42.471Zm-6.46-5.613a.515.515,0,0,1-.535.535H6.442a.535.535,0,1,1,0-1.069h6.281A.542.542,0,0,1,13.258,36.858ZM2.7,32.67a.515.515,0,0,1,.535-.535h9.444a.535.535,0,1,1,0,1.069h-9.4A.526.526,0,0,1,2.7,32.67Zm10.558,8.375a.515.515,0,0,1-.535.535H8.58a.535.535,0,0,1,0-1.069h4.143A.514.514,0,0,1,13.258,41.046Z"
      transform="translate(-2.7 -26.3)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
