import React, { useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";

import { Link, useHistory } from "react-router-dom";
import "./styleHeader.css";
import style from "./hover.module.css";
import { Svgloader } from "../ShowProducts/svgLoader";
import { useSelector } from "react-redux";
import Langs from "../../stylesForLangs/langs.module.css";
import { Names } from "../../staticTexts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    outline: "none",
    width: "100%",
    // margin:"0 10px"
  },
  paper: {
    background: "#fff",
    outline: "none",
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const popperRef = React.useRef();
  const allCategories = useSelector((state) => state.categories);
  const [categriesIds, setcategriesIds] = useState([]);
  const history = useHistory()

  useEffect(() => {
    if (allCategories) {
      let categoriesIds = allCategories.map((item) => item.id);
      setcategriesIds(categoriesIds);
    }
  }, [allCategories]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseOnLeave = () => {
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleIcon(item){
    if(item.category.icon.includes("defaultIcon")){
        let category = allCategories.find(x=>x.id === item.category.id)
        return <img height="32px" width="32px" src={`${process.env.REACT_APP_API_URL}/originals/${category.icon}`}/>  
    }else{
        return <img height="32px" width="32px" src={`${process.env.REACT_APP_API_URL}/originals/${item.category.icon}`}/>  
    }
}

  return (
    <div className={classes.root}>
      <button
        className={props.classNameprop}
        onMouseEnter={handleOpen}
        onMouseLeave={handleCloseOnLeave}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={() => {
          handleToggle();
          !window.location.pathname.toLocaleLowerCase().includes("products") &&
            window.scrollTo(0, 0);
        }}
        style={{ padding: "16px 2vw", whiteSpace: "nowrap", marginLeft: "0px" }}

        // variant="contained"
      >
        {Names.products[props.flag]}
      </button>
      <Popper
        ref={popperRef}
        anchorEl={anchorRef.current}
        style={{ width: "100%" }}
        onMouseEnter={handleOpen}
        onMouseLeave={handleCloseOnLeave}
        open={open}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            timeout={100}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ background: "inherit", paddingRight: "10px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={style.content}>
                  {props.hover &&
                    props.hover.map((item) => {
                      if (!categriesIds.includes(item.category.id)) {
                        return null;
                      }
                      return (
                        <div
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            top: item.pos.y,
                            left: item.pos.x,
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              !window.location.pathname
                                .toLocaleLowerCase()
                                .includes("products") && window.scrollTo(0, 0);
                              handleCloseOnLeave();
                              history.push({
                                pathname: "/products",
                                state: item.category.id,
                              });
                            }}
                            className={` ${props.flag}M ${style.categoryName}`}
                          >
                            {handleIcon(item)}
                            &nbsp;&nbsp;
                              {item.category.name[
                                props.flag
                              ]}
                            {/* </Link> */}
                          </span>
                          {item.hasOwnProperty("children") &&
                            item.children.map((el, i) => {
                              if (!categriesIds.includes(el.id)) {
                                return null;
                              }
                              return (
                                <span
                                  key={i}
                                  className={`${props.flag}R ${style.childButton}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    history.push({
                                      pathname: "/products",
                                      state: el.id,
                                    });
                                    handleCloseOnLeave();
                                  }}
                                  style={{ paddingLeft: "50px", lineHeight:`${props.lineHeight}px` }}
                                >
                                  {/* <Link style={{textDecoration:"none", color:"inherit", textAlign:"left"}} to={{pathname: "/products", state: el.id} }>  */}
                                  {el.name[props.flag]}
                                  {/* </Link> */}
                                </span>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
