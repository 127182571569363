import React from 'react';
import tableStyle from "./table.module.css"
// import { BsDownload } from "react-icons/bs"
import BsDownload from "../Downloads/icons/downloadIcon/NounDownloadComponent"
import { IconButton } from '@material-ui/core'
import NoResults from "../ShowProducts/noProductsFound"
import langs from "../../stylesForLangs/langs.module.css"
import { Names } from '../../staticTexts';


export default function SimpleTable(props) {
 


  return <div>
    {props.table.length ? <table className={tableStyle.table} >
      <tr className={tableStyle.trHeader} >
        <th className={`${tableStyle.trHeader} ${props.flag === "am" ? langs.armenianHeaders : langs.foreignHeaders}`} style={{ position: "relative" }} >
          <span className={tableStyle.textDownload} style={{color:"#FFF"}}>
            {Names.date[props.flag]}
          </span>
        </th>
        <th className={`${tableStyle.trHeader} ${props.flag === "am" ? langs.armenianHeaders : langs.foreignHeaders}`} style={{ position: "relative" }} >
          <span className={tableStyle.textDownload} style={{color:"#FFF"}}>
            {Names.description[props.flag]}
          </span>
        </th>
      </tr>

      {Array.isArray(props.table) && props.table.map((row, i) => {
        return <>
          {row.name && row.url ?
            <tr className={tableStyle.tr} key={i}>
              <th className={`${tableStyle.th} ${props.flag === "am" ? langs.armenianHeaders : langs.foreignHeaders}`} style={{ position: "relative" }} >

                <span className={tableStyle.textDownload}>
                  { row.date && typeof(row.date)==="string" ? new Date(+row.date).toLocaleDateString() : typeof(row.date)!=="string" ? new Date(row.date).toLocaleDateString() : "undefined" }
                </span>
              </th>
              <th className={`${tableStyle.th} ${props.flag === "am" ? langs.armenianHeaders : langs.foreignHeaders}`} style={{ position: "relative" }} >

                <span className={tableStyle.textDownload}>
                  {row.name}
                </span>
                <span className={tableStyle.downloadIcon} >
                  <IconButton color="primary" onClick={() => { window.open(row.url) }} className={tableStyle.iconbutton}>
                    <BsDownload style={{ fontSize: "20px", color: "#FF5858" }} />
                  </IconButton>
                </span>

              </th>

            </tr>
            :
            null
          }
        </>
      })}
    </table>
      :
      <div style={{ marginTop: "-72px" }}> <NoResults /></div>
    }
  </div>
}