/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = ({ svgRef }) => (
  <svg width={22} height={14} viewBox="0 0 22.643 14.341" ref={svgRef}>
    <g transform="translate(22.643 14.341) rotate(180)">
      <path
        d="M22.643,7.17a.877.877,0,0,0-.213-.519L16.392.236A.756.756,0,0,0,15.325.206.777.777,0,0,0,15.3,1.273l4.847,5.142H.755a.755.755,0,1,0,0,1.51H20.143L15.3,13.067a.79.79,0,0,0,.029,1.067.756.756,0,0,0,1.067-.03l6.038-6.416A.671.671,0,0,0,22.643,7.17Z"
        transform="translate(0)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <GroupComponent svgRef={ref} {...props} />
));
export default ForwardRef;
