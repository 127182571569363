/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = ({ svgRef }) => (
  <svg width="39" height="28" viewBox="0 0 16.16 16.166" ref={svgRef}>
    <defs>
      <style>
        {".ddd{fill:none;stroke:#9F1A1E;stroke-width:0.9px;fill-rule:evenodd;}"}
      </style>
    </defs>
    <g transform="translate(0.281 0.25)">
      <g transform="translate(0 0)">
        <path
          className="ddd"
          d="M568.614,111.018c1.547,1.547,3.338,3.026,4.046,2.318,1.014-1.013,1.639-1.9,3.874-.1s.518,3-.464,3.977c-1.133,1.134-5.357.06-9.532-4.116s-5.249-8.4-4.115-9.537c.982-.982,2.179-2.7,3.975-.463s.914,2.86-.1,3.875c-.708.708.771,2.5,2.317,4.047"
          transform="translate(-562 -102)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <GroupComponent svgRef={ref} {...props} />
));
export default ForwardRef;
