/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import styles from "../../style.module.css"
const NounJobComponent = ({ svgRef }) => (
  <svg className={styles.a} viewBox="0 0 63.542 52.621" ref={svgRef}>
    <defs>
      <style>{".a{fill:#fff;}"}</style>
    </defs>
    <g transform="translate(0 -11)">
      <path
      className={styles.a}
        d="M52.921,32.843A10.921,10.921,0,1,0,42,21.921,10.89,10.89,0,0,0,52.921,32.843Zm0-19.857a8.936,8.936,0,1,1-8.936,8.936A8.962,8.962,0,0,1,52.921,12.986Z"
        transform="translate(-21.15)"
      />
      <path
      className={styles.a}
        d="M103.138,69.85H94.2V61.46C94.2,54.312,89.536,49.4,82.486,49A11.6,11.6,0,0,1,80.6,50.936h.993c6.354,0,10.624,4.22,10.624,10.475V69.85h-.794l-.248,1.986h11.964a.993.993,0,1,0,0-1.986Z"
        transform="translate(-40.588 -19.136)"
      />
      <path
      className={styles.a}
        d="M11.914,69.9v-8.39c0-6.255,4.269-10.475,10.624-10.475h.993A13.874,13.874,0,0,1,21.644,49.1c-7.049.347-11.716,5.262-11.716,12.411V69.9H.993a.993.993,0,0,0,0,1.986H12.957L12.708,69.9Z"
        transform="translate(0 -19.186)"
      />
      <path
      className={styles.a}
        d="M60.764,71H30.979A3,3,0,0,0,28,74.028l1.986,16.878a2.977,2.977,0,0,0,2.979,2.929H58.778a2.945,2.945,0,0,0,2.979-2.929l1.986-16.779v-.1A3,3,0,0,0,60.764,71Zm-.993,19.708v.1a1.04,1.04,0,0,1-.993,1.042H32.964a1.008,1.008,0,0,1-.993-1.042L29.986,73.929a1,1,0,0,1,.993-.993H60.764a1,1,0,0,1,.993.993Z"
        transform="translate(-14.1 -30.214)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounJobComponent svgRef={ref} {...props} />
));
export default ForwardRef;
