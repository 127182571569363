/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const DownArrowComponent = (props) => (
  <svg width={ 10} height={10.2} style={{cursor:"pointer"}} viewBox="0 0 4.401 2.2" >
    <defs>
      <style>{".asd{fill:#1B1B1B;} .dsa{fill:#1B1B1B;}"}</style>
    </defs>
    <g transform="translate(0 0)">
      <path
        className={props.footer ? "asd" : "dsa"}
        d="M0,53.333l2.2,2.2,2.2-2.2Z"
        transform="translate(0 -53.333)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <DownArrowComponent svgRef={ref} {...props} />
));
export default ForwardRef;
