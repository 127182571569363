/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounHourComponent = ({ svgRef }) => (
  <svg width={39} height={20} viewBox="0 0 19.1 20.497" ref={svgRef}>
    <defs>
      <style>{".qweewq{fill:#9F1A1E;stroke:#9F1A1E;stroke-width:0.3px;}"}</style>
    </defs>
    <g transform="translate(-9.75 -6.744)">
      <path
        className="qweewq"
        d="M17.869,7a.233.233,0,0,0-.08.429L19.4,8.39h-.1a9.271,9.271,0,1,0,7.157,3.342.233.233,0,1,0-.363.291A8.846,8.846,0,1,1,19.3,8.855h.1l-1.613.966a.233.233,0,1,0,.233.392l2.325-1.4a.233.233,0,0,0,0-.392l-2.325-1.4A.233.233,0,0,0,17.869,7Zm1.431,4.65a6.045,6.045,0,1,0,6.045,6.045A6.049,6.049,0,0,0,19.3,11.645Zm0,.465a5.58,5.58,0,1,1-5.58,5.58A5.577,5.577,0,0,1,19.3,12.11Zm-.022,1.853-.029.007a.233.233,0,0,0-.182.233V17.69a.233.233,0,0,0,.116.2l2.093,1.163a.234.234,0,0,0,.233-.407l-1.976-1.1V14.2a.233.233,0,0,0-.254-.24Z"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounHourComponent svgRef={ref} {...props} />
));
export default ForwardRef;
