
// import {headerButtonsRU,headerButtonsEN} from "../../wholeobject"
// import { lang } from "moment"
let flagFromLocaleStorage = localStorage.getItem("flag_Unitech")
flagFromLocaleStorage = flagFromLocaleStorage ? flagFromLocaleStorage : "us"
document.body.style.fontFamily = getComputedStyle(document.documentElement).getPropertyValue(`--${flagFromLocaleStorage}R`)
let initState={flag:flagFromLocaleStorage}


const fetchReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_PRODUCTS':
            return {...state, products: action.payload}
        case 'SET_CATEGORIES':
            return {...state, categories: action.payload}
        case 'SET_BADGES':
            return {...state, badges: action.payload}
        case 'SET_BRANDS':
            return {...state, brands: action.payload}
        case 'SET_NEWS':
            return {...state, news: action.payload}
        case 'CHANGE_LANGUAGE':
            
            document.body.style.fontFamily = getComputedStyle(document.documentElement).getPropertyValue(`--${action.payload}R`)
            localStorage.setItem("flag_Unitech", action.payload)
           return {...state, flag: action.payload}
            case 'SET_JOBS':
            return {...state, jobs: action.payload}
        default:
            return {...state}

    }
}
export default fetchReducer
