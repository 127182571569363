
import React, { useState, useEffect } from 'react';
import style from './Jobs.module.css'
import loadData from '../../helpers/loadData'
import CardForJob from './CardForJob'
import { CircleLoader } from 'react-spinners';
import langs from "../../stylesForLangs/langs.module.css"
import {Names} from "../../staticTexts"
import {MakeBreadcrumbs} from '../../helpers/helperComponents/helperComponents'
import Banner from "../banner/banner"

const Jobs = props => {

    const [Jobs, setJobs] = useState([])
    const [jobText, setjobText] = useState({ text: { am: "", us: "", ru: "" }, img: "" })

    const [fileDefault, setFileDefault] = useState("")
    const [isFetched, setisFetched] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
        loadData("jobs", JSON.stringify({ type: "job" })).then((res) => {
            setJobs(res)
        })
        loadData("jobs", JSON.stringify({ type: "jobtext" })).then((res) => {
            setFileDefault(res[0].img)
            setjobText({ text: res[0].text, img: res[0].img })
            setisFetched(true)
        })

    }, [])

    return (
        <section className={style.section}>
             <Banner link="career.png" page={Names.Job[props.flag]} gradient={"none"} textColor={"#8F9AB2"} />
            <div className={style.breadcrumbs}>
            {MakeBreadcrumbs([{name: Names.Job[props.flag], path:"/aboutUs"}],props.flag)}
        </div>
            {isFetched ? <div className={style.fullSection}> 
            <div className={style.pageName} >
                {Names.Job[props.flag]}
            </div>
            {/* <div className={style.textContainer} >
            
                    <img className={style.img} alt="job"
                        src={fileDefault ? `${process.env.REACT_APP_API_URL}/originals/${fileDefault}` : `${process.env.REACT_APP_API_URL}/originals/defaultIimage.png`}

                    />

                <div className={`${style.jobtext} ${props.flag==="am" ? langs.armenianHeaders : langs.foreignHeaders}`}>
                    {jobText.text[props.flag]}
                </div>

            </div> */}
                <div className={style.jobCards} style={{ margin: "0 auto" }} >
                    {Jobs.length ? Jobs.map(job => {
                        return <CardForJob job={job} flag={props.flag} />
                    })
                        :
                    <div className={style.noVacancy} >
                        <img src="/No_Vacancy.png"/>
                        <div className={style.noVacText} >No vacancy</div>
                        <div className={style.chackLater} >We don't have any positions available at the moment. Please check back later.</div>
                    </div>
                    }
                </div>
                </div>
                :
                <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <CircleLoader color={"#9F1A1E"}/>
                </div>
            }

        </section>
    );
};



export default Jobs;