import  {makeStyles} from '@material-ui/core/styles';



export const styles = makeStyles(theme => ({
    section:{
        width: "100%",
        height: "fit-content",
        color: "grey",
        margin:"20px 0"
    },
    sectionHaeder:{
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        fontSize:"23px",
        color:"black"
    },
    header:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        width: "100%",
        padding: "20 0",
    },
    prevNextbutton:{
        border: "none",
        width:"23px",
        height:"23px",
        fontSize:"5px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        color:"gray",
        // marginLeft: "20px",
        transitionDuration: "0.5s",
        "&:hover":{
            backgroundColor:"red",
            color:"white",
            // boxShadow: "2px 2px 2px black",
            // -webkit-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.55);
            // -moz-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.55);
            boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.55)"
            
        }
    },

    prevNextbuttonContainer:{
        width:"60px",
        height:"23px",
        display:"flex",
        justifyContent:"space-between",
        flexWrap:"none",
    },
    button:{
        // outline: "none &:!important",
        // outlineOffset: "none &:!important",
        color: "grey",
        padding: "10px 50px",
        fontSize:"16px",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid lightGrey",
        backgroundColor:"inherit",
        transitionDuration: "0.5s",
        "&:hover":{
            backgroundColor:"lightGrey",

        }
    },
    activeButton:{
        borderBottom: "2px solid red",
    },
    // header:{
    //     display:"flex",
    //     flexWrap: "wrap",
    //     width: "100%",
    //     padding: "20 0",
    // },
    products:{
        width: "100%",
        height: "fit-content",
        margin: "10px 0",
        display:"flex",
        justifyContent:"space-between",
        flexWrap: "wrap",
        overflowX: "hidden",
        overflowY:"hidden",
    },
    // Muibutton:{
    //     root:{
    //         margin: 0,
    //         padding: 0
    //     },
    // },
    card:{
        // position: "relative",
        cursor:"pointer",
        width: "255px",
        height: "253px",
        margin:"2px",
        marginLeft:"44px",
        padding:"10px",
        background:"radial-gradient(#FFF, #F5FBFF)",
        // border:"0.1px solid lightgrey",
        boxSizing:"border-box",
        display: "flex",
        justifyContent: "space-around",
        alignItems:"center",
        flexDirection:"column",
        transitionDuration: "0.5s",

        "&:hover":{
            // backgroundColor:"lightGrey",
            boxShadow: "0px 0px 10px -1px rgba(0,0,0,0.55)"
        },
        "&:last-child":{
            marginRight:"44px",
        }
    },
    imgcontainer:{
        height:"116px",
        width:"116px",
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
    },
    cardBrand:{
        position: "relative",
        cursor:"pointer",
        width: "150px",
        height: "150px",
        // backgroundColor:"lightgrey",
        // border:"0.1px solid lightgrey",
        boxSizing:"border-box",
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        flexDirection:"column",
        transitionDuration: "0.5s",

        "&:hover":{
            // backgroundColor:"lightGrey",
            boxShadow: "0px 0px 10px -1px rgba(0,0,0,0.55)"
        }
    },
    cardImg:{
        width:"100%",
    },
    cardBadge:{
        position: "absolute",
        top: "0",
        right: "0",
        width: "100px"
    },
    productShortText:{
        fontSize:"15px",
        padding:"0 15px"
    },
    productName:{ 
        fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        color: "#FB1F1F",
        fontSize:"18px",
        // fontWeight: "bold"
    },
    dividerDiv: {
        borderBottom: "1px solid gray",
        width: "80%",
        height: "50%",
    
      },
}))