import React, { useState, useEffect } from 'react';

import PhoneIcon from './Icons/phoneIcon/NounPhoneComponent';
import MailIcon from './Icons/MailIcon/NounEmailComponent';
import LocationOnIcon from './Icons/locationIcon/NounPlaceComponent';
import FacebookIcon from './Icons/fbicon/FacebookComponent';
import CallGreenIcon from "./Icons/callIcon/NounPhoneComponent"
// import LOGO_Unitech_White from "./Group 68.png"
import { Link } from "react-router-dom"
import classes from "./footer.module.css"
import loadData from '../../helpers/loadData';
import Map from "../maps/maps"
import MapWitoutControls from "../maps/mapsWithoutControls"
import {Names} from "../../staticTexts"
import langs from '../../stylesForLangs/langs.module.css';
import "./divider.css"
import SubFooter from '../bodyMain/subFooterContacts';



export default function Footer(props) {
    const [contacts, setContacts] = useState(null)
    const [clickedMap, setClickedMap] = useState(false)


    useEffect(() => {
        // if (!contacts) {
            loadData("staticpages", JSON.stringify({ id: "contacts" })).then(res => {
                if (res && res[0]) {
                    setContacts(res[0])
                }
            })
        // }commented fo console warning
    }, [])

    return <>
    <div style={{backgroundColor:"#1B233C", height:"fit-content"}} className={`${props.flag}R`} >
        <SubFooter flag={props.flag} /> 
        </div>
    <div style={{backgroundColor:"#283458"}}>
        {clickedMap ?
            <div style={{ width: "100%", height: "540px", position: "relative" }}>
                <button
                    variant="contained"
                    color="white"
                    style={{     
                        height: "40px",
                        width: "170px",
                        backgroundColor: "white",
                        border: "none",
                        fontSize: "18px",
                        position: "absolute", 
                        top: 54, 
                        left: 10, 
                        zIndex: "10000", 
                        outline: "none" 
                    }}
                    onClick={() => setClickedMap(false)}
                >Back</button>
                <Map />
            </div>
            :
            <div className={`${classes.allContent} ${props.flag}R`}>
                <footer className={classes.footer}>
                    
                    <div className={classes.content}>
                        <span className={`${props.flag}M ${classes.title}`}>
                            {contacts && contacts.header[props.flag]}
                        </span>
                        <div className={classes.infoContainer}>
                        <span className={`${classes.textWithIcon}`} onClick={() => { setClickedMap(true) }}>

                            <span style={{ marginRight: "15px" }}>
                                <LocationOnIcon />
                            </span>
                            {/* <Link  style={{ textDecoration: "none" }}> */}
                                <span className={classes.simple}>
                                    {contacts && contacts.address[props.flag]}
                                </span>
                            {/* </Link> */}
                        </span>
                        <span className={`${classes.textWithIcon}`}>
                            <span style={{ marginRight: "15px" }}>
                                <PhoneIcon />
                            </span>
                            <a style={{color:"inherit", textDecoration:"none"}} href={`tel:${contacts &&contacts.phone}`}>
                                <span className={classes.simple} >
                                    {contacts && contacts.phone}
                                </span>
                            </a>
                        </span>
                        <span className={`${classes.textWithIcon}`}>

                            <span style={{ marginRight: "15px" }}>
                                <MailIcon />
                            </span>
                            <a style={{color:"inherit", textDecoration:"none"}} href={`mailto:${contacts && contacts.mail}`}>
                                <span className={classes.simple}>
                                    {contacts && contacts.mail}
                                </span>
                                </a>
                        </span>

                        <span className={`${classes.textWithIcon}`}>
                            <span style={{ marginRight: "15px" }}>
                                <FacebookIcon />
                            </span>
                            <a style={{color:"inherit", textDecoration: "none" }} target="_blank" rel="noopener noreferrer"  href={`http://www.${contacts && contacts.fblink}`}>
                                <span className={classes.simple}>
                                {contacts && contacts.fblink}
                                </span>
                            </a>
                        </span>
                        </div>


                        
                    </div>
                    
                    <div className={`${classes.content}`}>
                        <span className={`${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600} ${classes.title}`}>
                            {Names && Names.footer.Company && Names.footer.Company[props.flag]}
                        </span>
                        <div className={classes.infoContainer}>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/aboutus">
                               {Names && Names.footer.Aboutus && Names.footer.Aboutus[props.flag]}
                            </Link>
                        </span>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/News">
                                {Names && Names.footer.News && Names.footer.News[props.flag]}
                            </Link>
                        </span>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/Jobs">
                                {Names && Names.footer.Job && Names.footer.Job[props.flag]}
                            </Link>
                        </span>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/contacts">
                                {Names && Names.footer.Contacts && Names.footer.Contacts[props.flag]}
                            </Link>
                        </span>
                        </div>


                    </div>
                    <div className={`${classes.content}`}>
                        <span className={`${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600} ${classes.title}`}>
                            {Names && Names.footer.Services && Names.footer.Services[props.flag]}
                        </span>
                        <div className={classes.infoContainer}>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/services">
                                {Names && Names.footer.Commercialequipment && Names.footer.Commercialequipment[props.flag]}
                            </Link>
                        </span>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/services">
                                {Names && Names.footer.Deviceequipment && Names.footer.Deviceequipment[props.flag]}
                            </Link>
                        </span>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/services">
                                {Names && Names.footer.Software && Names.footer.Software[props.flag]}
                            </Link>
                        </span>
                        <span className={`${classes.text}`}>
                            <Link className={classes.text} style={{ textDecoration: "none" }} to="/services">
                                {Names && Names.footer.Supermarketfurniture && Names.footer.Supermarketfurniture[props.flag]}
                            </Link>
                        </span>
                        </div>

                    </div>
                    <div className={`${classes.mapContainer} ${classes.servicesContent}`}>
                        <span className={`${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600} ${classes.title}`}>
                            {Names && Names.footer.Map && Names.footer.Map[props.flag]}
                        </span>
                        <div onClick={() => { setClickedMap(true) }} className={classes.map}>
                            <MapWitoutControls />
                        </div>
                    </div>
                </footer>
                <div className={classes.logo}>
                    {/* <img src={LOGO_Unitech_White} alt="logo" /> */}
                </div>
                <div className={classes.mobileContent} >
                    <a className={classes.callGreenIcon} href={`tel:${contacts && contacts.phone}`}>
                    <span  >
                        <CallGreenIcon />
                    </span>
                    </a>
                    <span className={`${classes.text} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
                        <Link className={classes.text} style={{ textDecoration: "none" }} to="/jobs">
                            {Names && Names.Job && Names.Job[props.flag]}
                        </Link>
                    </span>
                    <span className={`${classes.text} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
                        <Link className={classes.text} style={{ textDecoration: "none" }} to="/news">
                            {Names && Names.footer.News && Names.footer.News[props.flag]}
                        </Link>
                    </span>

                    <span className={`${classes.text}`}>
                        <Link className={classes.text} style={{ textDecoration: "none" }} to="/services">
                        {Names && Names.footer.Services && Names.footer.Services[props.flag]}
                        </Link>
                    </span>

                    <span className={`${classes.text}`}>
                        <Link className={classes.text} style={{ textDecoration: "none" }} to="/contacts">
                            {Names && Names.footer.ContactsLong && Names.footer.ContactsLong[props.flag]}
                        </Link>
                    </span>
                </div>
                <div className="dividerhorizonal"></div>
                <div className={classes.copy} >
                    <span className={classes.copyright}>
                        &copy; {`${new Date().getFullYear()} Copyright. Unitech LLC `}
                    </span>
                </div>
            </div>}
    </div>
    </>
}
