import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {Collapse, Fade} from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import "./filtersforMobile.css"
import { Names } from "../../staticTexts"
import { useSelector } from 'react-redux';
import ExpandMore from './icons/arrowright icon/PathComponent';
// import { maketree } from "../../helpers/Functions"
import langs from "../../stylesForLangs/langs.module.css"
import BackIcon from "./icons/BackIcon/GroupComponent"
import ArrowIcon from "./icons/arrowRightForFilter/PathComponent"
import BrandLists from "./BrandsList"
import BadgesList from "./BadgesList"
import {getLangStyle, getParents} from "../../helpers/Functions"

const useStyles = makeStyles({
  list: {
    width: 304,
    height: "calc(100% - 85px)",
    overflow: "auto"

  },
  root: {
    marginTop: 0,
    // height:"48px"
  },
  fullList: {
    width: 'auto',

  },
  categoriesContainer: {
    padding: "0px",
  },
  selectFlag: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 15px",
    marginTop: "6px",
  },
  contacts: {
    marginTop: "19px",
    fontSize: "14px",
  },
  KeepInTouch: {
    marginLeft: "13px",
  },
  keepContainer: {
    marginTop: "21px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 16px"
  },
  KeepButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FF6666",
    width: 52,
    height: 52,
    border: "none",
    outline: "none"
  },
  flagShadow: {
    boxShadow: "0px 2px 10px 0px rgba(186,186,186,0.57)",
    borderRadius: "50%"
  }
});






export default function SwipeableTemporaryDrawer(props) {
  const {flag} = props
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [index, setIndex] = useState([]);
  const categoriesFromRedux = useSelector(state => state.categories)
  const [brandsShow, setBrandsShow] = useState(false)
  const [badgesShow, setBadgesShow] = useState(false)
  const [productsShow, setProductsShow] = useState(false)
  const [selectedBrandCopy, setSelectedBrandCopy] = useState([])
  const [selectedBadgeCopy, setSelectedBadgeCopy] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  
  const maketree = useCallback(
    (arr, tree = [], parentId = "null") => {
      arr.forEach((item) => {
        if (item.parentId === parentId) {
          tree.push({ ...item, children: [...maketree(arr, tree, item.id)].sort((a, b)=>(
            a.index - b.index
        )) });
          return tree;
        }
      });
      return tree.filter((item) => item.parentId === parentId).sort((a, b)=>(
            a.index - b.index
        ));
    },
    [flag]
  );
  // const [historyIds, setHistoryIds] = useState([])


  useEffect(()=>{
    setSelectedBrandCopy(props.selectedBrand)
  }, [props.open, props.selectedBrand])

  useEffect(()=>{
    setSelectedBadgeCopy(props.selectedBadge)
  }, [props.open, props.selectedBadge])


  useEffect(() => {
      
    if (categoriesFromRedux) {
      setCategories(maketree(categoriesFromRedux))
      // console.log(`(maketree(categoriesFromRedux, props.flag)`, (maketree(categoriesFromRedux)))
      // !historyIds.includes(props.id) && setHistoryIds([...historyIds, props.id])
    }
    
  }, [props.id, props.flag, categoriesFromRedux])


  function getAllChildsIds(category, ids = []) {
    if (category.children && category.children.length) {
      ids.push(...category.children.map(item=>item.id))
      // for (let key of keys) {
      //   if (typeof (category[key]) === "object" && (category[key]) != null) {
      //     ids.push(key)
      //     getAllChildsIds(category[key], ids)
      //   }
      // }
    }
    return ids
  }

  
  function makeElements(nestedListClone = categories, padding = 16) {
    return <>
      {nestedListClone && nestedListClone.map((category) => {
        return <>
          <ListItem button 
          onClick={(e) => { getClickType(e, category )}} 
            style={index.includes(category.id) ? {backgroundColor: index.indexOf(category.id) > 0 ? "#FFF2E4" : "#E5F5F2", padding: `0 14px 0 ${padding}px`, height:"43px"} :  { padding: `0 14px 0 ${padding}px` , height:"43px" }}
          >
            <ListItemText  style={ padding > 16 ? {color:"rgb(99, 97, 97)", fontSize:"16px"} : {color:"#2A2A2A", fontSize:"16px"}} primary={<span>{category.name[flag]}</span>} />
            {!!category.children && !!category.children.length ? index.includes(category.id) ? <span style={{transform:"rotate(270deg)"}} > <ExpandMore  /> </span> : <span style={{transform:"rotate(90deg)"}} > <ExpandMore /> </span> : null}
          </ListItem>
          {!!category.children && !!category.children.length && <Collapse in={index.includes(category.id)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                makeElements(category.children, padding + 12)
              }
            </List>
          </Collapse>}
        </>

      })}
    </>
  }

  
  // function makeElements(nestedListClone = categories, padding = 16) {
  //   return <>
  //     {nestedListClone && nestedListClone.map((category) => {
  //       return <>
  //         <ListItem button onClick={(e) => { getClickType(e, category )}} 
  //           style={props.id===category.id ? {backgroundColor:"rgba(255, 86, 86, 0.09)", padding: `0 14px 0 ${padding}px`, height:"43px"} :  { padding: `0 14px 0 ${padding}px` , height:"43px" }}
  //         >
  //           <ListItemText  style={ padding > 16 ? {color:"rgb(99, 97, 97)", fontSize:"16px"} : {color:"#2A2A2A", fontSize:"16px"}} primary={<span className={props.flag==="am" ? padding>16 ? langs.armenianHeaders : langs.armenianHeadersMedium : padding>16 ? langs.foreignHeaders :  langs.foreignHeadersMedium}>{category.name[flag]}</span>} />
  //           {nestedListClone[key] && Object.keys(nestedListClone[key]).length > 2 ? index.includes(key) ? <span style={{transform:"rotate(270deg)"}} > <ExpandMore  /> </span> : <span style={{transform:"rotate(90deg)"}} > <ExpandMore /> </span> : null}
  //         </ListItem>
  //         {typeof (nestedListClone[key]) === "object" && <Collapse in={index.includes(key)} timeout="auto" unmountOnExit>
  //           <List component="div" disablePadding>
  //             {
  //               (Object.keys(nestedListClone[key]).length > 3) && makeElements(nestedListClone[key], Object.keys(nestedListClone[key]), padding + 12)
  //             }
  //           </List>
  //         </Collapse>}
  //       </>

  //     })}
  //   </>
  // }

  const handleClick = (category) => {
    
    if(props.id !== category.id){
      props.setId(category.id)
    }else{
      props.setId(null)
    }
    props.setCurrentCategory(category)
    props.setClickedChildCategories(getAllChildsIds(category))

  };

  function getClickType(e, category){
    e.stopPropagation(); 
    let i = index.indexOf(category.id);
    let clone = JSON.parse(JSON.stringify(index))
    if (i === -1) {
      console.log(`object`, getParents(categoriesFromRedux, category))
        setIndex(getParents(categoriesFromRedux, category).map(item=>item.id));
    } else {
      clone.splice(i, 1)
      setIndex(clone)
    }
    if(selectedCategory && selectedCategory.id ===category.id){
      setSelectedCategory([])
    }else{
      setSelectedCategory(category)
    }
    // if(category.children && category.children.length) {
    //   handleClick(category) 
    // }else{
    //   props.setClickedChildCategories([])
    //   props.setCurrentCategory(category)
    //   props.setId(category.id) 
    // }
  }

  function handleCancel(){
    if(brandsShow){
      if(props.selectedBrand && props.selectedBrand.length){
        props.setSelectedBrand([])
      }
      if(selectedBrandCopy && selectedBrandCopy.length){
        setSelectedBrandCopy([])
      }
      setBrandsShow(false)
    }else if(badgesShow){
      if(props.selectedBadge && props.selectedBadge.length){
        props.setSelectedBadge([])
      }
      if(selectedBadgeCopy && props.selectedBadge.length){
        setSelectedBadgeCopy([])
      }
      setBadgesShow(false)
    }else if(productsShow){
      if(props.id){
        props.setId(null)
        props.setCurrentCategory(null)
      }
      setProductsShow(false)
    }else{
    if(props.id){
      props.setId(null)
    }
    if(props.selectedBrand){
      props.setSelectedBrand([])
    }
    if(selectedBrandCopy){
      setSelectedBrandCopy([])
    }
    if(props.selectedBadge){
      props.setSelectedBadge([])
    }
    if(selectedBadgeCopy){
      setSelectedBadgeCopy([])
    }
    props.setCurrentCategory(null)
      props.setOpen(false)
    }
  }

  function handleSave(){
    if(productsShow){
      if(selectedCategory.children && selectedCategory.children.length) {
        handleClick(selectedCategory) 
      }else{
        props.setClickedChildCategories([])
        props.setCurrentCategory(selectedCategory)
        props.setId(selectedCategory.id) 
      }
      setProductsShow(false)
    }else if(brandsShow){
      setBrandsShow(false)
      props.setSelectedBrand(selectedBrandCopy)
    }else if(badgesShow){
      setBadgesShow(false)
      props.setSelectedBadge(selectedBadgeCopy)
    }else{
     props.setOpen(false)
    }

    // ? Names.saveBrands[props.flag] : productsShow ? Names.saveProducts[props.flag] :  Names.saveall[props.flag]}
  }



  function handleClose() {
   
    // setIndex([])
    props.setOpen(false)
  }

  function handleBackClick(){
    // if(historyIds.length > 1){
    //   let clone = JSON.parse(JSON.stringify(historyIds))
    //   props.setId(clone[clone.length-2])
    //   clone.pop()
    //   setHistoryIds(clone)
    // }else{
      if(productsShow) {
        setProductsShow(false)
      }
      else if(brandsShow) {
        setBrandsShow(false)
      }
      else if(badgesShow) {
        setBadgesShow(false)
      }
      else{ 
        props.setOpen(false);
        // props.setId(null) 
      } 
    // }
  }

  const list = (anchor) => (
    <div
      style={{ position: "relative", display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}
    >
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
      >
        <div className={"filtersHeader"}>
          <span className={"filterBackIcon"} onClick={handleBackClick} >
            <BackIcon />
          </span>
          <span className={` ${getLangStyle(props.flag, "h")} filterHeaderName`}>
             {productsShow ? Names.products[props.flag] : brandsShow ? Names.brand[props.flag] : badgesShow ? Names.badge[props.flag] : Names.filterMenu[props.flag]}
          </span>
        </div>
        {/* {brandsShow ? */}
          <Fade in={brandsShow} unmountOnExit={true} >
            <div style={{minHeight:"calc(100vh - 188px)"}}>
              <BrandLists mobileFilter={true} selectedBrand={selectedBrandCopy} setSelectedBrand={setSelectedBrandCopy} flag={props.flag} />
            </div>
          </Fade>
          <Fade in={badgesShow} unmountOnExit={true} >
            <div style={{minHeight:"calc(100vh - 188px)"}}>
              <BadgesList mobileFilter={true} selectedBadge={selectedBadgeCopy} setSelectedBadge={setSelectedBadgeCopy} flag={props.flag} />
            </div>
          </Fade>
          <Fade in={productsShow} unmountOnExit={true}>
            <div style={{minHeight:"calc(100vh - 188px)"}}>
            {/* <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.root}
            >
              <ListItem button onClick={(e) => { e.stopPropagation(); setOpenProducts(!openProducts) }} >
                <ListItemText primary={props.flag === "us" ? <span className={classes.textAM}>Categories</span> : props.flag === "ru" ? <span className={classes.textAM}>Категоры</span> : <span className={classes.textAM}>ԿԱՏԵԳՈՐԻԱՆԵՐ</span>} />
                {openProducts ? <ExpandLess /> : <ExpandMore />}
              </ListItem> */}
              
                <div className={classes.categoriesContainer}>
                  {categories && makeElements()}
                </div>
             
            {/* </List> */}
            </div>
          </Fade>
          {/* : */}
          <Fade in={!brandsShow && !productsShow} unmountOnExit={true} >
          <div style={{minHeight:"calc(100vh - 188px)"}}>
            
            <button onClick={() => { setProductsShow(true) }} className={` ${getLangStyle(props.flag, "h")} filterMenuButton`} >
              {Names.products[props.flag]}
              <ArrowIcon />
            </button>

            <button onClick={() => { setBrandsShow(true) }} className={` ${getLangStyle(props.flag, "h")} filterMenuButton`} >
              {Names.brand[props.flag]}
              <ArrowIcon />
            </button>

            <button onClick={() => { setBadgesShow(true) }} className={` ${getLangStyle(props.flag, "h")} filterMenuButton`} >
              {Names.badge[props.flag]}
              <ArrowIcon />
            </button>
          </div>
          </Fade>
          {/* } */}
      </div>

      <div className={"filterFooter"}>
        <Divider />
        <div className={classes.contacts} >
          <div className={classes.keepContainer}>
            <button className={"filterDownButton"} onClick={(e)=>{e.preventDefault(); handleCancel()}} >
              { brandsShow ? Names.cancelBrands[props.flag] : productsShow ? Names.cancelProducts[props.flag] : badgesShow ? Names.cancelBadges[props.flag] :  Names.cancelall[props.flag]}
            </button>
            <button className={"filterDownButtonFilled"} onClick={(e)=>{e.preventDefault(); handleSave()}} >
            { brandsShow ? Names.saveBrands[props.flag] : productsShow ? Names.saveProducts[props.flag] : badgesShow ? Names.saveBadges[props.flag] : Names.saveall[props.flag]}
            </button>
          </div>
        </div>
      </div>
    </div>

  );

  return (
    <div >
      <React.Fragment>
        <SwipeableDrawer
          // classes={classes.root}
          anchor="right"
          open={props.open}
          onClose={() => { handleClose() }}
          onOpen={() => { props.setOpen(true) }}
        >
          {/* <div style={{width:"100%", height:"100%"}} > */}
          {list()}
          {/* </div> */}

        </SwipeableDrawer>
      </React.Fragment>

    </div>
  );
}