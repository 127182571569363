/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounCancelComponent = ({ svgRef }) => (
  <svg width={26} height={20} viewBox="0 0 20.635 20.635" ref={svgRef}>
    <defs>
      <style>{".aewq{fill:#4a4a4a;}"}</style>
    </defs>
    <g transform="translate(-3 -3)">
      <path
        className="aewq"
        d="M3.252,23.384a.86.86,0,0,0,1.216,0l8.85-8.85,8.85,8.85a.86.86,0,1,0,1.216-1.216l-8.85-8.85,8.85-8.85a.86.86,0,1,0-1.216-1.216l-8.85,8.85-8.85-8.85A.86.86,0,1,0,3.252,4.468l8.85,8.85-8.85,8.85A.86.86,0,0,0,3.252,23.384Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounCancelComponent svgRef={ref} {...props} />
));
export default ForwardRef;
