import  {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
    section: {
        margin: "0",
        width: "100%",
        minHeight:"100vh",
        backgroundColor: "#f9f9f9",
        
        // boxShadow: "10px 20px 30px black",
        // position: "relative",
      },
      sectionFull:{
        position: "relative",
        // height: "100%"
    },
    section1:{
      display: "flex",
  },
  tableContainer: {
    width: "25%",
    padding: "20px"
},
    
    
}))
