import React, { useEffect, useState } from 'react';
import styles from "./cssforsvg.module.css"
export const Svgloader = props => {
    const [svg, setSvg] = useState(null);

    useEffect(() => {
        fetch(props.url)
            .then(res => {
                if(res.status!==200){
                    return ""
                }
                return res.text()
            })
            .then(res=>{
                setSvg(res)
            })
    }, [props.url]);

    return (
        <span 
            className={props.clicked ? styles.svg : styles.not}
            dangerouslySetInnerHTML={{ __html: svg }}
        />
    );
}