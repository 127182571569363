/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounMailComponent = ({ svgRef }) => (
  <svg width={18.6} height={15} viewBox="0 0 18.6 15" ref={svgRef}>
    <defs>
      <style>{".aw{fill:#4a4a4a;}"}</style>
    </defs>
    <g transform="translate(-5 -13.71)">
      <g transform="translate(5 13.71)">
        <path
          className="aw"
          d="M23.6,16.5a2.807,2.807,0,0,0-2.806-2.793H7.807A2.806,2.806,0,0,0,5,16.5v9.4A2.807,2.807,0,0,0,7.807,28.71H20.793A2.807,2.807,0,0,0,23.6,25.9V16.5ZM6.246,14.956a2.2,2.2,0,0,1,1.56-.646H20.793a2.2,2.2,0,0,1,2.2,2.058L15.526,21.74a2.1,2.1,0,0,1-2.453,0L5.608,16.368A2.2,2.2,0,0,1,6.246,14.956ZM23,25.9a2.207,2.207,0,0,1-2.207,2.207H7.807A2.207,2.207,0,0,1,5.6,25.9V17.1l7.123,5.125a2.7,2.7,0,0,0,3.154,0L23,17.1Z"
          transform="translate(-5 -13.71)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounMailComponent svgRef={ref} {...props} />
));
export default ForwardRef;
