import  {makeStyles} from '@material-ui/core/styles';



export const styles = makeStyles(theme => ({

    Line:{
        width:"100%",
        height:"fit-content",
        // background:"radial-gradient(#FFF, #F5FBFF)",
        margin:"10px 0",
        cursor:"pointer",
        display:"flex",
        justifyContent:"flex-start",
        transitionDuration:"0.5s",
        border:"1px solid rgba(128,128,128, 0.1)",
        boxSizing:"border-box",
        "&:hover":{
            
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)"
        }
    },
    imgcontainer:{
        height:"116px",
        width:"116px",
        display: "flex",
        marginLeft:"17px",
        justifyContent: "center",
        alignItems:"center",
    },
    cardImg:{
        width:"100%",
    },
    productName:{ 
        width:"100%",
        fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        color: "#707070",
        fontWeight: "bold",
        fontSize:"18px"
        // textAlign:"center",
    },
    productShortText:{
        width:"100%",
        fontSize:"15px",
        fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        // color: "#FB1F1F",
        // fontWeight: "bold",
        // textAlign:"center",
    }
}))