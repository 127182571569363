/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounTimeComponent = ({ svgRef }) => (
  <svg width="23" height="23" viewBox="0 0 24.053 24.053" ref={svgRef}>
    <defs>
      <style>{".ddsa{fill:#9F1A1E;stroke:#9F1A1E;stroke-width:0.3px;}"}</style>
    </defs>
    <g transform="translate(0.15 0.15)">
      <path
        className="ddsa"
        d="M22.276,10.4A11.876,11.876,0,1,0,34.153,22.276,11.876,11.876,0,0,0,22.276,10.4Zm0,22.553A10.677,10.677,0,1,1,32.953,22.276,10.677,10.677,0,0,1,22.276,32.953Z"
        transform="translate(-10.4 -10.4)"
      />
      <path
        className="ddsa"
        d="M50.079,37.417l-3.179-2.4V28.9a.6.6,0,1,0-1.2,0v6.418a.63.63,0,0,0,.24.48l3.419,2.579a.48.48,0,0,0,.36.12.63.63,0,0,0,.48-.24A.66.66,0,0,0,50.079,37.417Z"
        transform="translate(-35.113 -22.932)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounTimeComponent svgRef={ref} {...props} />
));
export default ForwardRef;
