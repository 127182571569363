import React, { useEffect } from 'react';
import style from "./Error404.module.css"
import {Link} from "react-router-dom"
import {Names} from "../staticTexts"
import {useSelector} from "react-redux"
import "./colorlib-error-404-4/css/style.css"
import langs from "../stylesForLangs/langs.module.css"


export default function NestedList(props) {
  useEffect(() => {
    window.scrollTo(0,0)
    document.body.style.overflow = "hidden"

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])
  const flag = useSelector(state=>state.flag)

  return <div className={style.cont}>
    <img src="/Error404.png" alt="404" className={style.img} />
    <span className={style.E404} >
      404
    </span>
    <span className={style.errtext} >
      ERROR: Page not Found
    </span>
    <span className={style.contactback} >
    Please contact us if you think this is a server error. Thank you.
    </span>
	</div>
}
