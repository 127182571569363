/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounPlaceComponent = ({ svgRef }) => (
  <svg width={22} height={18} viewBox="0 0 24.256 30.544" ref={svgRef}>
    <defs>
      <style>{".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}"}</style>
    </defs>
    <g transform="translate(0.25 0.25)">
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M26.878,5.9a11.572,11.572,0,0,0-8.415,3.63,12.8,12.8,0,0,0,0,17.512l8.416,8.79,8.416-8.79a12.8,12.8,0,0,0,0-17.511A11.572,11.572,0,0,0,26.878,5.9Zm7.941,20.667-7.941,8.281-7.941-8.281a12.083,12.083,0,0,1,0-16.561,10.928,10.928,0,0,1,15.882,0A12.083,12.083,0,0,1,34.819,26.567Z"
          transform="translate(-15 -5.9)"
        />
        <path
          className="a"
          d="M37.382,22.5a6.482,6.482,0,1,0,6.482,6.482A6.482,6.482,0,0,0,37.382,22.5Zm0,12.285a5.8,5.8,0,1,1,5.8-5.8A5.8,5.8,0,0,1,37.382,34.785Z"
          transform="translate(-25.504 -16.867)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounPlaceComponent svgRef={ref} {...props} />
));
export default ForwardRef;
