/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const LogoComponent = ({ svgRef }) => (
  <svg width="39" height="28" viewBox="0 0 9.117 17.733" ref={svgRef}>
    <defs>
      <style>{".eee{fill:none;stroke:#9F1A1E;stroke-width:0.8px;}"}</style>
    </defs>
    <path
      className="eee"
      d="M13.231,2.861H14.8V.121A20.314,20.314,0,0,0,12.512,0C10.244,0,8.69,1.427,8.69,4.049V6.462h-2.5V9.526h2.5v7.707h3.069V9.526h2.4l.381-3.063H11.758V4.353c0-.885.239-1.491,1.473-1.491Z"
      transform="translate(-5.937 0.25)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <LogoComponent svgRef={ref} {...props} />
));
export default ForwardRef;
