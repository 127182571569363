import React,{useState, useEffect} from 'react';
import { styles } from "./styleCardForProductLine"
import Dotdotdot from 'react-dotdotdot'
import style from "./Card_for_product_Line.module.css"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {useHistory} from "react-router-dom"
import { useSelector } from 'react-redux';

export default function OutlinedCard(props) {
  const classes = styles();
  const history = useHistory()
  const [brandName, setbrandName] = useState("")
  const brandsFromRedux = useSelector(state=>state.brands)
  const imageSizeOriginal = "480x480/"
  useEffect(() => {
    if(props.product.brand && brandsFromRedux){
      let length = brandsFromRedux.length;

      for(let i =0; i<length; i++){
        if(brandsFromRedux[i].id===props.product.brand){
          setbrandName(brandsFromRedux[i].name);
          break;
        }
      }
      
      // brandsFromRedux.forEach(item=>{
      //   if(item.id===props.product.brand){
      //     setbrandName(item.name)
      //   }
      // })
    }
   
  }, [brandsFromRedux, props])


  const { product, flag } = props;
  return (
          <div className={`${classes.Line} ${style.line}`} onClick={()=>{history.push(product.path)}}>

            <div className={classes.imgcontainer}>
              <img  className={classes.cardImg} alt="product" src={`${process.env.REACT_APP_IMAGES_URL}/${imageSizeOriginal}/${Array.isArray(product.Image) ? product.Image[0] : product.Image}`} />
            </div>
            <div style={{width:"100%", paddingLeft:"32px",height:"auto",display:"flex", justifyContent:"space-around", flexDirection:"column"}}>
              <span
                className={style.productName}
              >
                {product.name[flag]}
              </span>
              <span
                className={style.productShortText}
              >
                <Dotdotdot clamp={1} >
                {product.shortText[flag]}
                </Dotdotdot>
              </span>
              {!!brandName[props.flag] && <span className={style.brand}>
              {props.flag === "us" ? "BRAND :" : props.flag==="ru" ? "BRAND :" : "ԲՐԵՆԴ :"}
              &nbsp;&nbsp;
                {brandName && brandName.us}
              </span>}
            </div>
            <div className={style.button}> 
              <NavigateNextIcon />
            </div>

          </div>
      
  )
}