import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import loadData from "../helpers/loadData";
// import style from "../Components/services/services.module.css";
import style from "./aboutus.module.css";
import { CircleLoader } from "react-spinners";
import langs from "../stylesForLangs/langs.module.css";
import { Names } from "../staticTexts";
import { MakeBreadcrumbs } from "../helpers/helperComponents/helperComponents";
import Customers from "./customers";
import Banner from "../Components/banner/banner"

export default function (props) {
  const [header1AM, setHeader1AM] = useState("");
  const [content1AM, setContent1AM] = useState("");
  const [header2AM, setHeader2AM] = useState("");
  const [content2AM, setContent2AM] = useState("");
  const [header3AM, setHeader3AM] = useState("");
  const [content3AM, setContent3AM] = useState("");
  const [header4AM, setHeader4AM] = useState("");
  const [content4AM, setContent4AM] = useState("");

  const [header1RU, setHeader1RU] = useState("");
  const [content1RU, setContent1RU] = useState("");
  const [header2RU, setHeader2RU] = useState("");
  const [content2RU, setContent2RU] = useState("");
  const [header3RU, setHeader3RU] = useState("");
  const [content3RU, setContent3RU] = useState("");
  const [header4RU, setHeader4RU] = useState("");
  const [content4RU, setContent4RU] = useState("");

  const [header1US, setHeader1US] = useState("");
  const [content1US, setContent1US] = useState("");
  const [header2US, setHeader2US] = useState("");
  const [content2US, setContent2US] = useState("");
  const [header3US, setHeader3US] = useState("");
  const [content3US, setContent3US] = useState("");
  const [header4US, setHeader4US] = useState("");
  const [content4US, setContent4US] = useState("");

  const [fileDefault, setFileDefault] = useState("");
  const [fileDefault2, setFileDefault2] = useState("");
  const [fileDefault3, setFileDefault3] = useState("");
  const [fileDefault4, setFileDefault4] = useState("");

  const [headerGlob, setHeaderGlob] = useState({});
  const [fileDefaultGlob, setFileDefaultGlob] = useState();
  const [isFetched, setisFetched] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData("staticpages", JSON.stringify({ id: "aboutus" })).then((res) => {
      try {
        setFileDefaultGlob(res[0].content.fileDefaultGlob);
        setFileDefault(res[0].content.fileDefault);
        setFileDefault2(res[0].content.fileDefault2);
        setFileDefault3(res[0].content.fileDefault3);
        setFileDefault4(res[0].content.fileDefault4);

        setHeaderGlob(res[0].content.headerGlob);

        setHeader1AM(res[0].content.am[0].header);
        setHeader1RU(res[0].content.ru[0].header);
        setHeader1US(res[0].content.us[0].header);

        setHeader2AM(res[0].content.am[1].header);
        setHeader2RU(res[0].content.ru[1].header);
        setHeader2US(res[0].content.us[1].header);

        setHeader3AM(res[0].content.am[2].header);
        setHeader3RU(res[0].content.ru[2].header);
        setHeader3US(res[0].content.us[2].header);

        setHeader4AM(res[0].content.am[3].header);
        setHeader4RU(res[0].content.ru[3].header);
        setHeader4US(res[0].content.us[3].header);

        setContent1AM(res[0].content.am[0].content);
        setContent1RU(res[0].content.ru[0].content);
        setContent1US(res[0].content.us[0].content);

        setContent2AM(res[0].content.am[1].content);
        setContent2RU(res[0].content.ru[1].content);
        setContent2US(res[0].content.us[1].content);

        setContent3AM(res[0].content.am[2].content);
        setContent3RU(res[0].content.ru[2].content);
        setContent3US(res[0].content.us[2].content);

        setContent4AM(res[0].content.am[3].content);
        setContent4RU(res[0].content.ru[3].content);
        setContent4US(res[0].content.us[3].content);

        setisFetched(true);
      } catch (error) {
        alert("Cannot get Data open Console");
      }
    });
  }, []);

  return (<>
      <Banner page={Names.footer.Aboutus[props.flag]} link={"/aboutus.png"} />
    <section className={style.fullsectionss}>
      <div className={style.bradcrumbs}>
        {MakeBreadcrumbs(
          [{ name: Names.footer.Aboutus[props.flag] }],
          props.flag
        )}
      </div>
      <div className={style.pageName}>{Names.footer.Aboutus[props.flag]}</div>
      {isFetched ? (
        <>
          {" "}
          <div className={style.lg}>
            <Grid container spacing={0}>
              <Grid item md={6} xs={12} >
                <div className={style.headerTextGlob}>
                  {headerGlob[props.flag]}
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <img
                  className={style.imgGlob}
                  src={`${process.env.REACT_APP_API_URL}/originals/${fileDefaultGlob}`}
                  alt="glob"
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <div className={style.pageName}>{Names.OurAdvantages[props.flag]}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item sm={3} xs={4}>
                    <img
                      className={style.img}
                      src={`${process.env.REACT_APP_API_URL}/originals/${fileDefault}`}
                      alt="file"
                    />
                  </Grid>
                  <Grid item sm={9} xs={8}>
                    <div className={style.header}>
                      {props.flag === "am"
                        ? header1AM
                        : props.flag === "ru"
                        ? header1RU
                        : header1US}
                    </div>
                    <div className={style.containerText}>
                      {props.flag === "am"
                        ? content1AM
                        : props.flag === "ru"
                        ? content1RU
                        : content1US}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item sm={3} xs={4}>
                    <img
                      className={style.img}
                      src={`${process.env.REACT_APP_API_URL}/originals/${fileDefault2}`}
                      alt="file"
                    />
                  </Grid>
                  <Grid item sm={9} xs={8}>
                    <div className={style.header}>
                      {props.flag === "am"
                        ? header2AM
                        : props.flag === "ru"
                        ? header2RU
                        : header2US}
                    </div>
                    <div className={style.containerText}>
                      {props.flag === "am"
                        ? content2AM
                        : props.flag === "ru"
                        ? content2RU
                        : content2US}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item sm={3} xs={4}>
                    <img
                      className={style.img}
                      src={`${process.env.REACT_APP_API_URL}/originals/${fileDefault3}`}
                      alt="file"
                    />
                  </Grid>
                  <Grid item sm={9} xs={8}>
                    <div className={style.header}>
                      {props.flag === "am"
                        ? header3AM
                        : props.flag === "ru"
                        ? header3RU
                        : header3US}
                    </div>
                    <div className={style.containerText}>
                      {props.flag === "am"
                        ? content3AM
                        : props.flag === "ru"
                        ? content3RU
                        : content3US}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item sm={3} xs={4}>
                    <img
                      className={style.img}
                      src={`${process.env.REACT_APP_API_URL}/originals/${fileDefault4}`}
                      alt="file"
                    />
                  </Grid>
                  <Grid item sm={9} xs={8}>
                    <div className={style.header}>
                      {props.flag === "am"
                        ? header4AM
                        : props.flag === "ru"
                        ? header4RU
                        : header4US}
                    </div>
                    <div className={style.containerText}>
                      {props.flag === "am"
                        ? content4AM
                        : props.flag === "ru"
                        ? content4RU
                        : content4US}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </div>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircleLoader color={"#9F1A1E"} />
        </div>
      )}
    </section>
    </>
  );
}
