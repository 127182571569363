export const Names = {

   products:{
       am:"Ապրանքներ",
       ru:"Товары",
       us:"Products"
   },
   product:{
        am:"Ապրանք",
        ru:"Товар",
        us:"Product"
    },
   Select_Product_to_show_Results:{
       us:"Select Product to show Results",
       am:"Ընտրեք Ապրանք՝ արդյունքները ցույց տալու համար",
       ru:"Выберите продукт, чтобы отобразить результаты"
   },
   For_product_information:{
       us:"For product information or technical support, please complete the form below and we will contact you as quickly as possible.",
       ru: "Для получения информации о продукте или технической поддержки, пожалуйста, заполните форму ниже, и мы свяжемся с вами как можно скорее.",
       am: "Ապրանքի մասին տեղեկատվության կամ տեխնիկական աջակցության համար խնդրում ենք լրացնել ստորև բերված դաշտերը, և մենք կկապվենք ձեզ հետ հնարավորինս արագ:"
   },
   Social:{
        am:"Սոցիալական",
        ru:"Социальные",
        us:"Social"
   },
    OldToNew:{
        am:"հինից նոր",
        ru:"от старого к новому",
        us:"from Old to New"

    },
    NewToOld:{
        am:"նորից հին",
        ru:"от нового к старому",
        us:"from New to Old"
    },

   DESC:{
       am:"ըստ գնի Բարձրից ցածր",
       us:"by price Descending",
       ru:"цена По убыванию",
   },

   ASC:{
        am:"ըստ գնի Ցածրից բարձր",
        us:"by price Ascending",
        ru:"цена По возрастанию",
    },
    price:{
        am:"Գին",
        us:"Price",
        ru:"Цена",
    },
    sort:{
        am:"Ֆիլտրել",
        us:"Sort",
        ru:"Сортировать",
    },

   OurAdvantages:{
       us: "Our advantages",
       ru:"Наши преимущества",
       am:"Մեր առավելությունները"
   },
   no_options:{
    us:"No Options",
    ru:"Нет вариантов",
    am:"Արդյւունքներ չեն գտնվել"
   },
   select_Category:{
       us:"Please select Category first",
       ru:"Сначала выберите категорию",
       am:"Խնդրում ենք նախ ընտրել Կատեգորիա",
   },
   Write_to_us:{
       us:"Write to us",
       ru:"Напишите нам",
       am:"Գրեք մեզ"
   },
   Find_us:{
    us:"Find us",
    ru:"Найди нас",
    am:"Գտեք մեզ"
   },
   Call_Us:{
       us:"Call Us",
       am:"Զանգահարեք մեզ",
       ru:"Позвоните нам"
   },
   Specifications:{
       us:"Specifications",
       am:"Տեխնիկական պայմաններ",
       ru:"Характеристики"
   },
   topproducts:{
    am:"Ապրանքներ",
    ru:"Товары",
    us:"Top Products"
},
   Shopbycategory:{
       us:"Categories",
       ru:"Категории",
       am:"Կատեգորիաներ"
   },
   filterBy:{
    us:"Filter by",
    ru:"Фильтровать по",
    am:"Ֆիլտրել ըստ"
   },
   viewall:{
    us:"View All",
    ru:"Посмотреть Все",
    am:"Դիտել Բոլորը",
   },
   categorySingle:{
        us:"Category",
        ru:"Категория",
        am:"Կատեգորիա"
    },
   category:{
       us:"Categories",
       ru:"Категория",
       am:"Կատեգորիաներ"
   },
   brands:{
    us:"Brands",
    ru:"бренды",
    am:"Բրենդներ"
   },
   brand:{
    us:"Brand",
    ru:"бренд",
    am:"բրենդի"
   },
   badge:{
    us:"Badge",
    ru:"Значок",
    am:"Բեյջ"
   },
   contactus:{
    am:"ԿԱՊ ՄԵԶ ՀԵՏ",
    ru:"СВЯЗАТЬСЯ С НАМИ",
    us:"CONTACT US"
   },
   mainPage:{
    am:"ԳԼԽԱՎՈՐ ԷՋ",
    ru:"ГЛАВНАЯ СТРАНИЦА",
    us:"MAIN PAGE"
   },
   customers:{
    us:"our customers",
    am:"մեր հաճախորդները",
    ru:"наши клиенты"
   },
   readMore:{
    us:"Read More",
    am:"Կարդալ Ավելին",
    ru:"Читать далее"
   },
   characteristic:{
    us:"characteristic",
    ru:"характеристика",
    am:"լրացուցիչ նկարագրություն"
   },
   cancelBrands:{
    us:"Reset Brands",
    ru:"Отменить бренды",
    am:"չեղարկել բրենդները"
   },

   cancelBadges:{
    us:"Reset Badges",
    ru:"Отменить Значки",
    am:"չեղարկել Բեյջերը"
   },

   cancelProducts:{
    us:"Reset Products",
    ru:"Отменить ПРОДУКТЫ",
    am:"չեղարկել ԱՊՐԱՆՔՆԵՐը"
   },
   cancelall:{
    us:"Reset All",
    ru:"Отменить Все",
    am:"չեղարկել Բոլորը"
   },
   saveBrands:{
    us:"save Brands",
    ru:"сохранить бренды",
    am:"պահպանել բրենդները"
   },

   saveProducts:{
    us:"save Products",
    ru:"сохранить ПРОДУКТЫ",
    am:"պահպանել ԱՊՐԱՆՔՆԵՐը"
   },
   saveBadges:{
    us:"save Badges",
    ru:"сохранить Значки",
    am:"պահպանել Բեյջերը"
   },
   saveall:{
    us:"Done",
    ru:"Готово",
    am:"Հաստատել"
   },
   Cancel:{
    us:"Cancel",
    ru:"Отменить",
    am:"Չեղարկել",
   },
   save:{
    am:"Պահպանել",
    us:"save",
    ru:"Сохранить"
   },
   ShowAll:{
    us:"See All",
    ru:"увидеть все",
    am:"ՏԵՍՆԵԼ ԲՈԼՈՐԸ"
   },
   blog:{
       us:"Blog",
       ru:"блог",
       am:"Բլոգ"
   },
   searchPlaceholder:{
        am:"Որոնել",
        ru:"Поиск",
        us:"Search"

   },
   KeepInTouch:{
    us:"Keep In Touch",
    am:"ԿԱՊ ՄԵԶ ՀԵՏ",
    ru:"СВЯЗАТЬСЯ С НАМИ"
   },
   filter:{
    us:"Filter",
    ru:"Фильтр",
    am:"Ֆիլտր"
   },
   filterMenu:{
    us:"Filter",
    ru:"Фильтр",
    am:"Ֆիլտրել"
   },
   Job:{
    us:"Career",
    ru:"Работа",
    am:"Աշխատանք"
   },
   all:{
       us:"All",
       ru:"Все",
       am:"Բոլորը"
   },
   LastSeenProducts:{
       us:"Last Seen Products",
       ru:"Последние просмотренные товары",
       am:"ՎԵՐՋԻՆ ԴԻՏՎԱԾՆԵՐԸ",
   },
   guarantee:{
       us:"Guarantee",
       ru:"Гарантия",
       am:"Երաշխիք"
   },
   year:{
       us:"Year",
       ru:"Год",
       am:"Տարի"
   },
   brand:{
       us:"Brand",
       ru:"Бренд",
       am:"Բրենդ"
   },
   share:{
       us:"Share",
       ru:"Share",
       am:"Կիսվել"
   },
   description:{
    us:"Description",
    ru:"Описание",
    am:"Բնութագիր"
   },
   date:{
       us:"Upload Date",
       ru:"Дата",
       am:"Ամսաթիվ",
   },
   download:{
       us:"Downloads",
       ru:"Загрузки",
       am:"Ներբեռնում"
   },
   footer:{
        Company:{
            us:"Company",
            ru:"Компания",
            am:"Ընկերություն",
        },
        Aboutus:{
            us:"About us1",
            ru:"О нас",
            am:"Մեր մասին"
        },
        News:{
            us:"News",
            ru:"Новости",
            am:"Նորություններ",
        },
        Map:{
            us:"Map",
            ru:"Карта",
            am:"Քարտեզ"
        },
        Job:{
            us:"Job",
            ru:"Работа",
            am:"Աշխատանք"
        },
        Contacts:{
            us:"Contacts",
            ru:"Контакты",
            am:"Կապ",
        },
        ContactsLong:{
            us:"Contacts",
            ru:"Контакты",
            am:"Կոնտակտային տվյալներ"
        },
        Services:{
            us:"Services",
            ru:"Сервисы",
            am:"Ծառայություններ"
        },
        Commercialequipment:{
            us:"Commercial equipment",
            ru:"Торговое оборудование",
            am:"Առևտրային սարքավորումներ"
        },
        Deviceequipment:{
            us:"Device equipment",
            ru:"Компьютерное оборудование",
            am:"Համակարգչային սարքավորումներ"
        },
        Software:{
            us:"Software",
            ru:"Программное обеспечение",
            am:"Ծրագրային ապահովում"
        },
        Supermarketfurniture:{
            us:"Supermarket furniture",
            ru:"Мебель для супермаркетов",
            am:"Սուպերմարկետի կահավորում"
        }
   }


}