/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import styles from "../../showProductsGrid.module.css"

const GroupComponent = (props,{ svgRef }) => (
  <svg width="1em" height="1em" viewBox="0 0 20.31 21.273" ref={svgRef}>
    {/* <defs>
      <style>{".fill,.stroke{fill:#636262;}.stroke{fill-rule:evenodd;}"}</style>
    </defs> */}
    <path
      className={props.viewMode ? styles.fill : styles.notfill }
      d="M.607,0H4.968a.607.607,0,0,1,.607.607V4.968a.607.607,0,0,1-.607.607H.607A.607.607,0,0,1,0,4.968V.607A.607.607,0,0,1,.607,0ZM4.7.874H.874V4.7H4.7Z"
      transform="translate(0 0)"
    />
    <path
      className={props.viewMode ? styles.fill : styles.notfill }
      d="M.607,2.282H4.968a.607.607,0,0,1,.607.607V7.251a.607.607,0,0,1-.607.607H.607A.607.607,0,0,1,0,7.251V2.889a.607.607,0,0,1,.607-.607ZM4.7,3.156H.874V6.984H4.7Z"
      transform="translate(0 5.567)"
    />
    <path
      className={props.viewMode ? styles.fill : styles.notfill }
      d="M.607,4.564H4.968a.607.607,0,0,1,.607.607V9.533a.607.607,0,0,1-.607.607H.607A.607.607,0,0,1,0,9.533V5.171a.607.607,0,0,1,.607-.607ZM4.7,5.438H.874V9.266H4.7Z"
      transform="translate(0 11.134)"
    />
    <path
      className={props.viewMode ? styles.stroke : styles.strokenot }
      d="M13.268,1.12a.438.438,0,0,1-.436.437H2.654a.437.437,0,0,1,0-.874H12.832A.438.438,0,0,1,13.268,1.12Z"
      transform="translate(5.41 1.668)"
    />
    <path
      className={props.viewMode ? styles.stroke : styles.strokenot }
      d="M6.088.684a.437.437,0,1,1-.437.437A.437.437,0,0,1,6.088.684Z"
      transform="translate(13.786 1.668)"
    />
    <path
      className={props.viewMode ? styles.stroke : styles.strokenot }
      d="M13.268,3.4a.438.438,0,0,1-.436.437H2.654a.437.437,0,0,1,0-.874H12.832A.438.438,0,0,1,13.268,3.4Z"
      transform="translate(5.41 7.234)"
    />
    <path
      className={props.viewMode ? styles.stroke : styles.strokenot }
      d="M6.088,2.966a.437.437,0,1,1-.437.437A.437.437,0,0,1,6.088,2.966Z"
      transform="translate(13.786 7.234)"
    />
    <path
      className={props.viewMode ? styles.stroke : styles.strokenot }
      d="M13.268,5.684a.438.438,0,0,1-.436.437H2.654a.437.437,0,0,1,0-.874H12.832A.438.438,0,0,1,13.268,5.684Z"
      transform="translate(5.41 12.801)"
    />
    <path
      className={props.viewMode ? styles.stroke : styles.strokenot }
      d="M6.088,5.248a.437.437,0,1,1-.437.437A.437.437,0,0,1,6.088,5.248Z"
      transform="translate(13.786 12.801)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <GroupComponent svgRef={ref} {...props} />
));
export default ForwardRef;
