/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PathComponent = ({ svgRef }) => (
  <svg width={30} height={30} viewBox="0 0 17.028 30.816" ref={svgRef}>
    <defs>
      <style>{".mobileDrawermobileDrawer{fill:none;stroke:#32406E;stroke-width:0.8px;}"}</style>
    </defs>
    <path
      className="mobileDrawermobileDrawer"
      d="M37.694.006,33.8,0c-4.373,0-7.2,2.9-7.2,7.387v3.406H22.689a.612.612,0,0,0-.612.612V16.34a.612.612,0,0,0,.612.612H26.6V29.4a.612.612,0,0,0,.612.612h5.106a.612.612,0,0,0,.612-.612V16.952h4.576a.612.612,0,0,0,.612-.612l0-4.935a.613.613,0,0,0-.612-.612H32.933V7.906c0-1.388.331-2.092,2.138-2.092h2.622a.612.612,0,0,0,.612-.612V.618A.612.612,0,0,0,37.694.006Z"
      transform="translate(-21.677 0.4)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
