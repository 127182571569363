import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import style from "./categoriesList.module.css";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { Svgloader } from "./svgLoader";
import { useLocation } from "react-router-dom";
import { Names } from "../../staticTexts";
import langs from "../../stylesForLangs/langs.module.css";
import allStyles from "./categoriesList.module.css";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    // padding:"10px",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    cursor: "pointer",
    "&:hover": { color: "#9F1A1E" },
  },
  listTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "inherit",
    // textTransform:"uppercase",
    color: "#1F242A",
    fontSize: "24px",
  },
  categoriesContainer: {
    padding: "15px 0",
    // border: "0.1px solid lightgray"
  },
}));

export default function NestedList(props) {
  const location = useLocation();


  const classes = useStyles();
  const flag = useSelector((state) => state.flag);
  const [categories, setCategories] = useState([]);
  const [index, setIndex] = useState([]);
  const categoriesFromRedux = useSelector((state) => state.categories);
  // const [clickedIndex, setClickedIndex] = useState("")
  const getParents = useCallback((categories, item, arr = []) => {
    categories.forEach((single) => {
      if (single.id === item) {
        arr.push(single.id);
        if (single.parentId !== "null") {
          categories.forEach((parent) => {
            if (parent.id === single.parentId) {
              getParents(categories, parent.id, arr);
            }
          });
        }
      }
    });
    return arr;
  }, []);

  const maketree = useCallback(
    (arr, tree = [], parentId = "null") => {
      arr.forEach((item) => {
        if (item.parentId === parentId) {
          tree.push({ ...item, children: [...maketree(arr, tree, item.id)].sort((a, b)=>(
            a.index - b.index
        )) });
          return tree;
        }
      });
      return tree.filter((item) => item.parentId === parentId).sort((a, b)=>(
            a.index - b.index
        ));
    },
    [flag]
  );

  const handleClick = useCallback((key, name, category, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (key === props.id) {
      return;
    }
    props.setId(key);
    props.setCurrentCategory(category);
    props.setClickedChildCategories(getAllChildsIds(category));
  },[props.id]);

  function openCategory(key) {
    let i = index.indexOf(key);
    if (i === -1) {
      setIndex([...index, key]);
    } else {
      let clone = JSON.parse(JSON.stringify(index));
      clone.splice(i, 1);
      setIndex(clone);
    }
  }

  function getAllChildsIds(category, ids = []) {
    ids.push(category.id, ...category.children.map(item=>item.id));
    return ids;
  }
  let { setId, setCurrentCategory, setClickedChildCategories } = props;
  useEffect(() => {
    let state = location.state
    // if(!state && categoriesFromRedux && !location.brandId){
    //   let parents = categoriesFromRedux.filter(item=>item.parentId === "null")
    //   let parentsIndexes = parents.map(item=>item.index)
    //   let minIndex = Math.min(...parentsIndexes)
    //   let firstParent = parents.find(item=>item.index === minIndex)
    //   state = firstParent.id
    // }
    if (categoriesFromRedux && state) {
      window.innerWidth > 1024 &&
        setTimeout(() => {
          window.scrollTo({
            top: 417,
            left: 0,
            behavior: "smooth",
          });
        }, 0);
      setTimeout(() => {
        setIndex(getParents(categoriesFromRedux, state));
      }, 500);
      categoriesFromRedux.forEach((item) => {
        if (item.id === state) {
          setId(item.id); //destructure props and pass to arr of useeffect/////////////////////////////////////////////////////////////////////////////////////////////////
          setCurrentCategory(item);
          setClickedChildCategories(
            categoriesFromRedux
              .filter((items) => items.parentId === item.id)
              .map((category) => category.id)
          );
        }
      });
    }
  }, [
    categoriesFromRedux,
    location.state,
    getParents,
    setId,
    setCurrentCategory,
    setClickedChildCategories,
    flag,
  ]);

  function sortedList(obj) {
    let sorted = [];
    let keys = Object.keys(obj);
    let values = Object.values(obj);
    let length = keys.length;
    for (let i = 0; i < length; i++) {
      let indx = values[i].index ? values[i].index : 0;
      sorted[indx] = keys[i];
    }
    return sorted;
  }

  useEffect(() => {
    let path = window.location.search;
    if (path) {
      let id = path.split("=")[1];
      let category;
      categoriesFromRedux.forEach((item) => {
        if (item.id === id) {
          category = Object.assign({}, item);
        }
      });
      if (category) {
        setId(id);
        setIndex(getParents(categoriesFromRedux, category));
        setCurrentCategory(category.name[flag]);
        setClickedChildCategories(
          categoriesFromRedux
            .filter((items) => items.parentId === id)
            .map((category) => category.id)
        );
      }
    }
  }, [
    getParents,
    categoriesFromRedux,
    setCurrentCategory,
    setClickedChildCategories,
    setId,
    flag,
  ]);

  useEffect(() => {
    if (categoriesFromRedux) {
      setCategories(maketree(categoriesFromRedux));
    }
    // loadData("Categories", JSON.stringify({type: "Category"})).then((data)=>{
    //     setCategories(maketree(data))
    // })
  }, [categoriesFromRedux, flag, maketree]);


  //   function makeElements(nestedListClone = categories, nestedListKeys = sortedList(categories), padding = 0) {
  //     return <>
  //       {nestedListKeys && nestedListKeys.map((key) => {
  //         if (typeof (nestedListClone[key]) !== "object") { return null }
  //         return <>
  //           {nestedListClone[key].name && <ListItem
  //           onDoubleClick={()=>{openCategory(key)}}
  //           // button
  //           onClick={(e) => { handleClick(key, nestedListClone[key].name, nestedListClone[key], e) }} className={allStyles.nested}
  //             style={props.id === key ?
  //               { backgroundColor: "#F5F5F5", padding: `4px 8px 4px ${padding + 10}px` } :
  //               { padding: `4px 8px 4px ${padding + 10}px`}}

  //           >
  //             {nestedListClone[key] && nestedListClone[key].icon!=="defaultIcon.png" &&
  //               // <object height="20px" className="svgg"  width="20px" data={`${process.env.REACT_APP_API_URL}/${nestedListClone[key].icon}`} type="image/svg+xml"></object>
  //               <Svgloader clicked={props.id === key} url={`${process.env.REACT_APP_API_URL}/${nestedListClone[key].icon}`}/>

  //               }

  //           {nestedListClone[key] && <ListItemText primary={<span className={ props.flag === "am" ? padding === 0 ? allStyles.parentAM : props.id === key ? allStyles.clickedAM : allStyles.childAM : padding === 0 ? allStyles.parent : props.id === key ? allStyles.clicked : allStyles.child} >{nestedListClone[key].name}</span>} />}
  //           {nestedListClone[key] && Object.keys(nestedListClone[key]).length > 3 ? index.includes(key) ?
  //           <span onClick={(e)=>{e.preventDefault(); e.stopPropagation(); openCategory(key)}} >
  //             <ExpandLess htmlColor={
  //               // props.id === key ? "#9F1A1E" :
  //               "rgba(128,128,128,0.9)" } />
  //           </span > :
  //           <span onClick={(e)=>{e.preventDefault(); e.stopPropagation(); openCategory(key)}} >
  //             <ExpandMore htmlColor={
  //               // props.id === key ? "#9F1A1E" :
  //             "rgba(128,128,128,0.9)"} />
  //           </span> :
  //           null}
  //         </ListItem>
  //       }
  //           { typeof(nestedListClone[key])=== "object" && <Collapse in={index.includes(key)} timeout="auto" unmountOnExit>
  //           <List component="div" disablePadding>
  //             {
  //               (Object.keys(nestedListClone[key]).length > 3) && makeElements(nestedListClone[key], sortedList(nestedListClone[key]), padding + 10)
  //             }
  //           </List>
  //         </Collapse>}
  //     </>

  //   })
  // }
  //     </>
  //   }

  return (
    <div className={style.categoriesContainer} >
      <div className={style.listTitle}>
        <span
          className={
            props.flag === "am" ? langs.DejaVuSansWF : langs.foreignHeaders600
          }
          style={props.flag === "am" ? { fontSize: "22px" } : {}}
        >
          {Names.Shopbycategory[props.flag]}
        </span>
      </div>
      <List handleClick={handleClick} getParents={getParents} categories={categories} flag={flag} current={props.id} />

      {/* <div className={classes.categoriesContainer}>
      {categories && makeElements()}
    </div> */}
    </div>
  );
}

function List({ categories, flag, handleClick, current, getParents }) {
  return (
    <div className={style.listContainer}>
      {categories.map((category, i) => {
        return (
          <ListItem getParents={getParents} handleClick={handleClick} current={current} key={category.id} i={i} category={category} flag={flag} />
        );
      })}
    </div>
  );
}

function ListItem({ category, flag, i, isChild , handleClick, current, getParents}) {
  const [openParent, setOpenParent] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const categoriesFromRedux = useSelector(s=>s.categories)


  const handleClose = function () {
    setOpenParent(false);
  };
  const openPopUp = function (e) {
    setOpenParent(true);
  };
  const parents = getParents(categoriesFromRedux, current)
  
  function handleIcon(item, allCategories){
    if(item.category.icon.includes("defaultIcon")){
        let category = allCategories.find(x=>x.id === item.category.id)
        return <img height="32px" width="32px" src={`${process.env.REACT_APP_API_URL}/originals/${category.icon}`}/>  
    }else{
        return <img height="32px" width="32px" src={`${process.env.REACT_APP_API_URL}/originals/${item.category.icon}`}/>  
    }
}
  return (
    <>
      {category.children &&
        !!category.children.length &&
        (openParent || openChild) && (
          <ChildCategories
            getParents={getParents}
            i={i}
            children={category.children}
            flag={flag}
            open={openChild}
            setOpen={setOpenChild}
            handleClick={handleClick}
            current={current}
          />
        )}
      <Button
        onMouseEnter={openPopUp}
        onMouseLeave={handleClose}
        onClick={(e) => {
          handleClick(category.id, category.name[flag], category, e);
        }}
        fullWidth
        className={`${isChild ? style.listItemChild : style.listItem} ${style[`${flag}_${isChild ? parents.includes(category.id) ? "bold" : "light" :  "bold"}`]}  `}
        style={parents.includes(category.id) ? {backgroundColor: isChild ? "#FFF2E4" : "#E5F5F2" } : {}}
      >
        <span className={style.iconContainer} > {!isChild && <img className={style.icon} height="32px" width="32px" src={`${process.env.REACT_APP_API_URL}/originals/${category.icon}`}/> }  {category.name[flag]}</span>
        {category.children && !!category.children.length && (
          <span>
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.24219 10L9.48483 5.75736L5.24219 1.51472"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        )}
      </Button>
    </>
  );
}

function ChildCategories({ children, flag, open, setOpen, i, handleClick, current, getParents }) {
  const handleClose = function () {
    setOpen(false);
  };
  const openPopUp = function (e) {
    setOpen(true);
  };

  return (
    <div
      onMouseEnter={openPopUp}
      onMouseLeave={handleClose}
      className={style.childsContinerLarge}
      style={{ top: `${50 * i - 60}px` }}
    >
      <div className={style.childsContiner}>
        {children.map((category) => {
          return (
            <ListItem 
              getParents={getParents}
              handleClick={handleClick}
              isChild={true}
              key={category.id}
              category={category}
              flag={flag}
              current={current}
            />
          );
        })}
      </div>
    </div>
  );
}
