import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';
import { useSelector } from "react-redux"
import CardHorizonal from "./cardForNews copy 2"
import styless from "./style.module.css"
import "./style.css"
import LatestNews from '../bodyMain/latestNews'
import Axios from "axios"
import {MakeBreadcrumbs} from '../../helpers/helperComponents/helperComponents'
import {Names} from "../../staticTexts"
import langs from "../../stylesForLangs/langs.module.css"










export default function Contacts(props) {

  const [html, setHtml] = useState()
  const [header, setHeader] = useState("")
  const allnews = useSelector(state => state.news)

  useEffect(() => {
    window.scrollTo(0, 0)
    if(props.news.news[props.flag]){

      let nesinfo = JSON.parse(props.news.news[props.flag])
      let getHeader;
      nesinfo && nesinfo.blocks.forEach((info)=>{
        if(info.text.length>3 && info.text.length<15){
          getHeader = info.text
        }
      })
      setHeader(getHeader)
      
    let news = JSON.parse(props.news.news[props.flag])
    let obj = (news)
    let dataa = Object.values(obj.entityMap)
    let images = dataa.map(item => item.data)


    let options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'image') {
          const data = entity.getData();
          return {
            element: 'img',
            attributes: {
              src: data.src,
            },
            style: {
              border: "bored"
            },
          };
        }
      },
    };
    let html = stateToHTML(convertFromRaw(news), options);

    images.forEach(item => {
      // html = html.replace('<img style="border: bored">&nbsp;</img>', 
      // `<img src="${item.url}" 
      // width="${item.width>810 || !item.width? "810" : item.width }px" height="${item.height}px" >&nbsp;</img>`)
      html = html.replace('<figure><img style="border: bored">&nbsp;</img></figure>',
        `<img src="${item.url}"  style="max-width:${item.width > 810 || !item.width ? 100 : (item.width*100)/1366}%; border-radius:18px "
             ></div>`)
    })
    setHtml(html)

  }
  }, [props])
  
  useEffect(()=>{
    window.scrollTo(0,0)
    let newsIds = JSON.parse(localStorage.getItem("seenNewsIds"))
    if(!newsIds || !newsIds.length){
      let sendObj = JSON.parse(JSON.stringify(props.news))
      sendObj = Object.assign({},sendObj,{views: props.news.views ? props.news.views+1 : 1})
      if(sendObj._id){delete sendObj._id}
      Axios.post(`${process.env.REACT_APP_API_URL}/news`, sendObj, {withCredentials: true}).then((res)=>{
        if(res.status===200){
          localStorage.setItem("seenNewsIds", JSON.stringify([props.news.id]));
          // newsIds=[props.news.id]
        }else{
        }
      }).catch(err=>{
        console.log(err)
      })
      
    }else if(Array.isArray(newsIds)){
      if(newsIds.includes(props.news.id)){
      }else{
        let sendObj = JSON.parse(JSON.stringify(props.news))
        sendObj = Object.assign({},sendObj,{views: props.news.views ? props.news.views+1 : 1})
        if(sendObj._id){delete sendObj._id}
        Axios.post(`${process.env.REACT_APP_API_URL}/news`, sendObj, {withCredentials: true})
        .then((res)=>{
          if(res.status===200){
            newsIds.push(props.news.id)
            localStorage.setItem("seenNewsIds", JSON.stringify(newsIds));
          }else{
          }
        })
        .catch(err=>{
          console.log(err)
        })
        
      }
    }
},[props.news])

  return <section className={`${styless.scroll} ${styless.allContainerr}`}>
    <div style={{ margin: "27px 0px 27px 0px" }}>
      {MakeBreadcrumbs([{name:Names.footer.News[props.flag], path:"/news"},{name:header}],props.flag)}
    </div>
    <div className={` ${props.flag === "us" ? langs.NunitoRegular : langs.DejavuSans} ${styless.allcontent}`}>
      {html && <div dangerouslySetInnerHTML={{ __html: html }}>
      </div>}
      <div className={styless.blogContent} style={{marginBottom:"65px"}} >
        <span className={styless.blog}>
          {props.flag === "us" ? "NEWS" : props.flag === "ru" ? "НОВОСТИ" : "ԲԼՈԳ"}
        </span>
        {allnews.map((news, i) => {
          if (news.id === props.news.id || i>4) {
            return null
          }
          return <div className={styless.containerCardForNews}><CardHorizonal news={news} /></div>
        })}
        <Link to="/news">
        <Button
        style={{float:"right", color:"#FF4040"}}
        >
          {Names.ShowAll[props.flag].toLocaleUpperCase()}
        </Button>
        </Link>
      </div>
      <div className={styless.blogContentforsmall} >
        <LatestNews flag={props.flag} />
      </div>
    </div>
  </section>
}
