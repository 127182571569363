import React, { useState, useEffect } from "react"
import "./style.css"
import CardForNews from "../news/cardForNews copy"
import CardForNewsLine from "../news/cardForNews horizonal"
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core"
import SwipeableViews from 'react-swipeable-views';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {MakeBreadcrumbs} from '../../helpers/helperComponents/helperComponents'
import {Names} from "../../staticTexts"
import Banner from "../banner/banner"







export default function (props) {
  // const [allNews, setAllNews] = useState([])
  // const {allNews} = props;
  const allNews = useSelector(state => state.news)
  const [page, setPage] = useState(0)
  const [newsPages, setNewsPages] = useState([])
  const [mobile, setMobile] = useState(false)


  // useEffect(()=>{
  //     loadData("news", JSON.stringify({type: "news"})).then((data)=>{
  //         setAllNews(renderAllBrands(data))
  //     })
  // },[])

  // useEffect(()=>{
  //     loadData("news", JSON.stringify({type: "news"})).then((data)=>{
  //         setAllNews(data)
  //     })
  // },[])
  useEffect(() => {
    window.scrollTo(0, 0)
    if(window.innerWidth<1650){
      !mobile && setMobile(true)
     }
    window.addEventListener("resize",function resize(){
      if(window.innerWidth<1650){
        setMobile(true)
       }else{
        setMobile(false)
        }
    })
  }, [mobile])
  useEffect(() => {
    if (allNews) {
      setNewsPages(getSubArrs(allNews, 12))
    }
  }, [allNews, mobile])

  function getSubArrs(arr, n) {
    let count = 0;
    let subArrs = [];
    if (arr.length > n) {
      for (let i = 0; i < arr.length; i++) {
        if (!Array.isArray(subArrs[count])) {
          subArrs[count] = [];
        }
        if (subArrs[count].length < n) {

          subArrs[count].push(arr[i]);
        } else {
          count += 1;
          if (!Array.isArray(subArrs[count])) {
            subArrs[count] = [];
          }
          subArrs[count].push(arr[i]);

        }
      }
    } else {
      subArrs = [arr]
    }
    return subArrs;
  }






  return <>
<Banner link="https://png.pngtree.com/thumb_back/fh260/back_pic/00/02/44/5056179b42b174f.jpg" />

  <section className={"allCont"}>
    {/* <div style={{ width:"100%"}}>
    {!addNews && !editNews.bool ? 
        <Button style={{margin:"10px", fontSize:"12px", outline:"none"}} color="primary" variant="contained" onClick={()=>{setAddNews(true)}}><AddIcon /> &nbsp; {` Add News`} </Button>

    :
    <Button style={{fontSize:"12px", outline:"none"}} color="primary" variant="contained" onClick={()=>{setAddNews(false); setEditNews({bool:false, news:""})}}> <BackspaceIcon /> &nbsp; {` Go back`} </Button>

    } */}
    <div style={{ margin: "27px 0px 27px 0px" }}>
      {MakeBreadcrumbs([{name:Names.footer.News[props.flag], path:"/news"}],props.flag)}
    </div>

    {
      //  addNews ? <AddNews /> 
      //  : 
      //  editNews.bool ? <EditNews editNews={editNews} /> :

      newsPages && newsPages.length ?
        //  <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
        <div>
          {/* //  <Grid item xs={3}> */}
          {/* <CardForNews setEditNews={setEditNews} news={newsPages[0]} />
            <CardForNews setEditNews={setEditNews} news={newsPages[1]} /> */}
          <SwipeableViews enableMouseEvents index={page} slideStyle={{ overflow: 'hidden' }}>
            {newsPages.map((newsPages,i) => {

              return <Grid key={i} container spacing={0}> 
              {newsPages.length && newsPages.map((brand, i) => {
                  
                    return <Grid key={i} item lg={4} md={4} sm={6} xs={12}>
                    <div className="containerCardForNews"><CardForNews news={brand} /></div>
                  </Grid>
                  
                  
                })
              }
              </Grid>


            })}
          </SwipeableViews>


        </div>
        // {/* </div> */}
        : <div>no News</div>
    }
    {/* </div> */}
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }} >
      {newsPages.length > 1 && newsPages.length < 3 ? newsPages.map((_, i) => {
        return <button onClick={() => { setPage(i) }} className={page === i ? 'pagebutton12Clicked' : 'pagebutton12'}>
          {i + 1}
        </button>
      })
        :
        newsPages.length > 3 ?
          <>
           {<button onClick={() => { page - 1 >= 0 && setPage(page - 1) }} className={`${page === 0 ?  'pagebutton12Disabled rot180' : 'pagebutton12 rot180'}`}>
              <ChevronRightIcon />
            </button>}
            {page-1<=newsPages.length && page===newsPages.length-1 && <button onClick={() => { setPage(page - 2);  }} className={'pagebutton12'}>
              {page-1}
            </button>}
            {!!page && <button onClick={() => { setPage(page - 1);  }} className={'pagebutton12'}>
              {page}
            </button>}
            <button className={'pagebutton12Clicked'}>
              {page + 1}
            </button>
            {page + 1 < newsPages.length && <button onClick={() => { setPage(page + 1);  }} className={'pagebutton12'}>
              {page + 2}
            </button>}
            {!page && <button onClick={() => { setPage(page + 2);  }} className={'pagebutton12'}>
              {page + 3}
            </button>}
            {<button onClick={() => {page + 1 <= newsPages.length - 1 &&  setPage(page + 1) }} className={`${page === newsPages.length - 1 ?  'pagebutton12Disabled' : 'pagebutton12'}`}>
              <ChevronRightIcon fontSize="small" />
            </button>}
          </>
          :
          null
      }
    </div>

  </section>
  </>
}