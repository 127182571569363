import React from 'react'

import { withGoogleMap, GoogleMap, Marker, withScriptjs } from "react-google-maps"



function Map(){
    return <GoogleMap
    defaultZoom={15}
    defaultCenter={{lat:40.1714825, lng:44.52750112}}
    defaultOptions={{fullscreenControl: false}}
    styles = {[{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#144b53"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#08304b"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0c4152"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#0b434f"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#0b3d51"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#146474"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#021019"}]}]}
    >
    <Marker
    title={'Unitech'}
    position={{lat:40.1714825, lng:44.52750112}} />
    </GoogleMap>
}

const WrappedMap = withScriptjs(withGoogleMap(Map))
export default  function (props){
    return(
        <div style={{width:"100%", height:"100%"}} >
            <WrappedMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAR9JSqq8FGHx0ddoh10p7NE9eMd9H-pKE`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                defaultOptions={props.defaultOptions}
            />
            {/* maps */}
        </div>
    )
}