import React, { useState, useEffect } from 'react';
import loadData from "../../../helpers/loadData"

// import CategoriesEditDialog from "./mainPageCategoriesEditDialog"
// import ProductsEditDialog from "./mainPageProductsEditDialog"
// import LineLoad from "../loadingCircular"
// import {Paper} from "@material-ui/core"


// import Divider from '@material-ui/core/Divider';
import {useSelector} from "react-redux"
import "../ShowBrands.css"
import Tabss from "./tabs"

// const useStyles = makeStyles((theme) => ({
//     container: {
//       display: 'grid',
//       gridTemplateColumns: 'repeat(12, 1fr)',
//       gridGap: theme.spacing(1),
//     },
// }));







export default function ScrollableTabsButtonAuto(props) {
    const [tabs, setTabs] = useState([])
    const [products, setProducts] = useState([])
    const [isFetched, setisFetched] = useState(false)

    const productsFromRedux = useSelector(state=>state.products)

    useEffect(() => {
       
       if(productsFromRedux) { loadData("products6forMainpage", JSON.stringify({type:"container"}))
        .then((data)=>{
            let tabs=[];
            let products=[];
            data.forEach(item => {
                tabs.push(item.containerName)
                products.push(productsFromRedux.filter(product=>item.selected.includes(product.id)))
            });
            setTabs(tabs)
            setProducts(products)
            setisFetched(true)
        })}


    }, [productsFromRedux])


    // useEffect(() => {
    //     if (index !== 0) {
    //         setSelectedProducts(allProducts[index])
    //     } else {
    //         setSelectedProducts(products)

    //     }

    // }, [index])

    


    return (
        <>

            {/* <span style={{ float: "right" }}>
                <Button size="large" variant="contained" onClick={() => { setAddMainPage5CategoriesDialog(true) }}>
                    <EditIcon /> Change Categories
                    </Button>
            </span> */}
            {/* <CategoriesEditDialog categoriesIds={categoriesIds} categories={categories} open={addMainPage5CategoriesDialog} setOpen={setAddMainPage5CategoriesDialog} flag={props.flag} />
            <ProductsEditDialog index={index} open={addMainPage5ProductsDialog} setOpen={setAddMainPage5ProductsDialog} id={id} flag={props.flag} /> */}
           
                {products.length && tabs.length && <Tabss isFetched={isFetched} flag={props.flag} tabs={tabs} products={products} />}
            

        </>
    )
}
