import React, { useEffect, useState } from "react";
import style from "./searchbar.module.css";
import SearchIcon from "../icons/search/NounSearchComponent";
import { useTransition, animated } from "react-spring";
import { useHistory } from "react-router-dom";

export default function Searchbar() {
  const history = useHistory();
  const [value, setValue] = useState("");
  const [toggle, set] = useState(false);
  const [toggleIcon, setIcon] = useState(true);
  const transitions = useTransition(toggle, null, {
    from: { width: 0, opacity: 0 },
    enter: { width: 200, opacity: 1 },
    leave: { width: 0, opacity: 0 },
  });
//   useEffect(() => {
//     window.addEventListener("keyup", handleSearchClick);
//   }, []);

  function handleSearchClick(e) {
    

    if (value) {
      if (!e) {
        set(false);
        setTimeout(() => {
          setIcon(true);
        }, 400);
        history.push({
          pathname: "/SearchResult",
          state: { search: value },
        });
      }else if(e.keyCode === 13) {
        set(false);
        setTimeout(() => {
          setIcon(true);
        }, 400);
        history.push({
          pathname: "/SearchResult",
          state: { search: value },
        });
      }
    }else{
      if (!e || e.keyCode === 13 ) {
      set(false);
      setTimeout(() => {
        setIcon(true);
      }, 400);
    }
    setValue("")
  }
  }
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div className={`${style.container}`} style={props}>
              {!toggleIcon && (
                <input
                onKeyUp={(e)=>{handleSearchClick(e)}}
                  className={style.input}
                  autoFocus
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
              )}
              <div
                style={{
                  position: "relative",
                  zIndex: "100000",
                  cursor: "pointer",
                }}
                onClick={()=>{handleSearchClick()}}
              >
                <SearchIcon />
              </div>
            </animated.div>
          )
      )}

      {toggleIcon && (
        <span
          className="SubheaderSearch"
          onClick={() => {
            set(true);
            setIcon(false);
            //  setSearchClick(!searchClick);
          }}
        >
          <SearchIcon />
        </span>
      )}
    </>
  );
}
