import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { useSelector } from "react-redux"
import styless from "./bradlist.module.css"
import {useLocation} from "react-router-dom"

import {Names} from "../../staticTexts"
import langs from "../../stylesForLangs/langs.module.css"

import Collapse from '@material-ui/core/Collapse';
import ArrowDown from "./categories icons/arrowDown/NounArrowComponent"

 

// import { Button } from 'reactstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: "100%",
    // padding:"10px",
    marginTop: "25px",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 25px", 
    backgroundColor: "#fff",
    padding: "0px 12px",
    boxShadow: "0px 4px 4px 0px rgb(0 0 0 / 6%)",
    borderRadius: "10px",

  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  
  textAM: {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  categoriesContainer: {
    padding: "29px 34px 15px",
    height:"264px",
    overflow: "auto"
  },
  buttonFilter: {
    boxShadow: "none",
    backgroundColor: "#fff",
    border: "0.2px solid lightgray",
    transitionDuration: "0",
    transitionProperty: "box-shadow",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.2)",
      border: "none"
    }
  },
  checkbox:{
    borderRadius:"50%"
  }
}));

export default function NestedList(props) {
  const location = useLocation()
  const classes = useStyles();
  const brandsFromRedux = useSelector(state => state.brands)
  const [colapse, setColapse] = useState(false)
  const [collapseAll, setCollapseAll] = useState(true)
  // const [clickedIndex, setClickedIndex] = useState("")
  const {setSelectedBrand} = props
  useEffect(()=>{
      if(location.brandId){
        setSelectedBrand([location.brandId])
      }
  },[location, setSelectedBrand])

  function handleClick(brand) {
    let clone = [...props.selectedBrand];
    let index = clone.indexOf(brand.id)
    if (index === -1) {
      clone.push(brand.id)
    } else {
      clone.splice(index, 1)
    }
    props.setSelectedBrand(clone)
  }


  return (

    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {props.mobileFilter ?
      null
      :
      <div className={styless.listTitle}>
       
        <span className={props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}>
        {`${Names.brand[props.flag]}`}
        </span>
        {/* {props.flag === "us" ? <span className={classes.textAM}>BRAND</span> : props.flag === "ru" ? <span className={classes.textAM}>БРЕНД</span> : <span className={classes.textAM}>ԲՐԵՆԴ</span>} */}
        {collapseAll ? 
         <div className={"rightrot"} style={{height:"100%", display:"flex", alignItems:"center", cursor:"pointer"}} onClick={()=> { setCollapseAll(!collapseAll)} } >
         <ArrowDown />
         </div>
         : 
         <div style={{height:"100%", display:"flex", alignItems:"center", cursor:"pointer"}} onClick={()=> { setCollapseAll(!collapseAll)} } >
          <ArrowDown />
          </div>}
        
      </div>}

      {/* <div className={classes.categoriesContainer}>
        <Grid container spacing={2}>
          {brandsFromRedux && brandsFromRedux.map(brand => {
            return <Grid item lg={4} md={6} sm={12} xs={12} >
              <Button onClick={() => { handleClick(brand) }}
                className={classes.buttonFilter}
                style={
                  props.selectedBrand.includes(brand.name[props.flag]) ?
                    { width: "100%", height: "100%", border: "2px solid #FF8C61", boxSizing: "border-box", padding: "0px 20px", borderRadius: "0px", outline: 'none' } :
                    { width: "100%", height: "100%", padding: "0px 20px", boxSizing: "border-box", borderRadius: "0px", outline: 'none' }
                }
              >
                <img width="79x" src={`${process.env.REACT_APP_API_URL}/${brand.Image}`} alt="brand" />
              </Button>
            </Grid>


          })}
        </Grid>
      </div> */}
      <Collapse in={collapseAll}>
      <div className={styless.categoriesContainer} style={props.mobileFilter ? {border:"none", padding:"16px 16px"} : {padding:"0px 34px", margin:"25px 0px"}}  >
        {brandsFromRedux && 
         
         brandsFromRedux.map((brand) => {
          return <>
          
          <label className={styless.container} >
            <span className={styless.text} >{brand.name && brand.name.us}</span>
            <input type="checkbox" checked={props.selectedBrand.indexOf(brand.id)!==-1} onChange={()=>{handleClick(brand)}}/>
            <span className={styless.checkmark}></span>
          </label>
          </>
        })}
      </div>
      </Collapse>

    </List>
  );
}
