import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import cancelPng from "./icons/sentpng/cancel.png";
import CloseIcon from '@material-ui/icons/Close';
import lang from "../../stylesForLangs/langs.module.css"


export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));


  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent style={{position:"relative"}}>

          <DialogContentText>
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}} >
                
                <img width="147px" height="140px" src={cancelPng} alt="sent"/>
                <span className={`${props.flag==="am" ? lang.armenianHeaders600 : lang.foreignHeaders600}`} style={{color:"red", marginTop:"40px"}}>
                {props.flag === "us" ? "Your message has not been confirmed. Please try again later." : props.flag === "ru" ? "Ваше сообщение не было подтверждено. Попробуй еще раз." : "Ձեր հաղորդագրությունը չի հաստատվել։ Փորձել կրկին։"}
                </span>
            </div>
          </DialogContentText>
          <span style={{position:"absolute", top:"10px", right:"10px"}}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </span>
        </DialogContent>
      </Dialog>
    </div>
  );
}
