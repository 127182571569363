import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
  GrFacebookOption,
  GrLinkedinOption,
  GrGooglePlus,
  GrTwitter,
  FaTelegramPlane,
} from "react-icons/all";
import allstyles from "./ProductInfo.module.css";
import loadData from "../../helpers/loadData";
import Related from "./relatedProducts";
import Table from "./table";
import LastSeen from "./lastSeenProducts1";

import TableDownloads from "./tableDownloads";
import ImageGallery from "react-image-gallery";
import MyImageGallery from "./exampleImgGal";
import MyReactImageMagnify from "./MyReactImageMagnify";
import ReactImageMagnify from "react-image-magnify";
import ExampleImgGal from "./exampleImgGal";

import "./image-gallery.css";
// import "react-image-gallery/styles/css/image-gallery.css";

import FullScreenDialog from "./ImagesFullScreenDialog";
import { Helmet } from "react-helmet";
import langs from "../../stylesForLangs/langs.module.css";
import { Names } from "../../staticTexts";
import { MakeBreadcrumbs } from "../../helpers/helperComponents/helperComponents";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import StickyBox from "react-sticky-box/dist/esnext";
import MagnifyImg from "./magnify";

export default function NestedList(props) {
  const productsFromRedux = useSelector((state) => state.products);
  const brandsFromRedux = useSelector((state) => state.brands);
  const categoriesFromRedux = useSelector((s) => s.categories);
  const [product, setProduct] = useState();
  const [productInfo, setProductInfo] = useState();
  const [indexclick, setindexclick] = useState(0);
  const [productDownloads, setProductDownloads] = useState();
  // const [, setShowImageControllButtons] = useState({
  //   bool: false,
  //   index: null,
  // });
  const [fullScreen, setfullScreen] = useState({ bool: false, index: null });
  const galleryRef = useRef();
  const [brandName, setbrandName] = useState("");
  const [parents, setParents] = useState([]);
  const imageSizeOriginal = "480x480/";
  const imageSizeThumbnail = "150x150/";

  useEffect(() => {
    function addLastSeen() {
      let prods = JSON.parse(localStorage.getItem("lastSeen"));
      if (!prods) {
        localStorage.setItem("lastSeen", JSON.stringify([]));
        prods = [];
      }

      for (let i = 0; i < prods.length; i++) {
        if (prods[i] === props.product.id) {
          return;
        }
      }

      if (prods.length === 8) {
        prods.pop();
      }
      prods.unshift(props.product.id);
      localStorage.setItem("lastSeen", JSON.stringify(prods));
    }
    window.scrollTo(0, 0);
    addLastSeen();
    window.scrollTo(0, 0);
    if (props.product) {
      setProduct(props.product);
      loadData("itemsInfo", JSON.stringify({ id: props.product.id })).then(
        (data) => {
          setProductInfo(data[0].toolbar);
          setProductDownloads(data[0].downloads);
        }
      );
    } else {
      if (productsFromRedux) {
        productsFromRedux.forEach((item) => {
          if (item.path === props.productPath) {
            setProduct(item);
          }
        });
      }
    }
    return;
  }, [productsFromRedux, props]);

  useEffect(() => {
    if (props.product.brand && brandsFromRedux) {
      brandsFromRedux.forEach((item) => {
        if (item.id === props.product.brand) {
          setbrandName(item.name);
        }
      });
    }
  }, [brandsFromRedux, props.product.brand]);

  // function isDownloads() {
  //   if (productDownloads) {
  //     if (productDownloads[`downloadtable${props.flag.toLocaleUpperCase()}`]) {
  //       if (
  //         productDownloads[`downloadtable${props.flag.toLocaleUpperCase()}`]
  //           .length > 0
  //       ) {
  //         return productDownloads[
  //           `downloadtable${props.flag.toLocaleUpperCase()}`
  //         ].some((item) => item.name && item.url);
  //       }
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  function getParentCategories(categories, parentId, parents = []) {
    let category;
    if (parentId && parentId !== "null") {
      category = categories.find((item) => item.id === parentId);
      parents.push(category);
      return getParentCategories(categories, category.parentId, parents);
    } else {
      return parents;
    }
  }

  useEffect(() => {
    if (categoriesFromRedux) {
      setParents(
        getParentCategories(categoriesFromRedux, props.product.parentId)
      );
    }
  }, [categoriesFromRedux, props.product]);

  function myRenderItem(props, images) {
    return <MagnifyImg {...props} images={images} />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product && product.metaTags[props.flag].title}</title>
        <meta
          name="keywords"
          content={
            product && product.metaTags[props.flag].keyWords.map((item) => item)
          }
        ></meta>

        <meta
          name="description"
          content={product && product.shortText[props.flag]}
        />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:type" content="product" />
        <meta
          property="og:title"
          content={product && product.metaTags[props.flag].title}
        />
        <meta
          property="og:description"
          content={product && product.shortText[props.flag]}
        />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_IMAGES_URL}/480x480/${
            product && product.Image[0]
          }`}
        />
        <link rel="canonical" href="http://unitech.am" />
      </Helmet>

      <FullScreenDialog
        open={fullScreen}
        setOpen={setfullScreen}
        images={props.product.Image}
      />
      <div className={allstyles.breadcrumbs}>
        {MakeBreadcrumbs(
          [
            { name: Names.products[props.flag], path: "/products" },
            ...parents.map((item) => {
              return {
                name: item.name[props.flag],
                path: "/products",
                state: item.id,
              };
            }).reverse(),
            { name: props.product.name[props.flag], path: "/products" },
          ],
          props.flag
        )}
      </div>

      <section className={allstyles.fullContainer}>
        <div style={{ width: "100%", minHeight: "100px", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <span
                className={`${
                  props.flag === "am"
                    ? langs.armenianHeaders600
                    : langs.BarlowBold
                } ${allstyles.nameSM}`}
              >
                {product && product.name[props.flag]}
              </span>
              {/* <div
            className={`${allstyles.searchBar} ${allstyles.searchInputContainer}`}
          >
            <input
              className={allstyles.searchInput}
              placeholder={
                props.flag === "us"
                  ? "Search..."
                  : props.flag === "ru"
                  ? "Поиск..."
                  : "Որոնել..."
              }
              value={props.searchWord}
              onChange={(e) => {
                props.setSearchWord(e.target.value);
              }}
            />
            </div> */}
              <div
                style={{
                  height: "fit-content",
                  maxWidth: "100%",
                  position: "relative",
                }}
              >
                {/* <ImageGallery
                  slideDuration={200}
                  thumbnailPosition={
                    window.innerWidth < 601 ? "bottom" : "left"
                  }
                  // slideOnThumbnailOver={true}
                  // ref={galleryRef}
                  // renderCustomControls={_renderCustomControls}
                  // onMouseOver={() => {
                  //   setShowImageControllButtons({
                  //     index: galleryRef.current.getCurrentIndex(),
                  //     bool: true,
                  //   });
                  // }}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  // className="imageGallery"
                  renderItem={()=>{myRenderItem(
                      props,
                    //   props.product.Image.map((image) => {
                    //   let index = image.indexOf("(");
                    //   if (index !== -1) {
                    //   } else {
                    //   }
                    //   return {
                    //     original: `${process.env.REACT_APP_API_URL}/${imageSizeOriginal}/${image}`,
                    //     thumbnail: `${process.env.REACT_APP_API_URL}/${imageSizeThumbnail}/${image}`,
                    //   };
                    // })
                    )
                  }}
                  showNav={false}
                  items={props.product.Image.map((image) => {
                    let index = image.indexOf("(");
                    if (index !== -1) {
                    } else {
                    }
                    return {
                      original: `${process.env.REACT_APP_API_URL}/${imageSizeOriginal}/${image}`,
                      thumbnail: `${process.env.REACT_APP_API_URL}/${imageSizeThumbnail}/${image}`,
                    };
                  })}
                /> */}

                {/* <ReactImageMagnify {...{
                        smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: `${process.env.REACT_APP_API_URL}/${imageSizeOriginal}/${props.product.Image[0]}`
                        },
                        lensStyle: {
                          background: 'hsla(0, 0%, 100%, .3)',
                          border: '1px solid #ccc'
                        },
                        largeImage: {
                            src: `${process.env.REACT_APP_API_URL}/${"1024x1024"}/${props.product.Image[0]}`,
                            width: 1200,
                            height: 1800
                        }
                    }} /> */}

                <ExampleImgGal images={props.product.Image} />

                {/* images gallery */}
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={allstyles.allInfo}>
                <div className={allstyles.nameInfoContainer}>
                  <span className={allstyles.CategoryName}>
                    {parents && parents[0] && parents[0].name[props.flag]}
                  </span>
                  <span
                    className={`${
                      props.flag === "am"
                        ? langs.armenianHeaders600
                        : langs.BarlowBold
                    } ${allstyles.name}`}
                  >
                    {product && product.name[props.flag]}
                  </span>

                  <span
                    className={`${
                      props.flag === "us"
                        ? langs.NunitoRegular
                        : langs.dejavuExtraLightWF
                    } ${allstyles.shortText}`}
                  >
                    {productInfo &&
                      productInfo[props.flag][0][
                        `aboutText${props.flag.toLocaleUpperCase()}`
                      ]}
                    <div className={allstyles.containerScroll}></div>
                  </span>
                  <div className={allstyles.infoContainer}>
                    {/* <div className={allstyles.guarantee}> */}

                    <span>
                      <span
                        className={`${allstyles.guaranteeWM}  ${
                          props.flag === "am"
                            ? langs.DejaVuSansWF
                            : langs.NunitoRegular
                        }  `}
                      >
                        {Names.brand[props.flag]}
                      </span>{" "}
                      :{" "}
                      <span
                        className={`${allstyles.brandName} ${
                          props.flag === "am"
                            ? langs.DejaVuSansBold
                            : langs.BarlowBold
                        }`}
                      >{` ${
                        brandName && brandName.us.toLocaleUpperCase()
                      } `}</span>
                    </span>
                    <div
                      className={`${
                        props.flag === "am"
                          ? langs.DejaVuSansWF
                          : langs.BarlowBold
                      } ${allstyles.year}`}
                    >
                      <span
                        className={` ${allstyles.guaranteeWM} ${
                          props.flag === "am"
                            ? langs.DejaVuSansWF
                            : langs.NunitoRegular
                        }`}
                      >
                        {Names.guarantee[props.flag]}
                      </span>{" "}
                      :{" "}
                      <span
                        className={
                          props.flag === "us"
                            ? langs.BarlowBold
                            : langs.DejaVuSansBold
                        }
                      >
                        {" "}
                        {`${product && product.guarantee} `}{" "}
                        {Names.year[props.flag]}{" "}
                      </span>
                    </div>

                    </div>
                    {/* </div> */}
                    <span className={`${allstyles.guarantee}`}>
                      <span
                        className={`${
                          props.flag === "am"
                            ? langs.DejaVuSansWF
                            : langs.NunitoRegular
                        }`}
                      >
                        {Names.share[props.flag]} :
                      </span>



                      <FacebookShareButton
                        style={{outline: "none"}}
                        url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${window.location.pathname}`}
                        quote={props.product.shortText[props.flag]}
                        hashtag={`#${props.product.name[props.flag]}`}
                      >
                        <svg style={{marginLeft: "20px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0ZM19.3229 18.2687V27.7896H15.3836V18.2691H13.4155V14.9881H15.3836V13.0182C15.3836 10.3416 16.4949 8.75 19.6522 8.75H22.2808V12.0313H20.6377C19.4087 12.0313 19.3274 12.4899 19.3274 13.3456L19.3229 14.9877H22.2994L21.9511 18.2687H19.3229Z" fill="#3B5998"/>
                        </svg>
                      </FacebookShareButton>
                      <LinkedinShareButton
                        style={{outline: "none"}}
                      url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${window.location.pathname}`}
                        quote={props.product.shortText[props.flag]}
                        hashtag={`#${props.product.name[props.flag]}`}
                      >
                         <svg style={{marginLeft: "14px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0ZM8.39681 14.4945H12.3627V26.4103H8.39681V14.4945ZM12.626 10.8082C12.6002 9.63988 11.7648 8.75 10.4081 8.75C9.05135 8.75 8.16439 9.63988 8.16439 10.8082C8.16439 11.9524 9.02514 12.8679 10.3566 12.8679H10.3819C11.7648 12.8679 12.626 11.9524 12.626 10.8082ZM22.1039 14.2142C24.7136 14.2142 26.6701 15.9176 26.6701 19.5777L26.67 26.4098H22.7042V20.0349C22.7042 18.4336 22.1304 17.3409 20.6947 17.3409C19.599 17.3409 18.9464 18.0776 18.6598 18.789C18.5549 19.044 18.5292 19.3992 18.5292 19.7554V26.4102H14.5628C14.5628 26.4102 14.6151 15.6124 14.5628 14.4943H18.5292V16.1821C19.0556 15.3707 19.9983 14.2142 22.1039 14.2142Z" fill="#0077B5"/>
                        </svg>
                      </LinkedinShareButton>
                      {/*

                    <FacebookShareButton
                      style={{outline: "none"}}
                      url={`${process.env.REACT_APP_URL}${window.location.pathname}`}
                      quote={props.product.shortText[props.flag]}
                      hashtag={`#Unitech #${props.product.name[props.flag]}`}
                    >
                      <button className={allstyles.shareButton} >
                        <GrGooglePlus fontSize="16px" />
                      </button>
                    </FacebookShareButton>
                    */}

                      <TelegramShareButton
                        style={{outline: "none"}}
                      url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${window.location.pathname}`}
                        quote={props.product.shortText[props.flag]}
                        hashtag={`#${props.product.name[props.flag]}`}
                      >
                        <svg style={{marginLeft: "14px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM14.2962 25.5207C14.7218 25.5207 14.9164 25.3314 15.1514 25.1025L15.1712 25.0832L17.268 23.0444L21.6341 26.2702C22.4389 26.7143 23.0198 26.4843 23.2202 25.523L26.0909 11.9951C26.3849 10.8167 25.6418 10.2823 24.8719 10.6318L8.015 17.1317C6.86436 17.5933 6.87107 18.2352 7.80526 18.5212L12.1311 19.8714L22.1459 13.5532C22.6187 13.2665 23.0526 13.4206 22.6965 13.7367L14.5816 21.0597L14.5934 21.0684L14.2962 25.5207Z" fill="#31A8DC"/>
                        </svg>
                      </TelegramShareButton>

                      <TwitterShareButton
                        style={{outline: "none"}}
                      url={`${process.env.REACT_APP_LINK_FRONT_SITEMAP}${window.location.pathname}`}
                        quote={props.product.shortText[props.flag]}
                        hashtag={`#${props.product.name[props.flag]}`}
                      >
                        <svg style={{marginLeft: "14px"}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0ZM17.0126 14.8298L16.9759 14.2242C16.8657 12.6548 17.8327 11.2212 19.3629 10.6651C19.9259 10.4674 20.8807 10.4427 21.505 10.6157C21.7498 10.6898 22.215 10.937 22.5455 11.1594L23.1453 11.5673L23.8063 11.3572C24.1735 11.246 24.6632 11.0606 24.8835 10.937C25.0916 10.8258 25.2752 10.764 25.2752 10.8011C25.2752 11.0112 24.8223 11.7279 24.4428 12.1234C23.9287 12.6795 24.0756 12.7289 25.1161 12.3582C25.7404 12.1481 25.7526 12.1481 25.6302 12.3829C25.5567 12.5065 25.1773 12.939 24.7733 13.3344C24.0878 14.0141 24.0511 14.0883 24.0511 14.6567C24.0511 15.5342 23.6349 17.3631 23.2187 18.3641C22.4476 20.2425 20.795 22.1827 19.1425 23.159C16.8167 24.5308 13.7198 24.8768 11.1125 24.0735C10.2434 23.8016 8.75 23.1096 8.75 22.986C8.75 22.9489 9.20291 22.8995 9.75375 22.8871C10.9044 22.8624 12.055 22.5411 13.0343 21.9727L13.6953 21.5772L12.9364 21.3177C11.8592 20.9469 10.8922 20.0942 10.6473 19.291C10.5739 19.0315 10.5984 19.0191 11.2839 19.0191L11.9938 19.0067L11.394 18.7225C10.6841 18.3641 10.0353 17.7586 9.71703 17.1407C9.48445 16.6958 9.19067 15.5712 9.27636 15.4847C9.30084 15.4477 9.5579 15.5218 9.85168 15.6207C10.6963 15.9296 10.8065 15.8555 10.3168 15.3364C9.39877 14.3972 9.11723 13.0008 9.5579 11.6785L9.76599 11.0853L10.5739 11.8886C12.2264 13.5075 14.1727 14.4714 16.4006 14.7556L17.0126 14.8298Z" fill="#55ACEE"/>
                        </svg>
                      </TwitterShareButton>
                    </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          spacing={window.innerWidth < 601 ? 0 : 5}
          style={window.innerWidth < 601 ? {} : { marginTop: "70px" }}
        >
          <Grid item sm={8} xs={12}>
            {productInfo &&
            Array.isArray(productInfo[props.flag][1].table.rows) &&
            productInfo[props.flag][1].table.rows[0].name ? (
              <div>
                <div
                  className={`${
                    props.flag === "us" ? langs.BarlowBold : langs.DejaVuSansWF
                  } ${allstyles.title}`}
                >
                  {Names.Specifications[props.flag]}
                </div>
                <div>
                  <Table
                    flag={props.flag}
                    table={productInfo && productInfo[props.flag][1].table.rows}
                  />
                </div>
              </div>
            ) : null}
          </Grid>
          <Grid item sm={4} xs={12}>
            <div className={allstyles.relatedFor1366}>
              <StickyBox offsetTop={120} offsetBottom={10}>
                <span
                  className={`
                  ${props.flag === "us" ? langs.BarlowBold : langs.DejaVuSansWF}
                ${allstyles.title}`}
                >
                  {" "}
                  {props.flag === "us"
                    ? "Related Products"
                    : props.flag === "ru"
                    ? "Похожие продукты"
                    : "Նմանատիպ Ապրանքներ"}{" "}
                </span>
                <div
                  style={{ marginTop: "20px", width: "100%", height: "100%" }}
                >
                  {product && (
                    <Related
                      allProducts={productsFromRedux}
                      RelatedProducts={product.relatedProductsIds}
                      flag={props.flag}
                    />
                  )}
                </div>
              </StickyBox>
            </div>
          </Grid>
        </Grid>
      </section>
      <div
        id="myPortal"
        style={{ position: "absolute", left: "50%", top: "166px", backgroundColor:"#fff" }}
      />
      <div style={{ backgroundColor: "#EAEAEA" }}>
        <div
          style={{
            width: "100%",
            margin: "50px auto 0px",
            padding: "50px 0px",
          }}
        >
          <LastSeen flag={props.flag} />
        </div>
      </div>
    </>
  );
}
