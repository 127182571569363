import React, {useEffect} from 'react'
// import {useHistory} from 'react-router-dom'
import {useLocation} from "react-router-dom"



export default function SearchResult(props) {
    const location = useLocation()
    // const history = useHistory()
    useEffect(() => {
        // if(!props.search){
        //     history.push("/")
        // }
        // else{
            (function() {
                var cx = '011325779640217210047:iptwkvvfzfw';
                var gcse = document.createElement('script');
                gcse.type = 'text/javascript';
                gcse.async = true;
                gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
                var s = document.getElementsByTagName('head')[0];
                s.parentNode.insertBefore(gcse, s);
              })();
      
    }, [])
    function handleLoad(){
        let s;
        setTimeout(() => {
            s = document.getElementById("gsc-i-id1")
            if(s){
                s.value=location.state.search
                let b = document.getElementsByClassName("gsc-search-button gsc-search-button-v2")[0]
                b.click()
            }
        }, 500);
            
        
    }


    return (
        <div  style={{maxWidth:"1366px", padding:"50px 50px 20px", margin:"150px auto 0px"}}>
            
                <div onLoad={handleLoad()} class="gcse-searchbox" ></div>

                <div style={{minHeight:"50vh"}}>
                    <div class="gcse-searchresults"></div>
                </div>
                
        </div>
    )
}
