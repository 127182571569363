import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CallIcon from './icons/phoneIconMob/PathComponent';
import MapIcon from './icons/mapIconMob/GroupComponent';
import MailIcon from './icons/mailIconMob/PathComponent';
import FbIcon from './icons/fbIconMobile/PathComponent';

import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import "./headerforMobileOpen.css"
import Search from "../ShowProducts/categories icons/search/NounSearchComponent"
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Names} from "../../staticTexts"
import {useHistory} from "react-router-dom"
import { Collapse } from '@material-ui/core';


const useStyles = makeStyles({
  list: {
    width: 250,
    padding: "30px 20px"
  },
  fullList: {
    width: 'auto',
    height: "calc(100vh - 80px)",
    marginTop:"80px",
  },
});

export default function TemporaryDrawer(props) {
  const history = useHistory()
  const [searchWord, setSearchWord] = useState("")
  const classes = useStyles();
  const flag = useSelector(s=>s.flag)
  const [showRootCategories, setShowRootCategories] = useState(false)
  const categoriesFromRedux = useSelector(state=>state.categories)

  function handleClose(){
    props.setOpen(false)
  }
 
 function handleSearch(e){
  if (e.keyCode === 13) {
      history.push({
        pathname: "/SearchResult",
        state: { search: e.target.value },
      });
      props.setOpen(false)
  }
}
 

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={handleClose}
    >
      <div style={{
          margin:"0px 0px 27px 0px",
          padding:"4px 8px 4px 18px ",
          border:"1px solid rgba(209,216,238, 1)",
          borderRadius:"9px",
          position:"relative"
        }}
        onClick={(e)=>{e.stopPropagation(); e.preventDefault()}}
      >
        <Input
          id="standard-adornment-search"
          value={searchWord}
          onChange={(e)=>{setSearchWord(e.target.value)}}
          onKeyUp={(e)=>{handleSearch(e)}}
          disableUnderline
          fullWidth
          placeholder="Search anything..."
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={(e) => {
                  history.push({
                    pathname: "/SearchResult",
                    state: { search: e.target.value },
                  })
            }}
              >
                {<Search />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div style={{
        width:"100%",
        overflowY:"auto",
        overflowX:"hidden",
        flexDirection:"column",
        height: "calc(100% - 118px)",
        paddingBottom: "20px",
      }}>
        <div style={{display: "flex", justifyContent:"center"}} onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setShowRootCategories(!showRootCategories)}} className={"mobileMenuProducts"} >
          <span style={{position:"relative"}} >{Names.products[flag]} <div className={"mobileMenuProductsArrowUp"} style={ showRootCategories ? {transform:  "translate(-50%) rotate(-225deg)", top:"40%"} : {transform: "translate(-50%) rotate(-45deg)"} } /> </span> 
        </div>
        <Collapse in={showRootCategories} >
          <div className={"mobileMenuRootCategories"} >
            {categoriesFromRedux.filter(item=>item.parentId === "null").map(cat=>{
              return <Link to={{pathname: "/products", state: cat && cat.id}} className={"mobileMenuRootCategory"} >
              {cat.name[flag]}
            </Link>
            })}
          </div>
        </Collapse>
        <div style={{marginTop:"35px", display: "flex", justifyContent:"center"}}>
        <Link to="/Aboutus" style={{textDecoration:"none", color:"inherit"}} >
          {Names.footer.Aboutus[flag]}
        </Link>
        </div>
        <div style={{marginTop:"35px", display: "flex", justifyContent:"center"}}>
        <Link to="/downloads" style={{textDecoration:"none", color:"inherit"}} >
          {Names.download[flag]}
        </Link>
        </div>
        <div style={{marginTop:"35px", display: "flex", justifyContent:"center"}}>
        <Link to="/contacts" style={{textDecoration:"none", color:"inherit"}} >
          {Names.footer.Contacts[flag]}
        </Link>
        </div>
      </div>
      <div style={{
        padding:"0px 25vw",
        height:"80px",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        position:"absolute",
        bottom:"0px",
        left:"0",
        right:"0",
        backgroundColor: "#fff",
        borderTop: "1px solid lightgray",
      }}>
        <Link to="/contacts">
          <MapIcon/>
        </Link>
        <a href={`tel:${props.contacts && props.contacts.phone}`} >
          <CallIcon/>
        </a>
        <a  href={`mailto:${props.contacts && props.contacts.mail}`} >
          <MailIcon/>
        </a>
        {/* <a target="_blank" href={`https://www.${props.contacts && props.contacts.fblink}`} >
          <FbIcon/>
        </a> */}
      </div>
    </div>
  );

  return (
    <div>
      
        <React.Fragment>
          <Drawer 
            anchor={"top"} 
            open={props.open} 
            onClose={handleClose}>
            {list("top")}
          </Drawer>
        </React.Fragment>
    </div>
  );
}