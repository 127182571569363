import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import style from "./downloads.module.css"
import ArrowSelect from "./icons/arrowIcon/PathComponent"
import {Names} from "../../staticTexts"



export default function ControlledOpenSelect(props) {
  const {categories} = props


  const handleChange = v => {
    if(v && v.id){
      props.setSelectedProduct(v.id);
    }else{
      props.setSelectedProduct(null);
    }
  };



  return (
    <div style={{ width: "100%" }}>

    <Autocomplete
        popupIcon={<ArrowSelect />}
        id="combo-box-demo"
        // disableClearable
        options={categories}
        noOptionsText={props.selectedCategory ? Names.no_options[props.flag] : Names.select_Category[props.flag]}
        getOptionLabel={option => option.name[props.flag]}
        onChange={(e,v)=>handleChange(v)}
        renderInput={(params) => <div className={style.ForSelects} ><TextField 
        {...params} 
        placeholder={Names.product[props.flag]}
        variant="standard" 
        InputProps={{...params.InputProps, disableUnderline: true}}
        />
        </div>
        }
 
        // renderInput={(params) => (
          
        //   <TextField
        //     {...params}
        //     label="Search input"
        //     margin="normal"
        //     variant="outlined"
        //     InputProps={{ ...params.InputProps, type: 'search' }}
        //   />
        // )}
      />

      {/* <FormControl style={{ width: "100%" }} className={classes.formControl}>
        <Select
          // disabled={props.disable}
          value={props.selectedProduct}
          style={{ width: "100%", height:"39px" }}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          disableUnderline
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value={null}>
            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <span style={{
                color: "lightgray",
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                fontStyle: "italic",
                fontWeight: "lighter",
                fontSize:"14px",
                paddingLeft:"20px"
              }}> {props.flag === "us" ? "Model" : props.flag === "ru" ? "Модель" : "Մոդել"} </span>
            </div>
          </MenuItem>
          {categories.length ? categories.map(category => {
            return <MenuItem value={category.id}>
              <div style={{ display: "flex", paddingLeft:"20px", width: "100%", alignItems: "center" }}>
                {category.name[props.flag]}
              </div>

            </MenuItem>
          })
        :
        props.disable ?
        
        <MenuItem disabled >
              <div style={{ display: "flex", paddingLeft:"20px", width: "100%", justifyContent:"center", alignItems: "center" }}>
                Select Category To Show Products
              </div>

            </MenuItem>
            : 
        <MenuItem disabled >
              <div style={{ display: "flex", paddingLeft:"20px", width: "100%", justifyContent:"center", alignItems: "center" }}>
                No Products
              </div>

            </MenuItem>
        }
        </Select>
      </FormControl> */}
    </div>
  );
}