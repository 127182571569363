export  const SET_PRODUCTS = (products) => ({
    type: 'SET_PRODUCTS',
    payload: products,
})

export  const SET_CATEGORIES = (categories) => ({
    type: 'SET_CATEGORIES',
    payload: categories,
})

export  const SET_BADGES = (badges) => ({
    type: 'SET_BADGES',
    payload: badges,
})

export  const SET_BRANDS = (brands) => ({
    type: 'SET_BRANDS',
    payload: brands,
})

export  const SET_NEWS = (news) => ({
    type: 'SET_NEWS',
    payload: news,
})
export  const SET_JOBS = (jobs) => ({
    type: 'SET_JOBS',
    payload: jobs,
})