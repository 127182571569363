// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".app_slide__3VAQH{\n    border: none; \n    width: 100%; \n    margin-top: 90px;\n    height: calc(100vw / 2.9);\n    overflow: hidden;\n}\n.app_slideact__W8-22{\n    border: none; \n    width: 100vw; \n    height: 100%;\n    overflow: hidden;\n}", ""]);
// Exports
exports.locals = {
	"slide": "app_slide__3VAQH",
	"slideact": "app_slideact__W8-22"
};
module.exports = exports;
