import React from 'react';
import tableStyle from "./tableSimple.module.css"
import langs from "../../stylesForLangs/langs.module.css"


export default function SimpleTable(props) {
    
return <table className={tableStyle.table} >

    {Array.isArray(props.table) && props.table.map((row, i) => {
      return <>
      { row.name && row.url ?
       <tr className={tableStyle.tr} key={i}>
        <th className={tableStyle.th} >
          
              <span className={`${props.flag === "am" ? langs.armenianHeaders600 : langs.foreignHeaders600} `} >
                {row.name}
              </span>
          
        </th>
        <th className={tableStyle.th}  >
         
              <span className={`${props.flag === "am" ? langs.armenianHeaders600 : langs.foreignHeaders600} `} >
                {row.url}
              </span>
          </th>

      </tr>
    :
    null  
    }
      </>
    })}
  </table>
}