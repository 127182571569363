/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounFilterComponent = ({ svgRef }) => (
  <svg width="22px" height="22px" viewBox="0 0 22.5 22.5" ref={svgRef}>
    <defs>
      <style>{".ewa{fill:#5a5a5a;stroke:#5a5a5a;stroke-width:0.5px;}"}</style>
    </defs>
    <g transform="translate(0.25 0.25)">
      <path
        className="ewa"
        d="M3.468,6.815h7.723V9.226a.468.468,0,0,0,.936,0V6.815h12.4a.468.468,0,0,0,0-.936h-12.4V3.468a.468.468,0,1,0-.936,0V5.879H3.468a.468.468,0,1,0,0,.936Z"
        transform="translate(-3 -3)"
      />
      <path
        className="ewa"
        d="M24.532,38.579H19.149V36.168a.468.468,0,0,0-.936,0v2.411H3.468a.468.468,0,0,0,0,.936H18.213v2.411a.468.468,0,0,0,.936,0V39.515h5.383a.468.468,0,0,0,0-.936Z"
        transform="translate(-3 -28.047)"
      />
      <path
        className="ewa"
        d="M24.532,71.279H9.787V68.868a.468.468,0,1,0-.936,0v2.411H3.468a.468.468,0,0,0,0,.936H8.851v2.411a.468.468,0,0,0,.936,0V72.215H24.532a.468.468,0,0,0,0-.936Z"
        transform="translate(-3 -53.094)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounFilterComponent svgRef={ref} {...props} />
));
export default ForwardRef;
