

import React from "react"
import Home from '../../Components/ShowProducts/icons/home.svg'
import { Typography, Breadcrumbs } from '@material-ui/core';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function PaginationOutlined({
    productsPages,
    page,
    setPage
}) {
  const classes = useStyles();

    function handleChange(_, i){
        if(page !== i){
            setPage(i)
            window.scrollTo({
                top:450,
                left:0,
                behavior:"smooth"
            })
        }
    }

  return (
    <div className={classes.root}>
      <Pagination count={productsPages.length-1} variant="outlined" color="primary" onChange={handleChange} />
    </div>
  );
}


export function MakeBreadcrumbs(arr,flag) {
    let home = flag==="am" ? "ԳԼԽԱՎՈՐ" : flag === "ru" ? "ГЛАВНАЯ" : "HOME"
    return (
      <Breadcrumbs separator={<span style={{opacity:"0.7", color:"#32406E"}}>\</span>} aria-label="breadcrumb">
        <Link style={{textDecoration:"none"}} to="/" >
          {/* <img src={Home} alt="home" /> */}
    <span style={{opacity:"0.7", color:"#32406E", fontSize:"12px"}} >{home}</span>
        </Link>
        {arr.length && arr.map((item, i)=>{
            if(i<arr.length-1){
                return <Link key={item.name} style={{textDecoration:"none"}} to={{pathname: item.path, state: item.state ? item.state : "null"}}>
                    <Typography color="primary">
                        <span style={{ color: "#32406E", fontSize:"12px" }} >{item.name.toLocaleUpperCase()}</span>
                    </Typography>
                </Link>
            }else{
            return <Typography key={item.name} color="primary">
                        <span style={{ color: "#32406E", fontSize:"12px" }} onClick={()=>{alert( `width:${window.innerWidth}`)}} >{item.name.toLocaleUpperCase()}</span>
                    </Typography>
            }
        })}
      </Breadcrumbs>
    );
  }


  export function PageButtons({productsPages, page, setPage}){
    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }} >
    {productsPages.length > 1 && productsPages.length <= 3 ? productsPages.map((_, i) => {
        return <button onClick={() => { setPage(i); window.scrollTo(0, 0) }} className={page === i ? 'pagebutton12Clicked' : 'pagebutton12'}>
            {i + 1}
        </button>
    })
        :
        productsPages.length > 3 && productsPages.length < 4?
            <>
                {<button onClick={() => { page - 1 >= 0 && setPage(page - 1) }} className={`${page === 0 ? 'pagebutton12Disablednav' : 'pagebutton12nav'}`}>
                    Previous
                </button>}
                {page - 1 <= productsPages.length && page === productsPages.length - 1 && <button onClick={() => { setPage(page - 2); }} className={'pagebutton12'}>
                    {page - 1}
                </button>}
                {!!page && <button onClick={() => { setPage(page - 1); }} className={'pagebutton12'}>
                    {page}
                </button>}
                <button className={'pagebutton12Clicked'}>
                    {page + 1}
                </button>
                {page + 1 < productsPages.length && <button onClick={() => { setPage(page + 1); }} className={'pagebutton12'}>
                    {page + 2}
                </button>}
                {!page && <button onClick={() => { setPage(page + 2); }} className={'pagebutton12'}>
                    {page + 3}
                </button>}
                {<button onClick={() => { page + 1 <= productsPages.length - 1 && setPage(page + 1)  }} className={`${page === productsPages.length - 1 ? 'pagebutton12Disablednav' : 'pagebutton12nav'}`}>
                    Next
                </button>}
            </>
            :
            productsPages.length > 4 ? 
            <>
                {<button onClick={() => { page - 1 >= 0 && setPage(page - 1) }} className={`${page === 0 ? 'pagebutton12Disablednav' : 'pagebutton12nav'}`}>
                    Previous
                </button>}
                {page >2 && <button onClick={() => { setPage(0); window.scrollTo(0, 0) }} className={'pagebutton12'}>
                    {1}
                </button>}
                {page >2 && <button onClick={() => { setPage(1); window.scrollTo(0, 0) }} className={'pagebutton12'}>
                    {2}
                </button>
                }
                {page >= 3 && <>
                &nbsp;
                &nbsp;
                ...
                </>}
                
                {!!page && <button onClick={() => { page >= productsPages.length - 4 ? setPage(productsPages.length - 4) : setPage(page - 1); window.scrollTo(0, 0) }} className={page === productsPages.length - 4 ? 'pagebutton12Clicked' : 'pagebutton12'}>
                    {page >= productsPages.length - 4 ? productsPages.length - 3 : 
                    page}
                </button>}
                <button onClick={() => { page >= productsPages.length - 4 && setPage(productsPages.length - 3)}} className={page <= productsPages.length - 3 && page!==productsPages.length - 4 ? 'pagebutton12Clicked' : 'pagebutton12'}>
                    {page >= productsPages.length - 4 ? productsPages.length - 2 : 
                    page + 1}
                </button>
                {page < productsPages.length - 4 &&  <button onClick={() => { setPage(page + 1); window.scrollTo(0, 0) }} className={'pagebutton12'}>
                    {page + 2}
                </button>}
                {page < productsPages.length - 4 && <>
                &nbsp;
                &nbsp;
                ...
                </>}
                {<button onClick={() => { setPage(productsPages.length - 2);window.scrollTo(0, 0) }} className={page === productsPages.length - 2 ? 'pagebutton12Clicked' : 'pagebutton12'}>
                    {productsPages.length - 1}
                </button>}
                {<button onClick={() => { setPage(productsPages.length - 1);window.scrollTo(0, 0) }} className={page === productsPages.length - 1 ? 'pagebutton12Clicked' : 'pagebutton12'}>
                    {productsPages.length}
                </button>}

                {<button onClick={() => { page + 1 <= productsPages.length - 1 && setPage(page + 1)  }} className={`${page === productsPages.length - 1 ? 'pagebutton12Disablednav' : 'pagebutton12nav'}`}>
                    Next
                </button>}
            </>
            :
            null
    }
</div>
  }