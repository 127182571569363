// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n\n\n\n\n.noproductsFound_container__7XYbN{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n.noproductsFound_text__1QuIQ{\n    margin-top: 163px;\n    font-size: 2.3vw;\n    color: #31465A;\n\n}\n@media only screen and (max-width:600px){\n    .noproductsFound_text__1QuIQ{\n        font-size: 8vw;\n    \n    }\n}", ""]);
// Exports
exports.locals = {
	"container": "noproductsFound_container__7XYbN",
	"text": "noproductsFound_text__1QuIQ"
};
module.exports = exports;
