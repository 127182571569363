import React, { useEffect, useState, useRef } from 'react';
import Header from './Components/Header/Header';
import Footer from './Components/footer/footer';
import BodyMain from './Components/bodyMain/bodyMain';
import ShowProducts from './Components/ShowProducts/ShowProducts'
import Contacts from './Components/Contacts/contacts';
import {
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";


// // import SubHeader from "./Components/Header/SubHeader"

import { styles } from './styleApp';
import { Helmet } from "react-helmet";
// import Routes from "./routes"
import loadData from './helpers/loadData';
import { useDispatch, useSelector } from "react-redux";
import { SET_PRODUCTS, SET_CATEGORIES, SET_BRANDS, SET_BADGES, SET_NEWS, SET_JOBS  } from "./redux/actions/productAction/productsAction"
// import { SET_CATEGORIES } from "../redux/actions/categoryAction/categoryAction"
// import { SET_BRANDS } from "../redux/actions/brandsAction/brandAction"
// import { SET_BADGES } from "../redux/actions/badgeAction/badgeAction"
import ProductInfo from "./Components/productInfo/ProductInfo"
import News from "./Components/Newss/News"
import SingleNews from "./Components/news/singleNews"
import Aboutus from "./Aboutus/AboutUs"
import Services from "./Components/services/Services"
import Error404 from "./Error404/Error404"
import Jobs from './Components/Jobs/Jobs'
import SingleJob from './Components/Jobs/singleJob'
// import "./index.css"
import style from "./app.module.css"
import { CircleLoader } from 'react-spinners';
import SearchResult from './SearchResult/SearchResult'
import Downloads from './Components/Downloads/Downloads'
import SlideImg from "./png/banner/banner.png"

function App() {
  const classes = styles();
  const dispatch = useDispatch()
  const [routes, setRoutes] = useState([]);
  const [jobsRoutes, setJobsRoutes] = useState([])
  
  const [headerButtons] = useState([]);
  // const [flag, setFlag] = useState();
  const [, setIndex] = useState();
  const [, setNestedIndex] = useState();
  const flag = useSelector(state => state.flag)
  const [isFetched, setisFetched] = useState(false)
  const [search, setSearch] = useState("")
  const [slideLink, setSlideLink] = useState("")
  const [slideHtml, setSlideHtml] = useState({html:""})
    // "slide/standard.html")
  const [contacts, setContacts] = useState(null)
  const [hover, setHover] = useState([])
  const [lineHeight, setLineHeight] = useState(24)
  const slideRef = useRef()


  useEffect(() => {
    if(window.innerWidth>600 && slideLink!=="slide/standard.html"){
      setSlideLink("slide/standard.html")
     }else if(window.innerWidth<=600 && slideLink!=="slideMobile/standard.html"){
      setSlideLink("slideMobile/standard.html")
     }

     window.addEventListener("resize", function(){
  
      if(window.innerWidth>600 && slideLink!=="slide/standard.html"){
       setSlideLink("slide/standard.html")
      //  slideRef.current.style.height=`calc(100vw / 4})`
      }else if(window.innerWidth<=600 && slideLink!=="slideMobile/standard.html"){
       setSlideLink("slideMobile/standard.html")
      //  slideRef.current.style.height=`calc(100vw / 2})`
    
      }
    })













     
    // fetch(`${process.env.REACT_APP_API_URL}/getLang`, {
    //   credentials: 'include',
    // }).then(res=>{
    //   return res.json()
    // }).then(res=>{
    //   dispatch(CHANGE_FLAG(res.flag))
    // })


    // fetch(`${process.env.REACT_APP_API_URL}/checktoken`, {
    //   credentials: 'include',
    // })
    //   .then((res) => {
    //     if (res.status !== 200) {
    //       fetch(`${process.env.REACT_APP_API_URL}/getToken`, {
    //         credentials: 'include',
    //       }).then(() => {
            // setGotToken(true)
            // window.location.reload()
        //   })
        // }
      // })
  }, []) 
  
// useEffect(()=>{

//   if(slideRef.current){
//     slideRef.current.addEventListener("resize", function(e){
//       if(window.innerWidth>600){
//        slideLink!=="slide/standard.html" && setSlideLink("slide/standard.html")
//       }else{
//        slideLink!=="slideMobile/standard.html" && setSlideLink("slideMobile/standard.html")
//       }
//     })
//   }
// },[slideRef])
 




  useEffect(() => {
    loadData("staticpages", JSON.stringify({id:"contacts"})).then(res=>{
      if(res && res[0]){
          setContacts(res[0])
      }
  })
 
    loadData(`Categories`)
      .then(allTypes => {
        let categoriesClone = []
        let productsClone = []
        let badgesClone = []
        let brandsClone = []
        allTypes.forEach(element => {
          if (element.type === "Category") {
            categoriesClone.push(element)
          } else if (element.type === "item" && element.active) {
            productsClone.push(element)
          } else if (element.type === "brand") {
            brandsClone.push(element)
          } else if (element.type === "badge") {
            badgesClone.push(element)
          }
        })
        dispatch(SET_BRANDS(brandsClone))
        dispatch(SET_BADGES(badgesClone))
        dispatch(SET_CATEGORIES(categoriesClone))
        dispatch(SET_PRODUCTS(productsClone));
        
        // setRoutes(productsClone)
        loadData("news", JSON.stringify({ type: "news" })).then((data) => {
          dispatch(SET_NEWS(data))
          setRoutes([...productsClone, ...data])
          setisFetched(true)
        })
      })
      loadData("jobs", JSON.stringify({ type: "job" })).then((res) => {
        dispatch(SET_JOBS(res))
        setJobsRoutes(res)
      })

  // eslint-disable-next-line
  }, [])


  useEffect(()=>{
    loadData("headerHover", JSON.stringify({ id: `headerHover${flag.toLocaleUpperCase()}` })).then(res => {
      setHover(Object.values(res[0].header))
      setLineHeight(res[0].lineHeight)
  })
  },[flag])

  // function iframeLoaded(e){
  //   var iFrameID = e.target
  //     if(iFrameID) {
  //           // here you can make the height, I delete it first, then I make it again
  //           iFrameID.height = "";
  //           iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
  //     }   
  // }




  return (<>
    {isFetched ? <section className={classes.section}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Unitech | Торговое оборудование и программное обеспечение для автоматизации магазинов и ресторанов. Кассовое оборудование, сканеры штрих-кодов, слайсеры, торговые весы.</title>
        <meta name="description" content="Helmet application" />
        <link rel="canonical" href="http://unitech.am" />
      </Helmet>
      
      <BrowserRouter >
      <div style={{ position: "sticky", top: 0, backgroundColor: "#fff", zIndex: "1400", borderBottom: "1px solid lightgray" }}>
                <Header lineHeight={lineHeight} hover={hover} contacts={contacts} search={search} setSearch={setSearch} headerButtons={headerButtons} setIndex={setIndex} setNestedIndex={setNestedIndex} flag={flag} />
              </div>
      <Switch>
          <Route exact path="/aboutus">
          {/* <SubHeader contacts={contacts} flag={flag} /> */}
            <Aboutus flag={flag} />
          </Route>
          <Route exact path="/jobs">
          {/* <SubHeader contacts={contacts} flag={flag} /> */}
            <Jobs flag={flag} />
          </Route>
          <Route exact path="/downloads">
          {/* <SubHeader contacts={contacts} flag={flag} /> */}
            <Downloads flag={flag} />
          </Route>
          <Route exact path="/services">
          {/* <SubHeader contacts={contacts} flag={flag} /> */}

            <Services flag={flag} />
          </Route>
          <Route exact path="/SearchResult">
          {/* <SubHeader contacts={contacts} flag={flag} /> */}
            <SearchResult search={search} flag={flag} />
          </Route>
          <Route exact path="/news">
          {/* <SubHeader contacts={contacts} flag={flag} /> */}
            <News flag={flag} />
          </Route>
          {routes.length && routes.map(route => {
            if (route.type === "item") {
              return <Route key={route.path} exact={true} path={route.path}>
                {/* <SubHeader contacts={contacts} flag={flag} /> */}
              {/* <iframe id="slide" className={style.slide} scrolling="no" src={`${process.env.REACT_APP_API_URL}/slide/standard.html`} />             */}
                <ProductInfo productPath={route} product={route} flag={flag} />
                {/* <DrawSection flag={flag} current={route.item} /> */}
              </Route>
            } else if (route.type === "news") {
              return <Route key={route.path} exact={true} path={`/${route.path}`}>
                {/* <SubHeader contacts={contacts} flag={flag} /> */}
                <SingleNews news={route} flag={flag} />
              </Route>
            }else{
              return null
            }
          })}
          {jobsRoutes.length && jobsRoutes.map(route=>{
              return <Route key={route.path} exact={true} path={`${route.path}`}>
                {/* <SubHeader contacts={contacts} flag={flag} /> */}
                <SingleJob job={route} flag={flag} />
              </Route>
            })}
              <Route exact path="/contacts">
              {/* <SubHeader contacts={contacts} flag={flag} /> */}
              {/* <iframe id="slide" className={style.slide} scrolling="no" src={`${process.env.REACT_APP_API_URL}/slide/standard.html`} /> */}
                <Contacts flag={flag} />
              </Route>

              <Route exact path="/Products">
              {/* <SubHeader contacts={contacts} flag={flag} /> */}
              {/* <iframe id="slide" className={style.slide} scrolling="no" src={`${process.env.REACT_APP_API_URL}/slide/standard.html`} /> */}

                <ShowProducts flag={flag} />
              </Route>

              <Route exact path="/">
              {/* <SubHeader contacts={contacts} flag={flag} /> */}
              
              <div >
                {/* <div dangerouslySetInnerHTML={{__html: slideHtml.html}} > */}

                {/* </div> */}
              <iframe title="pageSlider" ref={slideRef}  id="slide" className={style.slide} scrolling="no" src={`${process.env.REACT_APP_API_URL}/originals/${slideLink}`} />
              {/* <img src={SlideImg} style={{width:"100%", padding: "85px 0px 0px 0px"}} alt="slide" /> */}
              </div>
                <BodyMain flag={flag} />
              </Route>
          
          <Route path={"*"}>
            <Error404 flag={flag} />
          </Route>
        </Switch>
        <Footer flag={flag} />
      </BrowserRouter>
    </section>
  :
  <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}} >
    <CircleLoader color={"#9F1A1E"}/>
  </div>
  }
  </>
  );
}

export default (App)
