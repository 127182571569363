/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounViewComponent = ({ svgRef }) => (
  <svg width={20.088} height={11.516} viewBox="0 0 20.088 11.516" ref={svgRef}>
    <defs>
      <style>{".aaa{fill:#fff;}"}</style>
    </defs>
    <path
      className="aaa"
      d="M17.326,3.03a10.346,10.346,0,0,0-14.631,0L0,5.724,2.762,8.486a10.346,10.346,0,0,0,14.631,0l2.694-2.694Zm-.414,4.978a9.671,9.671,0,0,1-13.671,0L.96,5.728,3.176,3.512a9.666,9.666,0,0,1,13.671,0l2.28,2.28Z"
    />
    <path
      className="aaa"
      d="M23.945,8.94a2.378,2.378,0,0,0-2.375,2.375.339.339,0,1,0,.679,0,1.7,1.7,0,0,1,1.7-1.7.339.339,0,1,0,0-.679Z"
      transform="translate(-14.251 -5.906)"
    />
    <path
      className="aaa"
      d="M20.981,3.94a4.411,4.411,0,1,0,4.411,4.411A4.415,4.415,0,0,0,20.981,3.94Zm0,8.144a3.733,3.733,0,1,1,3.733-3.733A3.735,3.735,0,0,1,20.981,12.084Z"
      transform="translate(-10.947 -2.603)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounViewComponent svgRef={ref} {...props} />
));
export default ForwardRef;
