import React from "react"
// import {NavLink} from "react-router-dom"
import "./shineButton.scss"
// import langs from "../../stylesForLangs/langs.module.css"
import {useSelector} from "react-redux"

export default function Shine({name, path}){
    const flag = useSelector(s=>s.flag)
    return <button className={`viewAllButton ${flag}M`}><span>{name}</span><div className={"arrowRightForViewAll"}></div></button>
}
// `${flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600} shinebutton`