/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const FacebookComponent = ({ svgRef }) => (
  <svg width={22} height={15} viewBox="0 0 9.403 18.005" ref={svgRef}>
    <defs>
      <style>{".asd{fill:none;stroke:#fff;stroke-width:0.8px;}"}</style>
    </defs>
    <path
      className="asd"
      d="M13.22,2.857H14.79V.121A20.282,20.282,0,0,0,12.5,0C10.237,0,8.686,1.424,8.686,4.043V6.452h-2.5V9.51h2.5v7.7H11.75V9.511h2.4l.381-3.058H11.749V4.346c0-.884.239-1.489,1.47-1.489Z"
      transform="translate(-5.787 0.4)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <FacebookComponent svgRef={ref} {...props} />
));
export default ForwardRef;
