import React from 'react';
import { styles } from "./styleShowCategories";


export default function OutlinedCard(props) {
  const classes = styles();
  const { product, brand, flag } = props;
  return (
    brand ?
      // <Button>
        <div className={"cardBrandsMain"}>
          <img className={"cardImgBrand"}  alt={brand.name.us} src={`${process.env.REACT_APP_IMAGES_URL}/originals/${brand.Image}`} />
        </div>
      // </Button>
      :

      
        // <Button className={classes.Muibutton}>
          <div className={classes.card}>

          {/* <Grid container> */}
            {/* {product.badge ? <img className={classes.cardBadge} alt="badge" src={product.badge.Image} /> : null} */}
            {/* <Grid item lg={12}> */}
            <div className={classes.imgcontainer}>
              <img  className={classes.cardImg} style={{maxWidth:"115px"}} alt="product" src={`${process.env.REACT_APP_IMAGES_URL}/${product.Image}`} />
              </div>
            {/* </Grid> */}
            {/* <Grid item lg={12}> */}
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
              <p
                className={classes.productName}
              // variant="body1"
              >
                {product.name[flag]}
              </p>
              <p
                className={classes.productShortText}
              // variant="body1"
              >
                {product.shortText}
              </p>
              </div>
            {/* </Grid> */}
            {/* </Grid> */}
          </div>
        //  </Button>
      
  )
}