/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = ({ svgRef }) => (
  <svg width={19.5} height={19.5} viewBox="0 0 19.5 19.5" ref={svgRef}>
    <defs>
      <style>{".aasdasd{fill:#878181;}"}</style>
    </defs>
    <g transform="translate(-1284.932 -79)">
      <g transform="translate(1284.932 79)">
        <path
          className="aasdasd"
          d="M8.587,17.175a8.587,8.587,0,1,1,8.587-8.587A8.6,8.6,0,0,1,8.587,17.175Zm0-15.589a7,7,0,1,0,7,7A7.01,7.01,0,0,0,8.587,1.585Z"
        />
        <path
          className="aasdasd"
          d="M356.383,357.176a.79.79,0,0,1-.561-.232l-4.545-4.545a.793.793,0,0,1,1.121-1.121l4.545,4.545a.793.793,0,0,1-.56,1.353Z"
          transform="translate(-337.676 -337.676)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <GroupComponent svgRef={ref} {...props} />
));
export default ForwardRef;
