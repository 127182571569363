// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.ShowBrands_head__1loAB{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.ShowBrands_header__3Fd3Z {\n    font-size: 28px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 34px;\n    letter-spacing: 0em;\n    text-align: left;\n\n}\n\n.ShowBrands_headname__BG0Qp{\n    white-space: nowrap;\n    font-size: 28px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 34px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: var(--headNameColor);\n}", ""]);
// Exports
exports.locals = {
	"head": "ShowBrands_head__1loAB",
	"header": "ShowBrands_header__3Fd3Z",
	"headname": "ShowBrands_headname__BG0Qp"
};
module.exports = exports;
