/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = ({ svgRef }) => (
  <svg width={39} height={31} viewBox="0 0 19.418 18.214" ref={svgRef}>
    <defs>
      <style>{".www{fill:#9F1A1E;stroke:#9F1A1E;stroke-width:0.3px;}"}</style>
    </defs>
    <g transform="translate(0.05 0.05)">
      <path
        className="www"
        d="M5.5,23.859c0,1.693,4.971,2.6,9.659,2.6s9.659-.912,9.659-2.6c0-.933-1.823-2.127-6.881-2.5l1.845-1.845a6.538,6.538,0,1,0-9.247,0l1.845,1.845C7.3,21.732,5.5,22.926,5.5,23.859Zm5.34-13.306a6.109,6.109,0,0,1,8.639,8.639l-4.319,4.319L10.84,19.192A6.1,6.1,0,0,1,10.84,10.553Zm1.954,11.2,2.366,2.366,2.366-2.366c4.45.282,6.859,1.3,6.859,2.084,0,1.02-3.8,2.171-9.225,2.171s-9.225-1.15-9.225-2.171C5.934,23.056,8.322,22.057,12.793,21.753Z"
        transform="translate(-5.5 -8.35)"
      />
      <path
        className="www"
        d="M41.4,23.588A2.978,2.978,0,0,0,37.188,27.8a2.987,2.987,0,0,0,4.211,0A3,3,0,0,0,41.4,23.588Zm-3.907,3.907a2.54,2.54,0,0,1,1.8-4.341,2.546,2.546,0,0,1,1.8.738,2.516,2.516,0,0,1,0,3.581A2.561,2.561,0,0,1,37.492,27.495Z"
        transform="translate(-29.634 -19.605)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <GroupComponent svgRef={ref} {...props} />
));
export default ForwardRef;
