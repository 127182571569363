/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import styles from "../../showProductsGrid.module.css"

const NounMenuComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18.631 18.76">
    {/* <defs>
      <style>{".filled{fill:#ff5656;}"}</style>
    </defs> */}
    <g transform="translate(0 0)">
      <path
        className={props.viewMode===0 ? styles.filled : styles.fillednot}
        d="M11.083,3.484H4.1a.457.457,0,0,0-.457.457v6.983a.457.457,0,0,0,.457.457h6.983a.457.457,0,0,0,.457-.457V3.941a.457.457,0,0,0-.457-.457Zm-.457,6.983H4.557V4.4h6.069Z"
        transform="translate(-3.643 -3.484)"
      />
      <path
        className={props.viewMode===0 ? styles.filled : styles.fillednot}
        d="M34.559,3.484H27.575a.457.457,0,0,0-.457.457v6.983a.457.457,0,0,0,.457.457h6.983a.457.457,0,0,0,.457-.457V3.941a.457.457,0,0,0-.457-.457ZM34.1,10.467H28.033V4.4H34.1Z"
        transform="translate(-16.385 -3.484)"
      />
      <path
        className={props.viewMode===0 ? styles.filled : styles.fillednot}
        d="M11.083,27.242H4.1a.457.457,0,0,0-.457.457v6.983a.457.457,0,0,0,.457.457h6.983a.457.457,0,0,0,.457-.457V27.7a.457.457,0,0,0-.457-.457Zm-.457,6.983H4.557V28.157h6.069Z"
        transform="translate(-3.643 -16.379)"
      />
      <path
        className={props.viewMode===0 ? styles.filled : styles.fillednot}
        d="M34.559,27.242H27.575a.457.457,0,0,0-.457.457v6.983a.457.457,0,0,0,.457.457h6.983a.457.457,0,0,0,.457-.457V27.7a.457.457,0,0,0-.457-.457ZM34.1,34.225H28.033V28.157H34.1Z"
        transform="translate(-16.385 -16.379)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounMenuComponent svgRef={ref} {...props} />
));
export default ForwardRef;
