import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "../../ShowProducts/Card_for_product_redesign";
import SwipeableViews from "react-swipeable-views";
import allstyles from "./ShowProductsfive.module.css";
import NavigateNextIcon from "../main page icons/rightIcon/PathComponent";
import NavigateBeforeIcon from "../main page icons/lefticon/PathComponent";
import "./shadow.css";
import { CircleLoader } from 'react-spinners';
import langsStyle from "../../../stylesForLangs/langs.module.css";
import ArrowRight from "../newsCardicons/arrow/PathComponent";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { orange, pink, green } from "@material-ui/core/colors";
import { useSpring, animated } from "react-spring";
import { Names } from "../../../staticTexts";
import QuickView from "../../ShowProducts/QuickView/QuickView"
import langs from "../../../stylesForLangs/langs.module.css"
import Shine from "../../shineButton/shineButton";
import { Link } from "react-router-dom"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  // root: {
    
  // },
  prevNextbuttonContainer: {
    width: "76px",
    height: "35px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "none",
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [productsVal, setProductsVal] = React.useState(0);
  const [productsarr, setProductsarr] = React.useState([[]]);
  const [count, setCount] = useState();
  const [indicatorColors, setIndicatorColors] = useState("#789456");
  // const [opacity, setOpacity] = useState(0)
  const ProdsRef = useRef();
  const [quickView, setQuickView] = useState({bool:false, product:null})

  const [springnProps, set, stop] = useSpring(() => ({ opacity: 0 }));
  const [springnPropsLeft, setLeft, stopLeft] = useSpring(() => ({
    opacity: 0,
  }));

  const handleChange = (event, newValue) => {
    setProductsVal(0);
    setTimeout(() => {
      setValue(newValue);
    }, 50);
  };

  const theme = createMuiTheme({
    overrides: {
      MuiTabs: {
        indicator: {
          backgroundColor: orange[700],
        },
      },
    },
  });

  const handleChangeIndex = (index) => {
    // if((productsVal === productsarr[value].length - 1) || (productsVal===0)){
    //   return
    // }
    setProductsVal(0);
    setTimeout(() => {
      setValue(index);
    }, 50);
  };
  const handleChangeProdIndex = (index) => {
    setProductsVal(index);
  };
  const resize = useCallback(() => {
    if (window.innerWidth <= 600) {
      indicatorColors !== "#789456" && setIndicatorColors("#789456");
      return 6;
    } else if (window.innerWidth < 1300) {
      setCount(8);
      indicatorColors !== "secondary" && setIndicatorColors("secondary");
      return 8;
    } else if (window.innerWidth > 1300 && window.innerWidth < 1920) {
      setCount(10);
      indicatorColors !== "secondary" && setIndicatorColors("secondary");
      return 10;
    } else if (window.innerWidth >= 1920) {
      setCount(10);
      indicatorColors !== "secondary" && setIndicatorColors("secondary");
      return 10;
    }
  }, [indicatorColors]);
  window.addEventListener("resize", resize);

  useEffect(() => {
    setProductsarr(renderSelectedProducts(props.products, resize()));
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [props, count, resize]);

  function renderSelectedProducts(products, n = 8) {
    if (!products.length) {
      return;
    } else {
      return products.map((product) => {
        if (product.length < n + 1) {
          return [product];
        } else {
          let arr = [[]];
          let index = 0;
          product.forEach((prod) => {
            if (arr[index].length < n) {
              arr[index].push(prod);
            } else {
              index += 1;
              arr[index] = [prod];
            }
          });
          return arr;
        }
      });
    }
  }
  // function indexDown(){
  //   if (productsVal - 1 < 0) {
  //     if(value-1>=0){
  //       handleChangeIndex(value-1);
  //     }
  //     return
  //   }
  //   if (productsVal - 1 === 0) {
  //     setProductsVal(productsVal - 1);
  //     return
  //   }
  //   setProductsVal(productsVal - 1);
  // }

  function indexDown() {
    // if(value-1>=0){
    //   handleChangeIndex(value-1);
    // }
    if (productsVal - 1 < 0) {
      return;
    }
    if (productsVal - 1 === 0) {
      setProductsVal(productsVal - 1);
      return;
    }
    setProductsVal(productsVal - 1);
  }

  function indexUp() {
    // if(value+1<props.tabs.length){
    //   handleChangeIndex(value+1);
    // }
    if (!productsarr[value][productsVal + 1]) {
      return;
    }
    setProductsVal(productsVal + 1);
  }

  function mouseEnter(e) {
    let mouseX = e.pageX - ProdsRef.current.offsetLeft;
    // let mouseY = e.pageY - ProdsRef.current.offsetTop;
    let opacity = (mouseX - 950) / 416;
    let opacityLeft = 1 - mouseX / 416;
    set({
      opacity: opacity > 0 ? opacity : 0,
      config: { mass: 1, tension: 1000, friction: 20 },
    });
    setLeft({
      opacity: opacityLeft > 0 ? opacityLeft : 0,
      config: { mass: 1, tension: 1000, friction: 20 },
    });
  }

  return (
    <div
      className={`${classes.root} ${allstyles.productsFiveContainer}`}
      ref={ProdsRef}
      onMouseLeave={() => {
        set({ opacity: 0 });
        setLeft({ opacity: 0 });
      }}
      onMouseMove={(e) => {
        e.stopPropagation();
        mouseEnter(e);
      }}
    >
      <QuickView flag = {props.flag} open={quickView} setOpen={setQuickView} />
      <div className={` ${allstyles.head} ${ props.flag === "us" ? langs.BarlowRegular : langs.DejaVuSansCondensed} `}  >
        <span className={`${allstyles.headname } ${props.flag}M`}>{Names.products[props.flag]}</span>
                  <Link to={{pathname: "/products"}}>
        <Shine
          name={Names.viewall[props.flag]}
        />
        </Link>
      </div>
      {/* <MuiThemeProvider theme={theme}>
        <AppBar
          position="static"
          color="transparent"
          className={allstyles.containers}
        > */}
          {/* <span className={`${props.flag==="am" ? langsStyle.armenianHeaders600 : langsStyle.foreignHeaders600} ${allstyles.sectionHaeder}`} >{props.flag === "us" ? "PRODUCTS" : props.flag === "ru" ? "ТОВАРЫ" : "ԱՊՐԱՆՔՆԵՐ"}</span> */}
          {/* <span className={allstyles.prevNextbuttonContainerForMobile}>
            <button
              className={`${allstyles.prevNextbuttonForMobile} ${allstyles.noOutline}`}
              onClick={() => {
                indexDown();
              }}
            >
              <NavigateBeforeIcon />
            </button> */}

            {/* <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor={"#000"}
          variant="standard"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={allstyles.tabsContainer}
        >
          {
            props.tabs.map((tab, i) => {
              return <Tab 
              key={i}
              
              className={value === i ? 
                `${allstyles.tabs} ${allstyles.actives} ${props.flag==="am" ? langsStyle.armenianHeaders600 : langsStyle.foreignHeaders600}` 
              : 
              `${allstyles.tabs} ${props.flag==="am" ? langsStyle.armenianHeaders600 : langsStyle.foreignHeaders600} ${allstyles.inactives}`} 
              disableRipple 
              // className={allstyles.tabs} 
              label={tab[props.flag]} 
              {...a11yProps(i)} />
            })
          }
        </Tabs> */}
            {/* <button
              className={`${allstyles.prevNextbuttonForMobile} ${allstyles.noOutline}`}
              onClick={() => {
                indexUp();
              }}
            >
              <NavigateNextIcon />
            </button>
          </span> */}
          {/* <span className={allstyles.prevNextbuttonContainer}>
          <button className={`${allstyles.prevNextbutton} ${allstyles.noOutline}`} onClick={() => { indexDown() }}><NavigateBeforeIcon /></button>
          <button className={`${allstyles.prevNextbutton} ${allstyles.noOutline}`} onClick={() => { indexUp() }}><NavigateNextIcon /></button>
        </span> */}
        {/* </AppBar> */}
      {/* </MuiThemeProvider> */}
      {/* <div style={{width:"375px", margin:"0px auto"}}> */}
        <div>
      {props.isFetched && productsarr[0].length ? (
        <SwipeableViews
          resistance={true}
          index={value}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents={false}
          disableLazyLoading
          slideStyle={{ overflow: "hidden" }}
        >
          {productsarr.map((prodArr) => {
            return (
              <div>
                <div className={allstyles.tabsContainerFull}>
                  {productsVal - 1 >= 0 && <animated.span
                    style={springnPropsLeft}
                    onClick={() => {
                      indexDown();
                    }}
                    className={`${allstyles.arrowButtons} ${allstyles.arrowLeft} rightrot navscontchild`}
                  >
                    <ArrowRight />
                  </animated.span>}

                  {productsarr[value][productsVal + 1] && <animated.span
                    style={springnProps}
                    onClick={() => {
                      indexUp();
                    }}
                    className={`${allstyles.arrowButtons} ${allstyles.arrowRight} navscontchild`}
                  >
                    <ArrowRight />
                  </animated.span>}
                  <SwipeableViews
                    index={productsVal}
                    onChangeIndex={handleChangeProdIndex}
                    disableLazyLoading
                    slideStyle={{ overflow: "hidden" }}
                  >
                    {prodArr.map((prodArrNested) => {
                      return (
                        <div
                          className={
                            allstyles.mobileProductsfiveless4
                          }
                        >
                          {prodArrNested.map((product) => {
                            return (
                              <div className={allstyles.cardContainerForHover}>
                                {" "}
                                <Card
                                  quickView={quickView} 
                                  setQuickView={setQuickView}
                                  key={product.id}
                                  mainpage
                                  flag={props.flag}
                                  product={product}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </SwipeableViews>
                </div>

                <div
                className={"DownButtonsContainer"}
                  style={{
                    marginBottom: "51px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "37px",
                    alignItems: "flex-end",
                  }}
                >
                  {prodArr.length > 1 &&
                    prodArr.map((item, i) => {
                      return (
                        <span
                          key={i}
                          className={
                            productsVal === i
                              ? "downButtonsClicked"
                              : "downButtons"
                          }
                          onClick={() => {
                            setProductsVal(i);
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </SwipeableViews>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircleLoader color={"#9F1A1E"}/>
        </div>
      )}
      </div>
    </div>
  );
}
