import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';


import {Link} from "react-router-dom"
import "./styleHeader.css"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        outline:"none",
        // margin:"0 10px"
        
      },
      paper: {
        outline:"none",
        marginRight: theme.spacing(2),
      },
      headerButtons: {
        display:"flex",
        flexWrap:"nowrap",
        justifyContent:"center",
        alignItems:"center",
        outline: "none",
        padding: "16px 2vw",
        // width:"207px",
        // outerHeight:"100%",
        margin: "0",
        border: "none",
        backgroundColor: "inherit",
        transitionDuration:"0.5s",
        // transitionProperty:"backgroundColor",
    
        color:"#5A5959",
        fontSize:  "calc(8px + (26 - 14) * ((100vw - 300px) / (1600 - 300)))",
            lineHeight:  "calc(1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)))",
        // boxShadow: "0 0 14px -2 black",
        '&:hover': {
          // transitionProperty:"backgroundColor",
            color:"#9F1A1E",
            // boxShadow: "0 0 7px -4px black"
        }
    },
    clickedHeaderButton: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
      outline: "none",
      padding: "16px 2vw",
      margin: "0",
      color: "#FF6565",
      backgroundColor: "inherit",
      transitionDuration:"0.5s",
      border: "none",
      borderBottom: "1px solid #FF6565",
      fontSize:  "calc(8px + (26 - 14) * ((100vw - 300px) / (1600 - 300)))",
            lineHeight:  "calc(1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)))",
      '&:hover': {
          backgroundColor: "#FF6565",
          color: "#fff",
      }
    },
    menuItem:{
        padding:"12px 30px",
        color:"#727272",
        transitionDuration:"0.3s",
        "&:hover":{
            color:"#9F1A1E",
        }
    }
}))

export default function MenuListComposition(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const popperRef = React.useRef()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOpen =()=>{
    setOpen(true);
  }

  const handleCloseOnLeave = () => {
    setOpen(false);
  };


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <div className={classes.root}>
      
      
        <button
          className={props.classNameprop}
          onMouseEnter={handleOpen}
          onMouseLeave={handleCloseOnLeave}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          style={{padding:"16px 18px", whiteSpace:"nowrap"}}
          onClick={()=>{window.scrollTo(0,0); handleToggle()}} 
          // variant="contained"
        >
             {!!props.button && !!props.button[props.flag] && props.button[props.flag] }
        </button>
        <Popper
        onMouseEnter={handleOpen}
        onMouseLeave={handleCloseOnLeave}
        open={open} ref={popperRef} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              timeout={100}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper style={{ borderRadius:"0px"}}>
                <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {props.children && props.children.length && props.children.map(child=>{
                        return <Link style={{ textDecoration:"none", color:"#000"}} to={child.path}>
                        <MenuItem 
                        className={classes.menuItem}
                        onClick={(e)=>{ handleClose(e); }}
                        > {child.button[props.flag]}</MenuItem>
                        </Link>
                    })}
                  
                    
                  </MenuList>
            
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
  );
}
