/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import styles from "../../style.module.css"
const HeadphonesComponent = ({ svgRef }) => (
  <svg className={styles.a} viewBox="0 0 46.001 52.621" ref={svgRef}>
    <g transform="translate(-26.48)">
      <g transform="translate(26.48)">
        <path
        className={styles.a}
          d="M67.481,18a18,18,0,0,0-36,0c-2.57.32-5,2-5,5.9v9.4c0,4.4,3.08,6,6,6h2.9a1,1,0,0,0,1-1V18.94a1,1,0,0,0-1-1h-1.9a16,16,0,0,1,32,0h-1.9a1,1,0,0,0-1,1v19.33a1,1,0,0,0,1,1h1.9c-.28,6.87-3.58,8.48-10.17,8.77v-.67a2,2,0,0,0-2-2H47.02a2,2,0,0,0-2,2v3.25a2,2,0,0,0,2,2h6.31a2,2,0,0,0,2-2v-.58c6.34-.29,11.82-1.6,12.15-10.83,2.57-.32,5-2,5-5.9v-9.4C72.481,19.99,70.061,18.32,67.481,18Zm-33.1,1.87v17.4H32.43c-1.48,0-4-.52-4-4v-9.4c0-3.45,2.49-4,4-4Zm18.95,29.241v1.52H47.02v-3.25h6.31ZM70.481,33.3c0,3.45-2.49,4-4,4h-1.9V19.94h1.95c1.48,0,4,.52,4,4Z"
          transform="translate(-26.48)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <HeadphonesComponent svgRef={ref} {...props} />
));
export default ForwardRef;
