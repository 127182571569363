import React from 'react';
import {styles} from './styleBodyMain'
import ShowCategories from "./MainPageProducts/ShowProductsfive";
import Categories4 from "./categories4" 
import ShowBrands from "./ShowBrands"
import LatestNews from "./latestNews"
import SubFooter from './subFooterContacts';
import style from "./bodyMain.module.css"
import ServicesMain from "./ServicesMain/ServicesMain.js"







export default function BodyMain(props){
    const classes = styles()
    return <section className={classes.section}>
        <div style={{backgroundColor: "#fafafa"}} >
        <Categories4 flag={props.flag}/>
        <ServicesMain flag={props.flag}/>
        <ShowCategories flag={props.flag} />
        <ShowBrands flag={props.flag} />
        <LatestNews flag={props.flag} />
        </div>
        {/* <div style={{backgroundColor:"#1B233C", height:"fit-content"}} >
        <SubFooter flag={props.flag} /> 
        </div> */}
     </section>
}