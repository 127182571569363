/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounFilterComponent = ({ svgRef }) => (
  <svg width={17} height={17} viewBox="0 0 17.4 17.4" ref={svgRef}>
    <defs>
      <style>{".edcvfr{stroke:#000;stroke-width:0.4px;}"}</style>
    </defs>
    <g transform="translate(0.2 0.2)">
      <path
        className="edcvfr"
        d="M9.109,12.164,5.047,16.226V.266a.266.266,0,0,0-.531,0v15.96L.454,12.164a.266.266,0,0,0-.376.376l4.383,4.383A.265.265,0,0,0,4.649,17h.266a.265.265,0,0,0,.188-.078l4.383-4.383a.266.266,0,0,0-.376-.376Z"
        transform="translate(0)"
      />
      <path
        className="edcvfr"
        d="M65.3,0H56.266a.266.266,0,0,0,0,.531H65.3A.266.266,0,1,0,65.3,0Z"
        transform="translate(-48.562)"
      />
      <path
        className="edcvfr"
        d="M72.234,25H64.266a.266.266,0,0,0,0,.531h7.969a.266.266,0,0,0,0-.531Z"
        transform="translate(-55.5 -21.68)"
      />
      <path
        className="edcvfr"
        d="M79.172,49H72.266a.266.266,0,0,0,0,.531h6.906a.266.266,0,0,0,0-.531Z"
        transform="translate(-62.437 -42.492)"
      />
      <path
        className="edcvfr"
        d="M86.109,74H80.266a.266.266,0,0,0,0,.531h5.844a.266.266,0,0,0,0-.531Z"
        transform="translate(-69.375 -64.172)"
      />
      <path
        className="edcvfr"
        d="M93.047,99H88.266a.266.266,0,0,0,0,.531h4.781a.266.266,0,0,0,0-.531Z"
        transform="translate(-76.312 -85.852)"
      />
      <path
        className="edcvfr"
        d="M99.984,124H96.266a.266.266,0,0,0,0,.531h3.719a.266.266,0,0,0,0-.531Z"
        transform="translate(-83.25 -107.531)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounFilterComponent svgRef={ref} {...props} />
));
export default ForwardRef;
