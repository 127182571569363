import  {makeStyles} from '@material-ui/core/styles';



export const styles = makeStyles(theme => ({
    section:{
        width: "100%",
        height: "fit-content",
        color: "grey",
        margin:"20px 0"
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop:"20px",
        textTransform:"capitalize",
        fontSize:"32px",
        
      },
      headname:{
        color:"#000",
        // borderBottom:"4px solid #F8C473",
        // paddingBottom:"8px",
        // marginBottom:"46px",
      },
    sectionHaeder:{
        // fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        fontSize:"23px",
        color:"black",
        // fontWeight: 400,
        letterSpacing: "0.04px",
        
    },
    header:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        width: "100%",
        padding: "20 0",
    },
    prevNextbutton:{
        width: "32px",
        height: "32px",
        fontSize:"5px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor:"inherit",
        border:"1px solid rgba(168, 168, 168, 0.3)",
        color:"rgba(168,168,168,0.8)",
        // marginLeft: "20px",
        transitionDuration: "0.5s",
        "&:hover":{
            backgroundColor:"#FF3B3B",
            color:"white",
        },
    },

    prevNextbuttonContainer:{
        width:"76px",
        height:"35px",
        display:"flex",
        justifyContent:"space-between",
        flexWrap:"none",
    },
    button:{
        // outline: "none &:!important",
        // outlineOffset: "none &:!important",
        color: "grey",
        padding: "10px 50px",
        fontSize:"16px",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid lightGrey",
        backgroundColor:"inherit",
        transitionDuration: "0.5s",
        "&:hover":{
            backgroundColor:"lightGrey",

        }
    },
    activeButton:{
        borderBottom: "2px solid red",
    },
    // header:{
    //     display:"flex",
    //     flexWrap: "wrap",
    //     width: "100%",
    //     padding: "20 0",
    // },
    products:{
        width: "100%",
        height: "fit-content",
        margin: "10px 0",
        display:"flex",
        justifyContent:"space-between",
        flexWrap: "wrap",
        overflowX: "hidden",
        overflowY:"hidden",
    },
    // Muibutton:{
    //     root:{
    //         margin: 0,
    //         padding: 0
    //     },
    // },
    card:{
        position: "relative",
        cursor:"pointer",
        width: "226px",
        height: "226px",
        // backgroundColor:"lightgrey",
        border:"0.1px solid lightgrey",
        boxSizing:"border-box",
        display: "flex",
        justifyContent: "space-between",
        alignItems:"center",
        flexDirection:"column",
        transitionDuration: "0.5s",

        "&:hover":{
            // backgroundColor:"lightGrey",
            boxShadow: "0px 0px 10px -1px rgba(0,0,0,0.16)"
        }
    },
    imgcontainer:{
        height:"140px",
        display: "flex",
        justifyContent: "space-between",
        alignItems:"center",
    },
    cardBrand:{
        position: "relative",
        cursor:"pointer",
        width: "150px",
        height: "150px",
        margin:"2px",
        // backgroundColor:"lightgrey",
        // border:"0.1px solid lightgrey",
        boxSizing:"border-box",
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        flexDirection:"column",
        transitionDuration: "0.5s",
        opacity: "0.5",
        "&:hover":{
            // backgroundColor:"lightGrey",
            // boxShadow: "0px 0px 10px -1px rgba(0,0,0,0.55)"
            opacity: "1",
        }
    },
    cardImg:{
        width:"100%",
    },
  
    cardBadge:{
        position: "absolute",
        top: "0",
        right: "0",
        width: "100px"
    },
    productName:{ 
        fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        color: "#FB1F1F",
        // fontWeight: "bold"
    },
    dividerDiv: {
        borderBottom: "1px solid rgba(165,163,163,0.3)",
        width: "1000px",
        height: "50%",
        margin:"0px 19.5px"
    
      },
}))