import  {makeStyles} from '@material-ui/core/styles';


export const styles = makeStyles(theme => ({
    section: {
        padding: "0",
        margin: "0 auto",
        height: "auto",
        maxWidth: "1366px",

    },
      Image:{
          margin:0,
          padding: 0,
        
       },
       textAM:{
        fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
       },
       
    
    
}))