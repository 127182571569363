import React, { useState, useEffect } from 'react';
import loadData from "../../helpers/loadData"
import Card from "./card_for_brand"
import { styles } from "./styleShowCategories"
import headerStyle from "./categories5.module.css"
import SwipeableViews from 'react-swipeable-views';
import "./ShowBrands.css"
import NavigateNextIcon from './main page icons/rightIcon/PathComponent';
import NavigateBeforeIcon from './main page icons/lefticon/PathComponent';
import allstyles from "./MainPageProducts/ShowProductsfive.module.css";
import langsStyle from '../../stylesForLangs/langs.module.css'
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import ArrowRight from "./newsCardicons/arrow/PathComponent"
import {Names} from "../../staticTexts"
import langs from "../../stylesForLangs/langs.module.css"
import allstylesBrands from "./ShowBrands.module.css"
import Shine from '../shineButton/shineButton';


export default function ScrollableTabsButtonAuto(props) {
    const classes = styles();
    const [index, setIndex] = useState(0);
    const [brands, setBrands] = useState([])
    const allbrands = useSelector(state => state.brands)
    const [count, setCount] = useState(3)

    useEffect(() => {
        loadData("Categories", JSON.stringify({ type: "brand" })).then((allbrands) => {
            setBrands(renderAllBrands(allbrands, resize()))

        })
        return ()=>{
            window.removeEventListener("resize", resize)
        }
    }, [])

    useEffect(() => {
        setBrands(renderAllBrands(allbrands, count))
        resize()
    }, [count, allbrands])

    function resize() {
        if (window.innerWidth <= 600) {
            setCount(6)
            return 6
        } else if (window.innerWidth < 1300) {
            setCount(8)
            return 8
        } else if (window.innerWidth > 1300 && window.innerWidth < 1920) {
            setCount(8)
            return 8
        } else if (window.innerWidth >= 1800) {
            setCount(12)
            return 12
        }
    }
    window.addEventListener('resize', resize)


    const renderAllBrands = (brand, n = 6) => {
        if (!brand.length) { return }
        let count = 0
        let render = [[]];
        for (let i = 0; i <= brand.length; i++) {
            if (render[count] && render[count].length === n) {
                count += 1;
            }
            if (!Array.isArray(render[count])) {
                render[count] = [];
            }
            brand[i] && render[count].push(brand[i]);
        }
        return render;
    }


    function indexDown() {
        if (index - 1 < 0) {
            return
        }
        setIndex(index - 1);
    }
    function indexUp() {
        if (!brands[index + 1]) {
            return
        }
        setIndex(index + 1)
    }

    return (
        <section className={"MainPagebrandssection"}>

            {/* <div className={classes.header}>
            </div> */}
      {/* <div className={` ${classes.head} `}>
        <span className={classes.headname}>
        {Names.brands[props.flag]}
        </span>
      </div> */}
            <div className={"MainPagebrandsContainer"}>
            {/* <div className="navscont" >
            <div onClick={() => { if (index - 1 >= 0) { setIndex(index - 1) } }} className={`navscontchild rightrot`} >
                <ArrowRight  />
            </div> */}
            {/* </div> */}
            <div className={` ${allstylesBrands.head} `}>
                <span className={`${allstylesBrands.headname} ${ props.flag === "am" ? langs.DejaVuSansBold : langs.MontserratExtraBold} headerFont`}>
                    {Names.brands[props.flag]}
                </span>
                <Shine name={Names.viewall[props.flag]} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SwipeableViews index={index} onChangeIndex={(i)=>{setIndex(i)}} disableLazyLoading enableMouseEvents 
                slideStyle={{ overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }} >
                {brands.map((brand, i) => {
                    return<div key={i} className={"container_more_brands"}> <Grid container spacing={2}> 
                    {/* <div key={i} className={"container_more_brands"}> */}
                        {brand.map((br, i) => {
                            return <Grid container justify="center" alignItems="center" item sm={3} md={3} xs={6}> 
                            <Link style={{width:"100%", height:"100%"}} to = {{pathname:"/products", brandId: br.id}}>
                                <Card key={i} mainpage={true} flag={props.flag} brand={br} />
                            </Link>
                        </Grid>
                        })}
                    </Grid>
                    </div>
                })}
                </SwipeableViews>
                </div>
                {/* </AutoPlaySwipeableViews> */}
                {/* <div className="navscont">
                    <div onClick={() => { if (index + 1 < brands.length) { setIndex(index + 1) } }} className="navscontchild" >
                        <ArrowRight />
                    </div>
                </div> */}
            </div>
            {/* <div className={"DownButtonsContainer"}>
                <span onClick={() => { if (index - 1 >= 0) { setIndex(index - 1) } }}  className={`${allstyles.arrowButtons} ${allstyles.arrowLeft} rightrot navscontchild`}>
                  <ArrowRight /> 
                </span>
                {brands.map((item, i) => {
                    return <span key={i+"200"} className={index === i ? "downButtonsClicked" : "downButtons"} onClick={() => { setIndex(i) }}  ></span>
                })}
                
                <span onClick={() => { if (index + 1 < brands.length) { setIndex(index + 1) } }}  className={`${allstyles.arrowButtons} ${allstyles.arrowRight} navscontchild`}>
                    <ArrowRight /> 
                  </span>

            </div> */}
        </section>
    )
}

