/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import styles from "../../style.module.css"
const NounDownloadComponent = ({ svgRef }) => (
  <svg className={styles.a} viewBox="0 0 49.461 52.621" ref={svgRef}>
    <g transform="translate(-16.862 -114)">
      <g transform="translate(16.862 114)">
        <path
        className={styles.a}
          d="M132.976,176h15.811v1.673H133.645l-4.434,26.018h11.712v3.848a2.32,2.32,0,0,0,2.091,2.091H161a2.32,2.32,0,0,0,2.091-2.091v-2.175h-.084v-1.673h11.8l-3.848-26.018H155.4V176h16.983c1.088,7.278,3.263,22.588,4.267,28.444.084,3.765,0,5.27,0,7.529a11.569,11.569,0,0,1-11.461,11.461H138.832a11.569,11.569,0,0,1-11.461-11.461c0-2.175-.335-5.605,0-7.529,1.59-8.784,3.681-21.668,4.852-28.444Zm6.274,29.364H129.044v6.609a9.412,9.412,0,0,0,2.928,6.86,9.748,9.748,0,0,0,6.86,2.928h26.352a9.748,9.748,0,0,0,6.86-2.928,9.412,9.412,0,0,0,2.928-6.86v-6.609H164.766v2.175A4.026,4.026,0,0,1,161,211.3H143.015a3.67,3.67,0,0,1-2.593-1.088,3.748,3.748,0,0,1-1.171-2.677Z"
          transform="translate(-127.222 -170.813)"
        />
        <path
        className={styles.a}
          d="M354.278,114l-.335,25.516,2.761-2.593h2.426l-1.924,1.757-4.183,3.932L347,136.922h2.426l2.844,2.677.335-25.6Z"
          transform="translate(-328.614 -114)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounDownloadComponent svgRef={ref} {...props} />
));
export default ForwardRef;
