// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* .breadcrumbs{\n    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);\n    -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);\n    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2)\n} */\n.ShowProducts_filtersContainer__1KbbT{\n    display: flex;\n    align-items: flex-start;\n    flex-direction: column;\n    position: relative;\n    height:100%;\n    background-color: inherit;\n    box-shadow: 0px 4px 4px 0px #000000 6%;\n    padding: 2px 12px;\n    width: 281px;\n}\n\n\n.ShowProducts_filters__1r1fG{\n    min-height: 500px; \n    margin: 0 auto; \n    padding: 0px 25px; \n    display: flex; \n    /* margin-top: 15px  */\n}\n.ShowProducts_products__1Z_gX{\n display: none;   \n}\n.ShowProducts_breadcrumbscont__1RdPh{\n    max-width: 1366px; \n    display: flex;\n    align-items:center; \n    justify-content: space-between; \n    margin: 0 auto; \n    padding: 0px 25px\n}\n\n\n@media only screen and (max-width:1280px){\n    .ShowProducts_breadcrumbs__3Joig{\n        \n    }\n    .ShowProducts_breadcrumbscont__1RdPh{ \n        padding: 0px 16px\n    }\n    .ShowProducts_filters__1r1fG{\n        display: none;\n    }\n    .ShowProducts_products__1Z_gX{\n        display: block;\n        padding:0px 16px ;\n        margin-bottom:100px ;\n    }\n}\n@media only screen and (max-width:600px){\n    .ShowProducts_breadcrumbs__3Joig{\n        display: flex;   \n    }\n    .ShowProducts_breadcrumbscont__1RdPh{\n        max-width: 1366px; \n        display: flex;\n        align-items:center; \n        justify-content: space-between; \n        margin: 0; \n        padding: 85px 16px 0px\n    }\n    .ShowProducts_products__1Z_gX{\n        display: block;\n        padding:0px 16px 0px ;\n        margin-bottom:0px ;\n    }\n}", ""]);
// Exports
exports.locals = {
	"filtersContainer": "ShowProducts_filtersContainer__1KbbT",
	"filters": "ShowProducts_filters__1r1fG",
	"products": "ShowProducts_products__1Z_gX",
	"breadcrumbscont": "ShowProducts_breadcrumbscont__1RdPh",
	"breadcrumbs": "ShowProducts_breadcrumbs__3Joig"
};
module.exports = exports;
