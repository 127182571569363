import React, { useState, useEffect } from "react";
import loadData from "../../helpers/loadData";
import { styles } from "./styleShowCategories";
import News from "../news/news";
import "./ShowBrands.css";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import langsStyle from "../../stylesForLangs/langs.module.css";
import { useSelector } from "react-redux";
import { Names } from "../../staticTexts";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(1),
  },
  height: {
    height: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "wrap",
    marginBottom: theme.spacing(1),
    height: "100%",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "65px",
    paddingTop: "40px",
    textTransform: "capitalize",
    fontSize: "32px",
  },
  headname: {
    color: "#31465A",
    borderBottom: "4px solid #F8C473",
    paddingBottom: "8px",
  },
  dividerDiv: {
    borderBottom: "1px solid rgba(165, 163, 163, 0.3)",
    width: "70%",
    height: "50%",
  },
  head:{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"16px 0px"

},
headname:{
    fontSize: "30px",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
},
headerDivider:{
    width: "100%",
    marginLeft: "27px",
    height: "49%",
    borderBottom: "1px solid rgba(112,112,122,0.2)",
},

  buttonViewMore: {
    // fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    transitionDuration: "0.5s",
    backgroundColor: "#FE5959",
    padding: "10px 20px",
    // marginBottom: "15px",
    width: "161px",
    height: "43px",
    border: "none",
    color: "white",
    fontSize: "14px",
  },
  sectionHaeder: {
    marginBottom: "65px",
    marginTop: "40px",
    textTransform: "capitalize",
    fontSize: "32px",
    // fontWeight: "600"
  },
  text: {
    fontSize: "24px",
    fontWeight: "500",
  },
  edit: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const headClasses = useStyles();
  const [index, setIndex] = useState(0);
  const [allNews, setAllNews] = useState([]);
  const [count, setCount] = useState();
  const allNewsRedux = useSelector((state) => state.news);

  // useEffect(() => {
  //     loadData("Categories", JSON.stringify({ type: "brand" })).then((allbrands) => {
  //         setBrands(allbrands)
  //     })
  // }, [])
  useEffect(() => {
    let mounted = true;
    loadData("news", JSON.stringify({ type: "news" })).then((data) => {
      if (mounted) {
        setAllNews(renderAllBrands(data));
        resize();
      }
    });
    return () => {
      mounted = false;
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    setAllNews(renderAllBrands(allNewsRedux, count));
  }, [count, allNewsRedux]);

  function resize() {
    if (window.innerWidth < 600) {
      setCount(1);
    } else if (window.innerWidth < 1300) {
      setCount(3);
    } else if (window.innerWidth > 1300 && window.innerWidth < 1920) {
      setCount(3);
    } else if (window.innerWidth >= 1920) {
      setCount(3);
    }
  }
  window.addEventListener("resize", resize);

  const renderAllBrands = (brand, n = 2) => {
    if (!brand.length) {
      return;
    }
    let count = 0;
    let render = [[]];
    for (let i = 0; i <= brand.length; i++) {
      if (render[count] && render[count].length === n) {
        count += 1;
      }
      if (!Array.isArray(render[count])) {
        render[count] = [];
      }
      brand[i] && render[count].push(brand[i]);
    }
    render = render.filter((item) => item.length);
    return render;
  };

  return (
    <section
      style={{ backgroundColor: "#fff", paddingBottom:"100px" }}
    >
      <div className={"MainPageNewsContainer"}>
        <News allNews={allNews} index={index} setIndex={setIndex} />
      </div>
    </section>
  );
}
