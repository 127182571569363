/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounSearchComponent = ({ svgRef }) => (
  <svg width={20} height={20} viewBox="0 0 13.317 13.343" ref={svgRef}>
    <defs>
      <style>{".asearch{fill:#1B1B1B;}"}</style>
    </defs>
    <g transform="translate(-6 -6)">
      <g transform="translate(6 6)">
        <path
          className="asearch"
          d="M17.232,15.771a1.053,1.053,0,0,0-1.225-.177l-.487-.487a5.474,5.474,0,1,0-.413.413l.487.487a1.056,1.056,0,0,0,.177,1.225l1.8,1.8a1.033,1.033,0,0,0,.723.31,1.007,1.007,0,0,0,.723-.31,1.042,1.042,0,0,0,0-1.461Zm-5.771.561a4.871,4.871,0,1,1,4.871-4.871A4.876,4.876,0,0,1,11.461,16.332Z"
          transform="translate(-6 -6)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounSearchComponent svgRef={ref} {...props} />
));
export default ForwardRef;
