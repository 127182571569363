import  {makeStyles} from '@material-ui/core/styles';



export const styles = makeStyles(theme => ({
    section:{
        width: "80vw",
        minHeight: "320px",
        color: "grey",
        margin:"0 auto",
        // boxShadow:"0 0 10px black",
        // border:"0.1px solid grey",
        padding:"10px",
    },
    sectionHaeder:{
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        fontSize:"23px",
        color:"black"
    },
    header:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        width: "100%",
        padding: "20px 10px",
    },
    prevNextbutton:{
        border: "none",
        marginLeft: "20px",
        transitionDuration: "0.5s",
        "&:hover":{
            backgroundColor:"red",
            // boxShadow: "2px 2px 2px black",
            // -webkit-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.55);
            // -moz-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.55);
            boxShadow: "0px 0px 20px -1px rgba(0,0,0,0.55)"
            
        }
    },
    button:{
        // outline: "none &:!important",
        // outlineOffset: "none &:!important",
        color: "grey",
        padding: "10px 50px",
        fontSize:"16px",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid lightGrey",
        backgroundColor:"inherit",
        transitionDuration: "0.5s",
        "&:hover":{
            backgroundColor:"lightGrey",

        }
    },
    activeButton:{
        borderBottom: "2px solid red",
    },
    // header:{
    //     display:"flex",
    //     flexWrap: "wrap",
    //     width: "100%",
    //     padding: "20 0",
    // },
    products:{
        width: "100%",
        height: "300px",
        padding: "10px 0",
        display:"flex",
        flexWrap: "wrap",
        // overflowX: "hidden",
        // overflowY:"hidden",
    },
    // Muibutton:{
    //     root:{
    //         margin: 0,
    //         padding: 0
    //     },
    // },
    card:{
        position: "relative",
        cursor:"pointer",
        width: "100%",
        height: "100%",
        // backgroundColor:"lightgrey",
        border:"0.1px solid lightgrey",
        boxSizing:"border-box",
        display: "flex",
        justifyContent: "space-between",
        alignItems:"center",
        flexDirection:"column",
        transitionDuration: "0.5s",

        "&:hover":{
            // backgroundColor:"lightGrey",
            boxShadow: "0px 0px 10px -1px rgba(0,0,0,0.55)"
        }
    },
    imgcontainer:{
        height:"100%",
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
    },
    selectedIcon:{
        position:"absolute",
        top:"0",
        left:"0",
      },
    cardBrand:{
        width: "250px",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        flexDirection:"column",
        transitionDuration: "0.5s",

        "&:hover":{
            // backgroundColor:"lightGrey",
            boxShadow: "0px 0px 10px -1px rgba(0,0,0,0.55)"
        }
    },
    cardImg:{
        width:"150px",
    },
    cardBadge:{
        position: "absolute",
        top: "0",
        right: "0",
        width: "100px"
    },
    productName:{ 
        fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        color: "#FB1F1F",
        // fontWeight: "bold"
    },
    dividerDiv: {
        borderBottom: "1px solid gray",
        width: "80%",
        height: "50%",
    
      },

      buttonNavLeft: {
        position: "absolute",
        top: "70px",
        left: "0px",
        border: "none",
        padding: "40px 10px",
        transitionDuration: "0.3s",
        zIndex:"10000",
        opacity:"0.7",
        outline:"none",
        "&:hover":{
            backgroundColor: "#878787",
            color:"#fff"
        }
    },
    buttonNavRight: {
        position: "absolute",
        top: "70px",
        right: "0px",
        border: "none",
        padding: "40px 10px",
        transitionDuration: "0.3s",
        zIndex:"10000",
        opacity:"0.7",
        outline:"none",
        "&:hover":{
            backgroundColor: "#878787",
            color:"#fff"
        }
    }

      
}))