import React, { useEffect, useState } from 'react';
import tableStyle from "./table.module.css"
// import { BsDownload } from "react-icons/bs"
import BsDownload from "../Downloads/icons/downloadIcon/NounDownloadComponent"
import { IconButton } from '@material-ui/core'
import NoResults from "../ShowProducts/noProductsFound"
import langs from "../../stylesForLangs/langs.module.css"
import { Names } from '../../staticTexts';
import { getSubArrs } from "../../helpers/Functions"
import SwipeableViews from 'react-swipeable-views';
import ChevronRightIcon from "../ShowProducts/icons/arrowright icon/PathComponent"
import RemoveIcon from '@material-ui/icons/Remove';
import { PaginationOutlined } from '../../helpers/helperComponents/helperComponents';

export default function SimpleTable(props) {
  const [pagesDownload, setPagesDownloads] = useState([])
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if(props.table!=="initial" && Array.isArray(props.table)){
    let arr = JSON.parse(JSON.stringify(props.table))
    arr = arr.filter(item => item.name && item.url)
    setPagesDownloads(getSubArrs(arr, 13))
    }
  }, [props])


  function getUploadDate(row){
    if(row.date){
      return typeof (row.date) === "string" ? new Date(+row.date).toLocaleDateString() : typeof (row.date) !== "string" ? new Date(row.date).toLocaleDateString() : "undefined"
    }else{
      return <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} ><RemoveIcon/></div>
    }
  }

  return <div className={tableStyle.margin}>


    {/* <div style={{width:"100%", backgroundColor:"blue"}}> */}
    <SwipeableViews index={index} onChangeIndex={(index) => { setIndex(index) }} disableLazyLoading enableMouseEvents slideStyle={{ overflow: "hidden" }} style={{ width: "100%" }} >
      { props.table ==="initial" ?
      <div 
        style={{
          width:"100%",
          height:"20vh",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          fontSize: "2.3vw",
          color: "#31465A",
        }}
      >{Names.Select_Product_to_show_Results[props.flag]}</div>
      :
      Array.isArray(pagesDownload) && pagesDownload.map((subarr, i) => {
        return <>
          {pagesDownload[0].length ? <div className={tableStyle.table} >
            {subarr.map((row, i) => {
              if (row.name && row.url) {
                return <div className={tableStyle.tr} key={i}>
                  <div className={tableStyle.thContiner} >
                    <div className={`${tableStyle.th} ${props.flag === "am" ? langs.armenianHeaders : langs.foreignHeaders}`} style={{ position: "relative" }} >
                    <span className={tableStyle.textDownload}>
                        {row.name}
                      </span>
                      <span className={`${tableStyle.downloadIconOnName} ${tableStyle.iconDownload}`}  >
                        <IconButton color="primary" onClick={() => { window.open(row.url) }} className={tableStyle.iconbutton}>
                          <BsDownload style={{ fontSize: "20px", color: "#FF5858" }} />
                        </IconButton>
                      </span>
                    </div>
                    <div className={`${tableStyle.th} ${props.flag === "am" ? langs.armenianHeaders : langs.foreignHeaders}`} style={{ position: "relative" }} >
                      
                      <span className={tableStyle.textDownload}>
                        {getUploadDate(row)}
                      </span>
                      <span className={`${tableStyle.downloadIcon} ${tableStyle.iconDownload}`}  >
                        <IconButton color="primary" onClick={() => { window.open(row.url) }} className={tableStyle.iconbutton}>
                          <BsDownload style={{ fontSize: "20px", color: "#FF5858" }} />
                        </IconButton>
                      </span>
                    </div>
                  </div>
                </div>
              
              }
              else {
                return null
              }

            })}
          </div>
            :
            <div style={{ marginTop: "-72px" }}> <NoResults flag={props.flag} /></div>
          }
        </>
      })}
    </SwipeableViews>
    {/* </div> */}
    {/* {pagesDownload && pagesDownload.length > 1 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PaginationOutlined
            productsPages={pagesDownload}
            page={index}
            setPage={setIndex}
          />
        </div>
      )} */}
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }} >
      {pagesDownload.length > 1 && pagesDownload.length <= 3 ? pagesDownload.map((_, i) => {
        return <button onClick={() => { setIndex(i); }} className={index === i ? 'pagebutton12Clicked' : 'pagebutton12'}>
          {i + 1}
        </button>
      })
        :
        pagesDownload.length > 3 ?
          <>
            {<button onClick={() => { index - 1 >= 0 && setIndex(index - 1) }} className={`${index === 0 ?  'pagebutton12Disabled rot180' : 'pagebutton12 rot180'}`}>
              <ChevronRightIcon />
            </button>}
            {index-1<=pagesDownload.length && index===pagesDownload.length-1 && <button onClick={() => { setIndex(index - 2);  }} className={'pagebutton12'}>
              {index-1}
            </button>}
            {!!index && <button onClick={() => { setIndex(index - 1);  }} className={'pagebutton12'}>
              {index}
            </button>}
            <button className={'pagebutton12Clicked'}>
              {index + 1}
            </button>
            {index + 1 < pagesDownload.length && <button onClick={() => { setIndex(index + 1); }} className={'pagebutton12'}>
              {index + 2}
            </button>}
            {!index && <button onClick={() => { setIndex(index + 2);  }} className={'pagebutton12'}>
              {index + 3}
            </button>}
            {<button onClick={() => {index + 1 <= pagesDownload.length - 1 &&  setIndex(index + 1) }} className={`${index === pagesDownload.length - 1 ?  'pagebutton12Disabled' : 'pagebutton12'}`}>
              <ChevronRightIcon fontSize="small" />
            </button>}
          </>
          :
          null
      }
    </div>
  </div>
}