import React, {useEffect,useState,useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import style from "./categories5.module.css"
import { CircleLoader } from 'react-spinners';
// import langsStyle from '../../stylesForLangs/Names.module.css'
import {Names} from "../../staticTexts"
import { useSelector } from 'react-redux';
import ShineButton from "../shineButton/shineButton"
import {useTransition, animated} from "react-spring"
import {Link} from "react-router-dom"
import langs from "../../stylesForLangs/langs.module.css"
export default function CSSGrid(props) {

  const [isFetched, setisFetched] = useState(false)
  const [categories, setCategories] = useState([])
  const flag = useSelector(s=>s.flag)
  const categoriesFromRedux = useSelector(s=>s.categories)
  const imgRef=useRef([]);
  const transitions = useTransition(categories, item => item.id, {
    from: { opacity:0 },
    enter: { opacity:1 },
    leave: { opacity:0 },
    })

  useEffect(()=>{
      if(categoriesFromRedux){
    fetch(`${process.env.REACT_APP_API_URL}/category5`,{
      credentials: 'include',
    }).then(data=>{
      return data.json()
    }).then(data=>{
        data = data.sort((a,b)=>a.id-b.id)
        let mainCategories = data.map(item=>{
            let subcategories=item.subcategories.map(it=>{ return categoriesFromRedux.filter(itemc=>itemc.id===it)[0]});
            return {
                id:item.id,
                path:categoriesFromRedux.filter(itemc=>itemc.id===item.path)[0],
                subcategories:subcategories
            }
        })
        categoriesFromRedux.filter(item=>data.map(i=>i.path).includes(item.id))

      setCategories(mainCategories);
    //   setSubCategories(subs)
      setisFetched(true)
    })
    .catch((err)=>{
    console.log("data SUBS", err)
    })
}
  },[categoriesFromRedux])

  // useEffect(()=>{
  //   if(!!indexOpacity){ 
  //    intId = setTimeout(()=>{
  //     setIndexOpacity(true)
  //    },100) 
  //   }
  // },[indexOpacity])


  function handleChildHover(index, img){
    // setIndexOpacity(false)
    imgRef.current[index].src=img
  }

  
  function handleMouseLeave(index, img){
    imgRef.current[index].src=img
  }

  return <div style={{backgroundColor:"#fafafa", paddingBottom:"70px"}}>
      <div style={{maxWidth:"1286px", margin:"0 auto", padding:"18px 25px"}}>
      <div className={` ${style.head} ${flag}M`}>
        <span className={`${style.headname}`}>
          {Names.category[flag]}
        </span>
      </div>

      {isFetched ?  <Grid container spacing={3}>
            {categories.length && transitions.map(({item, props, key}, i)=>{
              return<Grid  item lg={4} md={4} sm={6} xs={12}> 
              <div key = {key} className={style.container} >
                <div style={{width:"40%"}} className={style.mainImg} >
                      <animated.img style={props} className={style.imgs} ref={el => imgRef.current[i] = el} height="100%" alt="category" src={`${process.env.REACT_APP_API_URL}/originals/${item && item.path && item.path.Image}`} />
                </div>
                <div className={style.MainCategoryWithChildContainer}>
                <span className={`  ${`  ${style.text}`}`  } variant="h4">
                    { item.path && !!item.path.name[flag] &&
                    <Link style={{textDecoration:"none", color:"inherit"}} to={{pathname: "/products", state: categories[i].path.id}}>
                      <span className={`${style.headerName} ${flag}M`}>
                        {item.path.name[flag]}
                      </span>
                    </Link>}
                  <div  className={style.categoryChilds}>
                      {!!item.subcategories && !!item.subcategories.length && item.subcategories.map(items=>
                      <Link style={{textDecoration:"none", color:"inherit"}} to={{pathname: "/products", state: items && items.id}}>
                        <div 
                          onMouseLeave={()=>handleMouseLeave(i, `${process.env.REACT_APP_API_URL}/originals/${item.path.Image}`)} 
                          onMouseEnter={()=>{handleChildHover(i,`${process.env.REACT_APP_API_URL}/originals/${items && items.Image}`)}} 
                          className={` ${flag}R ${style.child}`}>{items && items.name && items.name[flag]} 
                        </div>
                      </Link>
                      )}
                  </div>
                  </span>
                  <Link to={{pathname: "/products", state: categories[i].path && categories[i].path.id}}>
                    <ShineButton name={Names.viewall[flag]} />
                  </Link>
                </div>
                </div>
            </Grid>})}
      </Grid>
      :
      <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}} >
       <CircleLoader color={"#9F1A1E"}/>
      </div>
      
    }
      {/* <Divider className={classes.divider} /> */}
</div>
    </div>
}
