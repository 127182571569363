import React from 'react';
import style from "./customers.module.css"


export default function OutlinedCard(props) {
    const { brand } = props;
    return (
        <div className={style.cardBrandsMain}>
            <img className={style.cardImgBrand} alt="product" src={`${process.env.REACT_APP_IMAGES_URL}/${brand.Image}`} />
        </div>
    )
}