import React, { useState, useEffect } from "react";
import { styles } from "./styleShowProducts";
import Grid from "@material-ui/core/Grid";
import CategoriesList from "./categoriesList copy";
import ShowProductsGrid from "./ShowProductsGrid";
import BrandsList from "./BrandsList";
import BadgesList from "./BadgesList";
import LastSeen from "../productInfo/lastSeenProducts1";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import allStyles from "./ShowProducts.module.css";
import { Names } from "../../staticTexts";
import { MakeBreadcrumbs } from "../../helpers/helperComponents/helperComponents";
import Search from "./categories icons/search/NounSearchComponent";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Banner from "../banner/banner";
import StickyBox from "react-sticky-box/dist/esnext";
import { getParents } from "../../helpers/Functions";
import { useSelector } from "react-redux";

const theme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "xxs", "sm", "md", "lg", "xl", "xxl"],
    values: { xs: 0, xxs: 350, sm: 568, md: 760, lg: 960, xl: 1650, xxl: 1779 },
  },
});
const GridWithXXL = ({ xxl, ...other }) => {
  const xxlClass = `MuiGrid-grid-xxl-${xxl}`;
  return <Grid className={xxlClass} {...other} />;
};

export default function ShowProducts(props) {
  const classes = styles();
  const [id, setId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState();
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState([]);
  const [clickedChildCategories, setClickedChildCategories] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [searchWords, setSearchWords] = useState([]);
  const [parents, setParents] = useState([])
  const categoriesFromRedux = useSelector(s=>s.categories)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    // console.log(`currentCategory`, currentCategory)
    // console.log(`clickedChildCategories`, clickedChildCategories)
    categoriesFromRedux && currentCategory && setParents(getParents(categoriesFromRedux, currentCategory))
  },[currentCategory, categoriesFromRedux])

  return (
    <>
      <Banner link="/products.png" page={Names.products[props.flag]} gradient={"none"} textColor={"#5273C4"} />
      <section className={classes.section}>
        <div className={allStyles.breadcrumbs}>
          <div className={allStyles.breadcrumbscont}>
            <div style={{ margin: "10px 0px 30px 0px" }}>
              { 
              // parents && parents.length ?
              // MakeBreadcrumbs(parents.map(item=>({name: item.name[props.flag]})), props.flag)
              // :
              MakeBreadcrumbs([{ name: Names.products[props.flag] }], props.flag)
              }
            </div>
          </div>
        </div>
        <div className={allStyles.filters}>
          <MuiThemeProvider theme={theme}>
            <Grid container spacing={0}>
              <GridWithXXL item xxl={3} xl={3} lg={3} xs={4}>
                <div className={allStyles.filtersContainer}>
                  <StickyBox offsetTop={120} offsetBottom={10} style={{zIndex: "1", width: "281px"}} >
                    {/* <div
                      style={{
                        margin: "0px 0px 27px 0px",
                        padding: "4px 8px 4px 18px ",
                        border: "1px solid rgba(152, 152, 152, 0.1)",
                        borderRadius: "9px",
                      }}
                    >
                      <Input
                        id="standard-adornment-search"
                        onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                            setSearchWord(e.target.value);
                          }
                        }}
                        disableUnderline
                        fullWidth
                        placeholder="Search anything..."
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                setSearchWord(
                                  document.getElementById(
                                    "standard-adornment-search"
                                  ).value
                                );
                              }}
                            >
                              {<Search />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div> */}
                    <CategoriesList
                      clickedChildCategories={clickedChildCategories}
                      setClickedChildCategories={setClickedChildCategories}
                      setCurrentCategory={setCurrentCategory}
                      id={id}
                      setId={setId}
                      flag={props.flag}
                    />
                    <BrandsList
                      selectedBrand={selectedBrand}
                      setSelectedBrand={setSelectedBrand}
                      flag={props.flag}
                    />
                    <BadgesList
                      selectedBadge={selectedBadge}
                      setSelectedBadge={setSelectedBadge}
                      flag={props.flag}
                    />
                  </StickyBox>
                </div>
              </GridWithXXL>
              <GridWithXXL item xxl={9} xl={9} lg={9} xs={8}>
                <ShowProductsGrid
                  searchWord={searchWord}
                  setSearchWord={setSearchWord}
                  searchWords={searchWords}
                  setSearchWords={setSearchWords}
                  selectedBadge={selectedBadge}
                  setSelectedBadge={setSelectedBadge}
                  selectedBrand={selectedBrand}
                  setSelectedBrand={setSelectedBrand}
                  currentCategory={currentCategory}
                  setCurrentCategory={setCurrentCategory}
                  clickedChildCategories={clickedChildCategories}
                  setClickedChildCategories={setClickedChildCategories}
                  id={id}
                  setId={setId}
                  flag={props.flag}
                />
              </GridWithXXL>
              {/* <Grid item xs={12}>
                <div style={{ marginTop: "100px", marginBottom: "50px" }}>
                  <LastSeen flag={props.flag} />
                </div>
              </Grid> */}
            </Grid>
          </MuiThemeProvider>
        </div>
        <div className={allStyles.products}>
          <ShowProductsGrid
            // setQuickView={setQuickView}
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            searchWords={searchWords}
            setSearchWords={setSearchWords}
            clickedChildCategories={clickedChildCategories}
            setClickedChildCategories={setClickedChildCategories}
            selectedBadge={selectedBadge}
            setSelectedBadge={setSelectedBadge}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
            id={id}
            setId={setId}
            flag={props.flag}
          />
        </div>
      </section>
      <div style={{backgroundColor:"#EAEAEA"}} >
        <div style={{ width:"100%", margin: "50px auto 0px", padding:"50px 0px"}}>
          <LastSeen flag={props.flag} />
         </div>
      </div>
    </>
  );
}
