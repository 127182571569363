import React, { useEffect } from 'react';
import style from "./downloads.module.css"

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowSelect from "./icons/arrowIcon/PathComponent"
import { Names } from '../../staticTexts';




export default function ControlledOpenSelect(props) {
  const [categories, setCategories] = React.useState([])


  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/Categories`, {
      credentials: "include"
    }).then(data => {
      return data.json()
    }).then((data => {
      data = data.filter(item => {
        return item.type === "Category" && item.name[props.flag]
      })
      setCategories(data)
    }))
  }, [props.flag])





  const handleChange = v => {
    if(v && v.id){
      props.setSelectedCategory(v.id);
    }else{
      props.setSelectedCategory(null);
    }
    
  };



  return (
    <div style={{ width: "100%" }}>
      {/* <FormControl style={{ width: "100%" }} className={classes.formControl}> */}

      <Autocomplete
        popupIcon={<ArrowSelect />}
        id="combo-box-demo"
        // disableClearable
        options={categories}
        getOptionLabel={option => option.name[props.flag]}
        onChange={(e,v)=>handleChange(v)}
        
        renderInput={(params) => <div className={style.ForSelects} ><TextField 
          {...params} 
          placeholder={Names.categorySingle[props.flag]}
          variant="standard" 
          InputProps={{...params.InputProps, disableUnderline: true}}
          />
          </div>
          }
 
        // renderInput={(params) => (
          
        //   <TextField
        //     {...params}
        //     label="Search input"
        //     margin="normal"
        //     variant="outlined"
        //     InputProps={{ ...params.InputProps, type: 'search' }}
        //   />
        // )}
      />

{/* 
        <Select
          value={props.selectedCategory}
          style={{ width: "100%", height:"39px" }}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          disableUnderline
          inputProps={{ 'aria-label': 'Without label' }}
          onkeyDown={(e)=>{IgnoreAlpha(e)}}
          
        >
          <MenuItem value={null}>
            <div style={{ display: "flex", backgroundColor:"#fff", width: "100%",height:"39px",marginLeft:"-8px", alignItems: "center" }}>
            <input 
                // onKeyUp={(e)=>{e.stopPropagation(); e.preventDefault(); handleKeys(e)}}
                onKeyDown={(e)=>{ handleKeys(e); IgnoreAlpha(e) }}
                onClick={(e)=>{e.stopPropagation()}}
                autoFocus
                value={search}
                onChange={(e)=>{setSearch(e.target.value)}}
                className={style.searchSelect}
                placeholder={props.flag === "us" ? "Search" : props.flag === "ru" ? "Поиск" : "Որոնել"}
            />
            <button className={style.iconButton} onClick={(e)=>{e.preventDefault();}}>
                                <FiSearch />
                            </button>
            </div>
          </MenuItem>
          <MenuItem value={null}>
            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <span style={{
                color: "lightgray",
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                fontStyle: "italic",
                fontWeight: "lighter",
                fontSize:"14px",
                paddingLeft:"20px"
              }}> {props.flag === "us" ? "Category" : props.flag === "ru" ? "Kатегория" : "Կատեգորիա"} </span>
            </div>
          </MenuItem>
          {categories.map(category => {

            if(search){
              if(category.name[props.flag].toLocaleLowerCase().includes(search.toLocaleLowerCase())){ 
              return <MenuItem value={category.id}>
                <div style={{ display: "flex", paddingLeft:"20px", width: "100%", alignItems: "center" }}>
                  {category.name[props.flag]}
                </div>
              </MenuItem>
              }else{
              return null 
              }
            }else{
             return <MenuItem value={category.id}>
              <div style={{ display: "flex", paddingLeft:"20px", width: "100%", alignItems: "center" }}>
                {category.name[props.flag]}
              </div>

            </MenuItem>
            }
          }
          )}
        
        </Select> */}
      {/* </FormControl> */}
    </div>
  );
}