import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import { useSelector } from 'react-redux';
import { getAllChildsIds, getParents } from '../../Functions';
import SearchIcon from '@material-ui/icons/Search';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function Chips({brands, setBrands, id, setId, badges, setBadges, categories, setCategories, clickedChildCategories, setClickedChildCategories, flag, searchs, setSearchs }) {
  const classes = useStyles();
  const allBrands = useSelector(s=>s.brands)
  const allBadges = useSelector(s=>s.badges)
  const allCategories = useSelector(s=>s.categories)

  const [brandChips, setBrandChips] = useState()
  const [badgeChips, setBadgeChips] = useState()
  const [categoryChips, setCategoryChips] = useState()
  const [searchsChips, setSearchChips] = useState();


//   const handleClick = useCallback((key, category) => {
//     props.setId(key);
//     props.setCurrentCategory(category);
//     props.setClickedChildCategories(getAllChildsIds(category));
//   },[props.id]);


  const handleDelete = (id, type, category) => {
    let clone;
        switch (type) {
            case "brand":
                clone = JSON.parse(JSON.stringify(brands))
                clone = clone.filter(item=>item!==id)
                setBrands(clone)
                break;
            case "badge":
                clone = JSON.parse(JSON.stringify(badges))
                clone = clone.filter(item=>item!==id)
                setBadges(clone)
                break;
            case "searchs":
                clone = JSON.parse(JSON.stringify(searchs))
                clone = clone.filter(item=>item!==id)
                setSearchs(clone)
                break;
            case "category":
                if(category.parentId === "null"){
                    console.log(`category`, category)
                    setId(null);
                    setCategories(null);
                    setClickedChildCategories([]);

                }else{
                    let parent = allCategories.find(item=>item.id === category.parentId)
                    setId(category.parentId)
                    setClickedChildCategories(allCategories.filter(item=>item.parentId === parent.id).map(item=>item.id));
                    setCategories(parent)

                }
                break;
            default:
                break;
        }
    };


  useEffect(()=>{
    if(allBrands && brands){
        setBrandChips(brands.map(item=>allBrands.find(it=>it.id === item)))
    }
  }, [brands])
  useEffect(()=>{
    if(allBadges && badges){
        setBadgeChips(badges.map(item=>allBadges.find(it=>it.id === item)))
    }
  }, [badges])
  useEffect(()=>{
    if(searchs){
        setSearchChips(searchs.map(item=>item))
    }
  }, [searchs])
  useEffect(()=>{
      if(categories===null)setCategoryChips([]) 
      if(allCategories && categories){
          console.log(`categories`, categories)
          if(categories.parentId === "null") {
            setCategoryChips([categories]);
        }else{
            let cats = getParents(allCategories, categories)
            console.log(`cats`, cats)
            setCategoryChips(cats)
        }
    }
  }, [categories])

  return (<>
  {brandChips && !!brandChips.length && brandChips.map(item=>
      <Chip
        avatar={<Avatar alt={item.name[flag]} src={`${process.env.REACT_APP_API_URL}/originals/${item.Image}`} />}
        label={item.name[flag]}
        onDelete={()=>{handleDelete(item.id, "brand")}}
      />
  )
  }
  {badgeChips && !!badgeChips.length && badgeChips.map(item=>
      <Chip
        avatar={<LabelImportantIcon />}
        label={item.name[flag]}
        onDelete={()=>{handleDelete(item.id, "badge")}}
      />
  )
  }
  {categoryChips && !!categoryChips.length && categoryChips.map(item=>
      <Chip
        avatar={<Avatar alt={item.name[flag]} src={`${process.env.REACT_APP_API_URL}/originals/${item.Image}`} />}
        label={item.name[flag]}
        onDelete={()=>{handleDelete(item.id, "category", item)}}
      />
  )
  }
  {searchsChips && !!searchsChips.length && searchsChips.map(item=>
      <Chip
        avatar={<SearchIcon/>}
        label={item}
        onDelete={()=>{handleDelete(item, "searchs")}}
      />
  )
  }
  </>
  );
}
