import React from 'react'
import style from "./noproductsFound.module.css"
import langs from "../../stylesForLangs/langs.module.css"
import { useSelector } from 'react-redux'

export default function NoProductsFound(props) {
    const flag = useSelector(s=>s.flag)
    return (
        <div className={style.container}>
            {/* <span className={style.img} style={{backgroundImage:`url("${notfound}")`}} /> */}
            {/* <img src={notfound} className={style.img} alt="not found"/> */}
            <span className={`${style.text} ${ flag === "us" ? langs.BarlowMedium : langs.DejaVuSans}`}>{props.flag==="us" ? "NO RESULT FOUND" : props.flag==="ru" ? "РЕЗУЛЬТАТОВ НЕ НАЙДЕНО" : "ՈՉ ՄԻ ԱՐԴՅՈՒՆՔ ՉԻ ԳՏՆՎԵԼ"}</span>
        </div>
    )
}
