/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import style from "../ProductInfo.module.css"
const PathComponent = ({ svgRef }) => (
  <svg width="30" height="56" viewBox="0 0 30.299 56.501" ref={svgRef}>
    {/* <defs>
      <style>{".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}"}</style>
    </defs> */}
    <path
      className={style.back}
      d="M3.835,28,29.125,2.708A1.585,1.585,0,1,0,26.884.467L.466,26.884a1.58,1.58,0,0,0,0,2.242L26.884,55.531A1.6,1.6,0,0,0,28,56a1.545,1.545,0,0,0,1.115-.469,1.58,1.58,0,0,0,0-2.242Z"
      transform="translate(29.94 56.251) rotate(180)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
