/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PathComponent = ({ svgRef }) => (
  <svg width={7} height={13} viewBox="0 0 7.697 13.981" ref={svgRef}>
    <defs>
      <style>{".arrright{fill:#2a2a2a;stroke:#2a2a2a;stroke-width:0.5px;}"}</style>
    </defs>
    <path
      className="arrright"
      d="M.923,6.74,7.011.652a.382.382,0,0,0-.54-.54L.112,6.472a.38.38,0,0,0,0,.54l6.359,6.356a.384.384,0,0,0,.268.113.372.372,0,0,0,.268-.113.38.38,0,0,0,0-.54Z"
      transform="translate(7.409 13.731) rotate(180)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
