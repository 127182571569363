
import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
// import "./image-gallery.css";
import ReactImageMagnify from "react-image-magnify";

function MyReactImageMagnify(props) {

  const [dimensions, setDimensions] = useState({width: "180%", height: "130%"})
  // const [dimensions, setDimensions] = useState({width: "300%", height: "300%"})

    useEffect(()=>{
      let timeoutId;
      let percentWidth = Math.floor( window.innerWidth * 0.094)
      if(window.innerWidth <= 600){
        setDimensions({
          width: `0%`,
          height: "0%",
      })
      }else{
        setDimensions({
            width: `${percentWidth}%`,
            height: dimensions.height,
        })
      }
        function getDimensions(){
            let percentWidth = Math.floor( window.innerWidth * 0.094)
            timeoutId && clearInterval(timeoutId)
            timeoutId = setTimeout(()=>{
              if(window.innerWidth <= 600){
                setDimensions({
                  width: `0%`,
                  height: "0%",
              })
              }else{
                setDimensions({
                    width: `${percentWidth}%`,
                    height: dimensions.height,
                })
              }
            },500)
        }


        window.addEventListener("resize", getDimensions)

        return ()=>{
            window.removeEventListener("resize", getDimensions)
        }
    },[])

    return (
      <ReactImageMagnify
        {...{
          smallImage: {
            alt: "Wristwatch by Ted Baker London",
            isFluidWidth: true,
            src: props.item.thumbnail
          },
          largeImage: {
            src: props.item.original,
            width: 1524,
            height: 1524
          },
          enlargedImagePortalId: "myPortal",
          isEnlargedImagePortalEnabledForTouch: false,
          enlargedImageContainerDimensions: dimensions,
        }}
      />
    );
}




export default function MyComponent(props) {

  function _handleImageError(event) {
      if (this.props.defaultImage &&
          event.target.src.indexOf(this.props.defaultImage) === -1) {
        event.target.src = this.props.defaultImage;
      }
    }
  
   function _myImageGalleryRenderer(item) {
      // your own image error handling
  
      return (
        <div className='image-gallery-image'>
           <MyReactImageMagnify item={item} />

          {/* <img
              src={item.original}
              alt={item.originalAlt}
              srcSet={item.srcSet}
              sizes={item.sizes}
              onLoad={this.props.onImageLoad}
              onError={onImageError.bind(this)}
          /> */}
  
          {/* {
            item.description &&
              <span className='image-gallery-description'>
                {item.description}
                {
                  item.link && item.linkLabel &&
                    <a className='my-own-class' href={item.link}>
                      {item.linkLabel}
                    </a>
                }
              </span>
          } */}
        </div>
      )
    }
  
      const items = props.images.map(item=>{
          return  {
            original: `${process.env.REACT_APP_API_URL}/${"1024x1024"}/${item}`,
            thumbnail: `${process.env.REACT_APP_API_URL}/${"480x480"}/${item}`,
          }
      })       
      return <div>
          <ImageGallery
                  slideDuration={200}
                  thumbnailPosition={
                    window.innerWidth < 601 ? "bottom" : "left"
                  }
                  showFullscreenButton={false}
                  showPlayButton={false}
                  // className="imageGallery"
                  renderItem={_myImageGalleryRenderer}
                  showNav={false}
                  items={items}
                />
        {/* <ImageGallery items={items} renderItem={_myImageGalleryRenderer}/> */}
      </div>
  }