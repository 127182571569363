import { Button, Grid, TextField } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import styles from './mainServices.module.css'
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import loadData from '../../../helpers/loadData';
import { useHistory } from 'react-router';

export default function MainServices({flag}) {
    const history = useHistory()
    const [images, setImages] = useState({am:["","","",""],us:["","","",""],ru:["","","",""]})
    const [paths, setPaths] = useState({am:["","","",""],us:["","","",""],ru:["","","",""]})
    

    useEffect(()=>{
        loadData(`staticPages`, JSON.stringify({id:"mainServices"}))
        .then(res=>{
            res[0].content.images && setImages(res[0].content.images)
            res[0].content.paths && setPaths(res[0].content.paths)
        })
    },[])
    
    function handleChange(e, index, flag){
        let newPaths = paths[flag];
        newPaths.splice(index, 1, e.target.value) 
        setPaths({...paths, [flag]: newPaths})
    }

   


    return (
        <div className={styles.section} >
        <div className={styles.container}>
            <div onClick={()=>{history.push(paths[flag][0])}} className={`${styles.img} ${styles.imgL}`} style={{backgroundImage: `url("${process.env.REACT_APP_API_URL}/originals/${images[flag][0]}")`}} >            </div>
            <div className={styles.gridCont}>
            <Grid container spacing={2} >
                <Grid item xs={6}>
                    <div onClick={()=>{history.push(paths[flag][1])}} className={`${styles.img} ${styles.imgS}`} style={{backgroundImage: `url("${process.env.REACT_APP_API_URL}/originals/${images[flag][1]}")`}} >                    
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div onClick={()=>{history.push(paths[flag][2])}} className={`${styles.img} ${styles.imgS}`} style={{backgroundImage: `url("${process.env.REACT_APP_API_URL}/originals/${images[flag][2]}")`}} >                    
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div onClick={()=>{history.push(paths[flag][3])}} className={`${styles.img} ${styles.imgM}`} style={{backgroundImage: `url("${process.env.REACT_APP_API_URL}/originals/${images[flag][3]}")`}} >                    
                    </div>
                </Grid>
            </Grid>
            </div>
        </div>
        </div>
    )
}

