import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowBackIosIcon from './arrowIcons/ArrowLeft';
import ArrowForwardIosIcon from './arrowIcons/PathComponent';
// import loadData from "./helpers/loadData"

import Slide from '@material-ui/core/Slide';
import style from "./ProductInfo.module.css"
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton"



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog(props) {
  const handleClose = () => {
    props.setOpen({bool:false, index:null});
  };
 
return (
    <Dialog
    BackdropProps={{
        style:{
            backgroundColor:"rgba(0, 0, 0, 0.5)"
        }
    }}
    PaperProps ={{
        style: {
                backgroundColor: "transparent",
                // boxShadow: "none",
                overflow: "hidden"
         }
      }}
    // style={{backgroundColor:"inherit"}}
      open={props.open.bool}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      style={{height:"100vh", width:"100%"}}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{height:"80vh", width:"100%"}} >
          <div style={{width:"100%", height:"90%", display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}}>
              <IconButton onClick={handleClose} style={{position:"absolute", color:"white", top:"0", right:"10px", outline:"none"}} >
                <CloseIcon color="inherit" />
              </IconButton>
              <span className={style.arrow}  onClick={()=>{props.setOpen({bool:true, index:props.open.index - 1>=0 ? props.open.index - 1 : props.images.length-1 })}}  >
                <ArrowBackIosIcon />
              </span>
              <div style={{
                  width:"70%",
                  height:"95%",
                  backgroundImage:`url("${process.env.REACT_APP_API_URL}/${props.images[props.open.index]}")`,
                  backgroundRepeat:"no-repeat",
                  backgroundSize:"contain",
                  backgroundPosition:"center",
                  }} />
            {/* <img maxWidth="100%" maxHeight="100%" src={`${process.env.REACT_APP_API_URL}/${props.images[props.open.index]}`} /> */}
            <span className={style.arrowRight}onClick={()=>{props.setOpen({bool:true, index:props.open.index + 1<props.images.length ? props.open.index + 1 : 0 })}}>
                <ArrowForwardIosIcon />
              </span>
              
          </div>
          <div style={{width:"100%", height:"10px", display:"flex",justifyContent:"center", alignItems:"center"}} >
                {props.images.map((item, i)=>{
                    return <span onClick={()=>{props.setOpen({bool:true, index: i})}} style={props.open.index===i ? 
                        {marginLeft: i===0 ? 0 : "10px", height:"15px", width:"15px", border:"1px solid white", borderRadius:"50%", backgroundColor:"inherit"} 
                        : 
                        {marginLeft:i===0 ? 0 : "10px", height:"15px", width:"15px", border:"1px solid white", borderRadius:"50%", backgroundColor:"white"}} />
                })}
              </div>
      </DialogContent>

    </Dialog>
);
}
