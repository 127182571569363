import { useEffect, useRef } from "react";
import langs from "../stylesForLangs/langs.module.css"
import {dates} from "./dates"


export function maketree(arr, flag, tree = {}, haveprop = undefined, name, icon) {
    if (!haveprop) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].parentId === "null") { tree[arr[i].id] = { name: arr[i].name[flag], icon: arr[i].icon }; }
      }
    }
    // if (arr[0].parentId == null) { tree[arr[0].id] = { name: arr[0].name[flag] }; arr.splice(0, 1); }
    if (haveprop !== undefined) { tree[haveprop] = { name, icon } }
    let keys = Object.keys(tree);
    for (let key of keys) {
      for (let elem of arr) {
        if (elem.parentId === key && elem.name[flag]) {
          tree[key] = { ...tree[key], ...maketree(arr, flag, tree[key], elem.id, elem.name[flag], elem.icon) };
        }
      }
    }
    return tree;
}

export function getSubArrs(arr, n) {
    let count = 0;
    let subArrs = [];
    if (arr.length > n) {
        for (let i = 0; i < arr.length; i++) {
            if (!Array.isArray(subArrs[count])) {
                subArrs[count] = [];
            }
            if (subArrs[count].length < n) {

                subArrs[count].push(arr[i]);
            } else {
                count += 1;
                if (!Array.isArray(subArrs[count])) {
                    subArrs[count] = [];
                }
                subArrs[count].push(arr[i]);

            }
        }
    } else {
        subArrs = [arr]
    }
    return subArrs;
}

export function getLangStyle(flag, type){
  let result = flag==="am" ? "armenian" : "foreign"
  switch (type){
    case "h":
      result += "Headers600" 
      break;
    case "t":
      result+="Headers"
      break;
    case "m":
      result += "HeadersMedium" 
      break;
    default:
      result += "Headers600" 
  }
  return langs[result]
}

export function getAllChildsIds(category, ids = []) {
  let keys = Object.keys(category)
  if (keys.length > 3) {
    for (let key of keys) {
      if (typeof (category[key]) === "object" && (category[key]) !== null) {
        ids.push(key)
        getAllChildsIds(category[key], ids)
      }
    }
  }
  return ids
}

export function getParents(arr, category, names = [category]) {
  
  let parentId = category.parentId;
  if(!parentId){
    return []
  }
  while(parentId !== "null"){
    let index = arr.find(item=>item.id === category.parentId)
    if(index){
      names.push(index)
      parentId = index.parentId;
    }
  }
    return names.reverse()
}
  
export function addDots(text, n)
{
  if(text.length > n)
  {
    text = text.substring(0,n) + "...";
  }

    return text;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export  function getDate(date, flag){
  let fullDate = new Date(date)
  let day = fullDate.getDate()
  let month = fullDate.getMonth();
  let year = fullDate.getFullYear();
  return `${day} ${dates[flag][month]} ${year}`
}