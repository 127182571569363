import React from 'react';
// import { styles } from "./styleShowCategories"
import style from  "./cardFORaRelated.module.css"
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom"
import langstyle from '../../stylesForLangs/langs.module.css'






const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FBFBFB',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


export default function OutlinedCard(props) {
  const { product, flag } = props;
  const imageSizeOriginal = "480x480/"



  return <>{product && <Link style={{ textDecoration: "none" }} to={product && product.path}>
      <div className={style.card}>
        <div className={style.imgcontainer}>
          <img className={style.cardImg} alt="product" src={`${process.env.REACT_APP_IMAGES_URL}/${imageSizeOriginal}/${Array.isArray(product.Image) ? product.Image[0] : product.Image}`} />
        </div>
        <p
          className={`${props.flag === "am" ? langstyle.armenianHeaders600 : langstyle.foreignHeaders600} ${style.productName}`}
        >
          {product.name[flag].length>17 ? product.name[flag].substr(0,10)+"..." : product.name[flag] }
        </p>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Short Text</Typography>
              <span className={`${props.flag === "us" ? langstyle.NunitoRegular : langstyle.dejavuExtraLightWF} ${style.productName}`} >
                {product.shortText[flag]}
              </span>
            </React.Fragment>
          }
        >
          <p
            className={`${props.flag === "us" ? langstyle.NunitoRegular : langstyle.dejavuExtraLightWF} ${style.productShortText}`}
          >
            {product.shortText[flag] && product.shortText[flag].substr(0, 15) + "..."}

          </p>
        </HtmlTooltip>
      </div>
    </Link>
  }</>
}