// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./ttf/ARM/montserrat arm Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./ttf/ARM/montserrat arm Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./ttf/ARM/montserrat arm Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./ttf/RUS/Montserrat-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./ttf/RUS/Montserrat-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./ttf/RUS/Montserrat-Medium.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Montserrat_arm_R';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n    font-family: 'Montserrat_arm_B';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n    font-family: 'Montserrat_arm_M';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n\n@font-face {\n    font-family: 'Montserrat_rus_R';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n    font-family: 'Montserrat_rus_B';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n@font-face {\n    font-family: 'Montserrat_rus_M';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n\n\n.ruB{\n    font-family: 'Montserrat_rus_B' !important;\n}\n.ruM{\n    font-family: 'Montserrat_rus_M' !important;\n}\n.ruR{\n    font-family: 'Montserrat_rus_R' !important;\n}\n\n\n.amB{\n    font-family: 'Montserrat_arm_B' !important;\n}\n.amM{\n    font-family: 'Montserrat_arm_M' !important;\n}\n.amR{\n    font-family: 'Montserrat_arm_R' !important;\n}\n\n\n.usB{\n    font-family: 'Montserrat_arm_B' !important;\n}\n.usM{\n    font-family: 'Montserrat_arm_M' !important;\n}\n.usR{\n    font-family: 'Montserrat_arm_R' !important;\n}\n\n:root{\n    --ruR: 'Montserrat_rus_R';\n    --usR: 'Montserrat_arm_R';\n    --amR: 'Montserrat_arm_R';\n}\n\n\n\n\n\nbody {\n    /* display: flex;\n    justify-content: center; */\n    font-family: 'Montserrat_arm_R';\n    margin:0;\n    overflow: visible !important;\n    /* font-size: 0.85rem; */\n    /* letter-spacing: 0.04px; */\n}\n/* *{\n    font-family: DejaVuSans !important;\n} */\ninput::placeholder{\n    font-style: italic !important;\n}\n\n\nbutton{\n    cursor: pointer;\n}\n.MuiPaper-elevation16{\n    box-shadow: none !important;\n}\n.MuiTab-wrapper{\n    text-transform: none !important;\n}\n\n\n", ""]);
// Exports
module.exports = exports;
