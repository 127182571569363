/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = ({ svgRef }) => (
  <svg width={30} height={30} viewBox="0 0 24.993 30.016" ref={svgRef}>
    <defs>
      <style>{".mobileDrawer{fill:#32406E;}"}</style>
    </defs>
    <path
      className="mobileDrawer"
      d="M48.584,28.748a12.511,12.511,0,0,0-12.5,12.5c0,10.227,11.738,17.084,12.237,17.371l.259.148.259-.148c.5-.287,12.237-7.144,12.237-17.371A12.51,12.51,0,0,0,48.584,28.748Zm0,28.808c-1.726-1.07-11.456-7.5-11.456-16.312a11.456,11.456,0,1,1,22.913,0C60.04,50.056,50.309,56.486,48.584,57.556Z"
      transform="translate(-36.087 -28.748)"
    />
    <path
      className="mobileDrawer"
      d="M60.038,46.271a6.416,6.416,0,1,0,6.416,6.416A6.424,6.424,0,0,0,60.038,46.271Zm0,11.792a5.376,5.376,0,1,1,5.376-5.375A5.382,5.382,0,0,1,60.038,58.063Z"
      transform="translate(-47.542 -40.195)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <GroupComponent svgRef={ref} {...props} />
));
export default ForwardRef;
