/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import styles from "../../style.module.css"

const NounProductsComponent = ({ svgRef }) => (
  <svg className={styles.a} viewBox="0 0 52.688 52.688" ref={svgRef}>
    <g transform="translate(-16 -17)">
      <g transform="translate(16 17)">
        <path
        className={styles.a}
          d="M35.187,17H20.8A4.821,4.821,0,0,0,16,21.8V36.265a4.821,4.821,0,0,0,4.8,4.8H35.265a4.821,4.821,0,0,0,4.8-4.8V21.8A4.94,4.94,0,0,0,35.187,17Zm3.249,19.187a3.269,3.269,0,0,1-3.249,3.249H20.8a3.269,3.269,0,0,1-3.249-3.249V21.8A3.269,3.269,0,0,1,20.8,18.547H35.265A3.269,3.269,0,0,1,38.514,21.8V36.187Z"
          transform="translate(-16 -17)"
        />
        <path
        className={styles.a}
          d="M72.188,17H57.8A4.821,4.821,0,0,0,53,21.8V36.265a4.821,4.821,0,0,0,4.8,4.8H72.265a4.821,4.821,0,0,0,4.8-4.8V21.8A4.94,4.94,0,0,0,72.188,17Zm3.249,19.187a3.269,3.269,0,0,1-3.249,3.249H57.8a3.269,3.269,0,0,1-3.249-3.249V21.8A3.269,3.269,0,0,1,57.8,18.547H72.265A3.269,3.269,0,0,1,75.514,21.8V36.187Z"
          transform="translate(-24.374 -17)"
        />
        <path
        className={styles.a}
          d="M72.188,54H57.8A4.821,4.821,0,0,0,53,58.8V73.265a4.821,4.821,0,0,0,4.8,4.8H72.265a4.821,4.821,0,0,0,4.8-4.8V58.8A4.94,4.94,0,0,0,72.188,54Zm3.249,19.188a3.269,3.269,0,0,1-3.249,3.249H57.8a3.269,3.269,0,0,1-3.249-3.249V58.8A3.269,3.269,0,0,1,57.8,55.547H72.265A3.269,3.269,0,0,1,75.514,58.8V73.188Z"
          transform="translate(-24.374 -25.374)"
        />
        <path
        className={styles.a}
          d="M35.187,54H20.8A4.821,4.821,0,0,0,16,58.8V73.265a4.821,4.821,0,0,0,4.8,4.8H35.265a4.821,4.821,0,0,0,4.8-4.8V58.8A4.94,4.94,0,0,0,35.187,54Zm3.249,19.188a3.269,3.269,0,0,1-3.249,3.249H20.8a3.269,3.269,0,0,1-3.249-3.249V58.8A3.269,3.269,0,0,1,20.8,55.547H35.265A3.269,3.269,0,0,1,38.514,58.8V73.188Z"
          transform="translate(-16 -25.374)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounProductsComponent svgRef={ref} {...props} />
));
export default ForwardRef;
