/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounMonthlycalendarComponent = ({ svgRef }) => (
  <svg width="23" height="23" viewBox="0 0 25.133 24.053" ref={svgRef}>
    <defs>
      <style>{".aasd{fill:#9F1A1E;stroke:#9F1A1E;stroke-width:0.3px;}"}</style>
    </defs>
    <g transform="translate(0.15 0.15)">
      <path
        className="aasd"
        d="M10.486,958.362a.54.54,0,0,0-.54.54v3.315a2.159,2.159,0,1,0,1.08,0V958.9A.54.54,0,0,0,10.486,958.362Zm11.876,0a.54.54,0,0,0-.54.54v3.315a2.159,2.159,0,1,0,1.08,0V958.9A.54.54,0,0,0,22.363,958.362Zm-15.655,1.62A2.714,2.714,0,0,0,4,962.689v16.718a2.714,2.714,0,0,0,2.708,2.708H26.125a2.714,2.714,0,0,0,2.708-2.708V962.689a2.714,2.714,0,0,0-2.708-2.708H24.514a.54.54,0,1,0,0,1.08h1.611a1.615,1.615,0,0,1,1.628,1.628v4.85H5.08v-4.85a1.615,1.615,0,0,1,1.628-1.628H8.319a.54.54,0,1,0,0-1.08Zm5.93,0a.54.54,0,0,0,0,1.08H20.2a.54.54,0,0,0,0-1.08Zm-2.151,3.239a1.08,1.08,0,1,1-1.08,1.08A1.072,1.072,0,0,1,10.486,963.221Zm11.876,0a1.08,1.08,0,1,1-1.08,1.08A1.072,1.072,0,0,1,22.363,963.221Zm-17.283,5.4H27.753v10.788a1.614,1.614,0,0,1-1.628,1.628H6.708a1.614,1.614,0,0,1-1.628-1.628Z"
        transform="translate(-4 -958.362)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounMonthlycalendarComponent svgRef={ref} {...props} />
));
export default ForwardRef;
