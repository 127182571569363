import React from "react"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import usFlag from "./flagIcons/usFlag.png"
import ruFlag from "./flagIcons/ruFlag.png"
import amFlag from "./flagIcons/amFlag.png"
import { useSelector, useDispatch } from "react-redux"
import {CHANGE_FLAG} from "../redux/actions/languageAction/languageAction"

import { styles } from "./style";
import "./style.css"
import Arrow from "./flagIcons/arrow/DownArrowComponent"

export default function SelectLanguage(props){
    const dispatch = useDispatch()
    const classes = styles();
    const flag = useSelector(state => state.flag)
    
    function handleChange(value){
        dispatch(CHANGE_FLAG(value.target.value))
     }
                
    
    
    
    
    return           <Select
                        value={flag}
                        disableUnderline
                        open={props.langOpen}
                        // margin="dense"
                        style={ {padding:"0px",}}
                        onChange={handleChange}
                        displayEmpty
                        MenuProps={{
                            disableScrollLock: true
                          }}
                        IconComponent={()=>{
                            if(props.langOpen)
                            {
                                return <span  className="rightrot"> <Arrow  footer={props.footer}/></span>
                            } else{
                                return <Arrow style={{cursor:"pointer"}} footer={props.footer} />
                            }
                        }}
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label',}}
                     >
                        <MenuItem value={"us"}> 
                        <img height="20px" style={{marginRight:"5px"}} src={usFlag} alt="flag" />
                         Eng
                         </MenuItem>
                        <MenuItem value={"ru"}> 
                        <img height="22px" style={{marginRight:"5px"}} src={ruFlag} alt="flag" />
                        Рус
                         </MenuItem>
                        <MenuItem value={"am"}> 
                        <img height="22px" style={{marginRight:"5px"}} src={amFlag} alt="flag" />
                         Հայ
                         </MenuItem>
                     </Select>
}