/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounLocationComponent = ({ svgRef }) => (
  <svg width={33} height={52} viewBox="0 0 43.547 52.298" ref={svgRef}>
    <defs>
      <style>{".abc{fill:#fff;}"}</style>
    </defs>
    <g transform="translate(-36.087 -28.748)">
      <path
        className="abc"
        d="M57.861,28.748A21.8,21.8,0,0,0,36.087,50.522c0,17.819,20.452,29.766,21.322,30.266l.451.259.452-.259c.869-.5,21.322-12.448,21.322-30.266A21.8,21.8,0,0,0,57.861,28.748Zm0,50.194C54.854,77.078,37.9,65.875,37.9,50.521a19.961,19.961,0,0,1,39.922,0C77.822,65.875,60.867,77.078,57.861,78.942Z"
        transform="translate(0 0)"
      />
      <path
        className="abc"
        d="M64.8,46.271A11.179,11.179,0,1,0,75.98,57.451,11.192,11.192,0,0,0,64.8,46.271Zm0,20.545a9.366,9.366,0,1,1,9.366-9.366A9.377,9.377,0,0,1,64.8,66.816Z"
        transform="translate(-6.941 -6.936)"
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounLocationComponent svgRef={ref} {...props} />
));
export default ForwardRef;
