import React, { useEffect, useState, useRef } from "react";
import { Fade } from "@material-ui/core";
import { FiSearch } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import "./styleHeader.css";
import ProductsHover from "./ProductsHover";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import style from "./searchBar.module.css";
import StyleSearchforMobile from "./searchBarMobile.module.css";
import Popper from "./popper";
import Langs from "../../stylesForLangs/langs.module.css";
import { useLocation } from "react-router-dom";
import IconEmail from "./icons/mail/NounMailComponent";
import IconPhoneCall from "./icons/call/NounPhoneComponent";
import MenuIcon from "./icons/menuIcon/NounMenuComponent";
import MenuClose from "./icons/menuCloseIcon/NounCancelComponent";
import HeaderMenu from "./headerforMobileOpen";
import { Names } from "../../staticTexts";
import ChangeFlag from "../../languageChange/languageChange";
import SearchIcon from "./icons/search/NounSearchComponent";
import SearchBar from "./searchbar/searchbar"
import LanguageChangeForMobile from "../../languageChange/languageChangeForMobile.js"

export default function Header(props) {
  const history = useHistory();
  const [langOpen, setLangOpen] = useState(false);

  const [searchClick, setSearchClick] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchClickMobile, setSearchClickMobile] = useState(false);
  const headerRef = useRef();

  // useEffect(()=>{
  //     const headerpos = ()=>{if(window.scrollY<5){headerRef.current.style.top="33px";}else{headerRef.current.style.top="0px";}}
  //     if(headerRef.current){
  //         window.addEventListener("scroll", headerpos )
  //     }
  //     return ()=>{
  //         window.removeEventListener("scroll",headerpos)
  //     }
  // },[headerRef.current])

  // const [flag, setFlag] = useState("ru");

  return (
    <header ref={headerRef} className={"header"}>
      <div className={"headerChildContayner"}>
        {window.innerWidth < 600 ? (
          !searchClickMobile ? (
            <Link to="/">
              <img
                className={"logo"}
                alt="logo"
                src={`${process.env.REACT_APP_API_URL}/static/LOGO-Unitech.png`}
              />
            </Link>
          ) : null
        ) : (
          <Link to="/" style={{ marginRight: "20px" }}>
            <img
              className={"logo"}
              alt="logo"
              src={`${process.env.REACT_APP_API_URL}/static/LOGO-Unitech.png`}
            />
          </Link>
        )}
        {!searchClickMobile && (
          <div className={"subHeaderTablet"}>
            <div style={{ display: "contents" }}>
              <span className={"subheaderText"}>
                <IconEmail />
                &nbsp;
                <a
                  style={{ textDecoration: "none", color: "#5A5959" }}
                  href={`mailto:${props.contacts && props.contacts.mail}`}
                >
                  {props.contacts && props.contacts.mail}
                </a>
              </span>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <span className={"subheaderText"}>
                <a
                  className={"subheaderText"}
                  style={{ textDecoration: "none", color: "#5A5959" }}
                  href={`tel:${props.contacts && props.contacts.phone}`}
                >
                  <IconPhoneCall /> &nbsp;{" "}
                  {props.contacts && props.contacts.phone}
                </a>
              </span>
            </div>
            {/* <span>
                <span onClick={()=>{setLangOpen(!langOpen)}}>
                    &nbsp;
                    <ChangeFlag langOpen={langOpen} setLangOpen={setLangOpen} />
                </span>
            </span> */}
          </div>
        )}

        <div className="phoneMail">
          <div className={"HeaderMenu"}>
            <HeaderMenu
              contacts={props.contacts}
              flag={props.flag}
              open={menuOpen}
              setOpen={setMenuOpen}
            />
          </div>
        </div>

        {/* <div
          className="drawerLangContainer"
          style={searchClickMobile ? { width: "100%" } : {}}
        >
          <div
            className="searchBarContainerMobile"
            style={searchClickMobile ? { width: "100%" } : {}}
          >
            <ClickAwayListener
              onClickAway={() => {
                searchClickMobile && setSearchClickMobile(false);
              }}
            >
              <div
                className={
                  searchClickMobile === true
                    ? StyleSearchforMobile.searchbarContainerEnd
                    : searchClickMobile === "animEnd"
                    ? StyleSearchforMobile.searchbarContainerended
                    : StyleSearchforMobile.searchbarContainerstart
                }
                style={{
                  border: searchClickMobile
                    ? "1px solid rgba(112, 112, 112, 0.3)"
                    : "none",
                }}
              >
                {searchClickMobile && (
                  <input
                    autoFocus
                    placeholder={
                      props.flag === "us"
                        ? "Search..."
                        : props.flag === "ru"
                        ? "Поиск..."
                        : "Որոնել..."
                    }
                    className={StyleSearchforMobile.searchInputEnd}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && search) {
                        props.setSearch(search);
                        history.push("/SearchResult");
                      }
                    }}
                  />
                )} */}
                {/* <button
                  className={StyleSearchforMobile.iconButton}
                  style={{ outline: "none" }}
                  onClick={() => {
                    if (searchClickMobile !== "animEnd") {
                      setSearchClickMobile(!searchClickMobile);
                      setTimeout(() => {
                        setSearchClickMobile("animEnd");
                      }, 500);
                    } else if (search) {
                      props.setSearch(search);
                      history.push("/SearchResult");
                    } else if (!search) {
                      setSearchClickMobile(!searchClickMobile);
                    }
                  }}
                >
                  <FiSearch width={20} height={20} />
                </button> */}
              {/* </div>
            </ClickAwayListener>
          </div> */}
          <div
          className="drawerLangContainer"
          style={searchClickMobile ? { width: "100%" } : {}}
        >
          <LanguageChangeForMobile/>
          {menuOpen ? (
              <button
                style={{
                  backgroundColor: "inherit",
                  border: "none",
                  padding: "5px 0px 5px 12px",
                  outline: "none",
                }}
                onClick={() => {
                  setMenuOpen(false);
                }}
              >
                <MenuClose />
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: "inherit",
                  border: "none",
                  padding: "5px 0px 5px 12px",
                  outline: "none",
                }}
                onClick={() => {
                  setMenuOpen(true);
                }}
              >
                <MenuIcon />
              </button>
            )}
            </div>
        {/* </div> */}

        <div className="headerButtonsContainer">
          <Fade in={!searchClick}>
            <div className={"headerButtons"}>
              <Link style={{ textDecoration: "none" }} to="/Products">
                <ProductsHover
                  classNameprop={
                    props.flag === "am" &&
                    location.pathname.toLocaleLowerCase().includes("products")
                      ? `${Langs.armenianTexts600} activeButton`
                      : props.flag === "am" &&
                        !location.pathname
                          .toLocaleLowerCase()
                          .includes("products")
                      ? `${Langs.armenianTexts600} buttons`
                      : location.pathname
                          .toLocaleLowerCase()
                          .includes("products")
                      ? `${Langs.foreignHeaders600} activeButton`
                      : `${Langs.foreignHeaders600} buttons`
                  }
                  lineHeight={props.lineHeight}
                  hover={props.hover}
                  flag={props.flag}
                />
              </Link>
              <Link to="/services">
                <button
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className={
                    props.flag === "am" &&
                    location.pathname.toLocaleLowerCase().includes("services")
                      ? `${Langs.armenianTexts600} activeButton`
                      : props.flag === "am" &&
                        !location.pathname
                          .toLocaleLowerCase()
                          .includes("services")
                      ? `${Langs.armenianTexts600} buttons`
                      : location.pathname
                          .toLocaleLowerCase()
                          .includes("services")
                      ? `${Langs.foreignHeaders600} activeButton`
                      : `${Langs.foreignHeaders600} buttons`
                  }
                >
                  {Names.footer.Services[props.flag]}
                </button>
              </Link>
              <Link to="/downloads">
                <button
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className={
                    props.flag === "am" &&
                    location.pathname.toLocaleLowerCase().includes("downloads")
                      ? `${Langs.armenianTexts600} activeButton`
                      : props.flag === "am" &&
                        !location.pathname
                          .toLocaleLowerCase()
                          .includes("downloads")
                      ? `${Langs.armenianTexts600} buttons`
                      : location.pathname
                          .toLocaleLowerCase()
                          .includes("downloads")
                      ? `${Langs.foreignHeaders600} activeButton`
                      : `${Langs.foreignHeaders600} buttons`
                  }
                >
                  {Names.download[props.flag]}
                </button>
              </Link>

              <Link style={{ textDecoration: "none" }} to="/AboutUs">
                <Popper
                  classNameprop={
                    props.flag === "am" &&
                    (location.pathname
                      .toLocaleLowerCase()
                      .includes("aboutus") ||
                      location.pathname.includes("news"))
                      ? `${Langs.armenianTexts600} activeButton`
                      : props.flag === "am" &&
                        !(
                          location.pathname
                            .toLocaleLowerCase()
                            .includes("aboutus") ||
                          location.pathname.includes("news")
                        )
                      ? `${Langs.armenianTexts600} buttons`
                      : location.pathname
                          .toLocaleLowerCase()
                          .includes("aboutus") ||
                        location.pathname.includes("news")
                      ? `${Langs.foreignHeaders600} activeButton`
                      : `${Langs.foreignHeaders600} buttons`
                  }
                  flag={props.flag}
                  button={{ us: "About Us", ru: "О нас", am: "Մեր մասին" }}
                  children={[
                    {
                      button: { us: "About Us", ru: "О нас", am: "Մեր մասին" },
                      path: "/aboutus",
                    },
                    {
                      button: {
                        us: "News",
                        ru: "Новости",
                        am: "Նորություններ",
                      },
                      path: "/news",
                    },
                    {
                      button: { us: "Career", ru: "Карьера", am: "Կարիերա" },
                      path: "/jobs",
                    },
                    {
                      button: Names.footer.Contacts,
                      path: "/Contacts",
                    },
                  ]}
                />
              </Link>

              {/* <Link style={{ textDecoration: "none" }} to="/Services">
                        <Popper
                            classNameprop={props.flag === "am" && location.pathname.toLocaleLowerCase().includes("services") ? `${Langs.armenianTexts600} activeButton` :
                                props.flag === "am" && !location.pathname.toLocaleLowerCase().includes("services") ? `${Langs.armenianTexts600} buttons` :
                                    location.pathname.toLocaleLowerCase().includes("services") ? `${Langs.foreignHeaders600} activeButton` : `${Langs.foreignHeaders600} buttons`}
                            flag={props.flag}
                            button={Names.footer.Services}
                        />
                    </Link> */}
              {/* <Link to="/jobs">
                        <button onClick={() => { window.scrollTo(0, 0) }}
                            className={props.flag === "am" && location.pathname.toLocaleLowerCase().includes("job") ? `${Langs.armenianTexts600} activeButton` :
                                props.flag === "am" && !location.pathname.toLocaleLowerCase().includes("job") ? `${Langs.armenianTexts600} buttons` :
                                    location.pathname.toLocaleLowerCase().includes("jobs") ? `${Langs.foreignHeaders600} activeButton` :
                                        `${Langs.foreignHeaders600} buttons`}
                        >{Names.Job[props.flag]}</button>
                    </Link> */}

              {/* <Link to="/contacts">
                <button
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className={
                    props.flag === "am" &&
                    location.pathname.toLocaleLowerCase().includes("contacts")
                      ? `${Langs.armenianTexts600} activeButton`
                      : props.flag === "am" &&
                        !location.pathname
                          .toLocaleLowerCase()
                          .includes("contacts")
                      ? `${Langs.armenianTexts600} buttons`
                      : location.pathname
                          .toLocaleLowerCase()
                          .includes("contacts")
                      ? `${Langs.foreignHeaders600} activeButton`
                      : `${Langs.foreignHeaders600} buttons`
                  }
                >
                  {Names.footer.Contacts[props.flag]}
                </button>
              </Link> */}
            </div>
          </Fade>
        </div>
        <div className={"search_language"} >

          <SearchBar
            searchClick={searchClick}
            setSearchClick={setSearchClick}
            search={search}
            setSearch={setSearch}
          />
          <span
            className="languageChangeContainer"
            onClick={() => {
              setLangOpen(!langOpen);
            }}
          >
            <ChangeFlag langOpen={langOpen} setLangOpen={setLangOpen} />
          </span>
        </div>
      </div>
    </header>
  );
}
