import React from 'react'
import style from "./banner.module.css"

export default function banner({page, link, gradient, textColor}) {
    return (
        <div className={style.banner}>
            <span className={style.pageName} style={{color: textColor ? textColor : "#C7D8E5"}} >{page}</span>
            <img className={style.img} height="100%" src={link} alt="banner" />
            { gradient==="none" ? null : <div className={style.gradient} style={{background: `linear-gradient(180deg, rgba(196, 196, 196, 0) ${gradient ? gradient : "0%"}, #477FB4 100%)`}} />}
        </div>
    )
}
