/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounPhoneComponent = ({ svgRef }) => (
  <svg width={22} height={15} viewBox="0 0 27.559 27.56" ref={svgRef}>
    <defs>
      <style>{".am,.bm{fill:none;stroke:#fff;}.a{fill-rule:evenodd;}"}</style>
    </defs>
    <g transform="translate(0.51 0.5)">
      <g transform="translate(0 0)">
        <path
          className="am"
          d="M6.108,41.966,8.28,39.459a1.033,1.033,0,0,0,.052-1.3L5.24,33.945a1.026,1.026,0,0,0-1.135-.384c-3.082.9-5.039,3.6-3.641,7.426A28.47,28.47,0,0,0,16.5,57.024c3.828,1.4,6.522-.559,7.426-3.641a1.026,1.026,0,0,0-.384-1.135l-4.214-3.092a1.033,1.033,0,0,0-1.3.052L15.522,51.38a20.211,20.211,0,0,1-9.413-9.413Z"
          transform="translate(-0.005 -30.934)"
        />
        <path
          className="bm"
          d="M177.724,12.657a1.093,1.093,0,1,0,2.186,0A12.656,12.656,0,0,0,167.253,0a1.093,1.093,0,1,0,0,2.186,10.47,10.47,0,0,1,10.471,10.471Z"
          transform="translate(-153.361 0)"
        />
        <path
          className="bm"
          d="M173.48,63.613a1.093,1.093,0,1,0,2.186,0,8.405,8.405,0,0,0-8.4-8.4,1.093,1.093,0,0,0,0,2.186,6.22,6.22,0,0,1,6.217,6.217Z"
          transform="translate(-153.37 -50.957)"
        />
        <path
          className="bm"
          d="M169.267,114.6a1.09,1.09,0,0,0,2.181.006,4.142,4.142,0,0,0-4.151-4.151,1.09,1.09,0,0,0,.006,2.181,1.975,1.975,0,0,1,1.964,1.964Z"
          transform="translate(-153.407 -101.942)"
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounPhoneComponent svgRef={ref} {...props} />
));
export default ForwardRef;
