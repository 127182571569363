import React, { useState, useEffect } from 'react';
import loadData from "../../helpers/loadData"
import Card from "../ShowProducts/Card_for_product_redesign"
import { styles } from "./styleShowCategories"
import SwipeableViews from 'react-swipeable-views';
import "../bodyMain/ShowBrands.css"
import allstyles from "../bodyMain/MainPageProducts/ShowProductsfive.module.css";
import langs from "../../stylesForLangs/langs.module.css"
import {Names} from "../../staticTexts"
import QuickView from "../ShowProducts/QuickView/QuickView";
import classnames from "./lastSeenProducts1.module.css"












export default function ScrollableTabsButtonAuto(props) {
    const classes = styles();
    const [index, setIndex] = useState(0);
    const [brands, setBrands] = useState([[]])
    const [count, setCount] = useState(3)
    const [allSeen, setAllSeen] = useState([])
    const [quickView, setQuickView] = useState({ bool: false, product: null });

    useEffect(() => {
        let relatedids = JSON.parse(localStorage.getItem("lastSeen"))
        relatedids && loadData("Categories", JSON.stringify({ id: { $in: [...relatedids] } })).then((res) => {
            setAllSeen(res)
            setBrands(renderAllBrands(res, resize()))
            
        })
        return ()=>{
            window.removeEventListener("resize", resize)
        }
    }, [])

    useEffect(() => {
        setBrands(renderAllBrands(allSeen, count))
        resize()
    }, [count, allSeen])


    function resize() {
        if (window.innerWidth <= 600) {
            setCount(2)
            setIndex(0)
            return 2
        } else if (window.innerWidth < 1300) {
            setCount(3)
            setIndex(0)
            return 3
        } else if (window.innerWidth > 1300 && window.innerWidth < 1920) {
            setCount(4)
            setIndex(0)
            return 4
        } else if (window.innerWidth >= 1920) {
            setCount(7) 
            setIndex(0)
            return 7
        }
    }
    window.addEventListener('resize', resize)


    const renderAllBrands = (brand, n = 6) => {
        if (!brand.length) { return }
        let count = 0
        let render = [[]];
        for (let i = 0; i <= brand.length; i++) {
            if (render[count] && render[count].length === n) {
                count += 1;
            }
            if (!Array.isArray(render[count])) {
                render[count] = [];
            }
            brand[i] && render[count].push(brand[i]);
        }
        render= render.filter(item=>item.length)
        return render;
    }

    const handleChangeIndex = (index) => {
        setIndex(index);
    };

    return (
        <section className={classes.section} style={{ margin:"0", backgroundColor: "#EAEAEA"}} >
            <QuickView flag={props.flag} open={quickView} setOpen={setQuickView} />
            <div style={{paddingBottom: "25px", maxWidth:"1366px", margin:"0px auto"}} className={`${allstyles.lastseen}  ${props.flag === "us" ? langs.BarlowBold : langs.DejaVuSansWF}`} >
                {brands && brands[0] && brands[0].length && Names && Names.LastSeenProducts && Names.LastSeenProducts[props.flag]}
            </div>
            <div className={`${classes.products} ${classnames.prductsContainer}`} >
                {/* <AutoPlaySwipeableViews axis="x-reverse" slideStyle={{ overflow: "hidden" }} disableLazyLoading>
                    {brands && brands.length && renderAllBrands(brands).map((item, i) => {
                            return <Grid container spacing={2}>
                                {item.map((product) => {
                                    return <Grid item lg={2} md={4} sm={6} xs={12}>
                                        <Card flag={props.flag} product={product} />
                                    </Grid>
                                })}
                            </Grid>
                        })}
                        </AutoPlaySwipeableViews> */}
                {/* <AutoPlaySwipeableViews axis="x-reverse" index={index} onChangeIndex={handleChangeIndex} slideStyle={{ overflow: "hidden" }} style={{ width: "100%" }} disableLazyLoading> */}
                    <SwipeableViews index={index} onChangeIndex={handleChangeIndex} disableLazyLoading enableMouseEvents slideStyle={{ overflow: "hidden"}} style={{ width:"100%" }} >
                    {brands && brands[0] && brands[0].length && brands.map(brand => {
                        return <div style={(brand.length === 3 && count === 3) || (brand.length === 4 && count === 4) || (brand.length === 4 && count === 4) || (brand.length >=7) ? { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" } : { display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%" }} >
                            {brand.map((br) => {
                                return  <div
                                    key={br.id}
                                    className={classnames.cardContainerForHover}
                                >
                                    <Card quickView={quickView} setQuickView={setQuickView} flag={props.flag} product={br} />
                                </div>

                            })}
                        </div>

                    })}
                    </SwipeableViews>
                {/* </AutoPlaySwipeableViews> */}
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-5px",
                marginTop: "27px"
            }}>
                {brands && brands.length>1 && brands.map((item, i) => {
                    return <span className={index === i ? "downButtonsClicked" : "downButtons"} onClick={() => { setIndex(i) }} />
                })}

                {/* <span className={clicked===1 ? "downButtonsClicked" : "downButtons"} onClick={()=>{setClicked(1)}} /> */}
            </div>
        </section>
    )
}

