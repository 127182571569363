/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NounEmailComponent = ({ svgRef }) => (
  <svg width={39} height={19} viewBox="0 0 39.591 19.858" ref={svgRef}>
    <defs>
      <style>{".eeewww{fill:#9f1a1e;}"}</style>
    </defs>
    <path
      className="eeewww"
      d="M18.453,46.158H38.479a3.826,3.826,0,0,0,3.812-3.812V30.112A3.826,3.826,0,0,0,38.479,26.3H18.453a3.826,3.826,0,0,0-3.812,3.812V42.346A3.854,3.854,0,0,0,18.453,46.158ZM15.688,30.112a2.762,2.762,0,0,1,2.765-2.765H38.479a2.762,2.762,0,0,1,2.765,2.765V42.346a2.762,2.762,0,0,1-2.765,2.765H18.453a2.762,2.762,0,0,1-2.765-2.765Zm3.016,11.4,6.41-5.656-6.368-4.944a.507.507,0,1,1,.628-.8l9.133,7.08L37.6,30.071a.507.507,0,0,1,.628.8L31.943,35.81l6.368,5.7a.521.521,0,0,1,.042.712.455.455,0,0,1-.377.168.476.476,0,0,1-.335-.126l-6.536-5.823-2.3,1.8a.405.405,0,0,1-.587,0l-2.22-1.718-6.619,5.74a.476.476,0,0,1-.335.126.455.455,0,0,1-.377-.168A.519.519,0,0,1,18.7,41.508Zm-6.075-5.279a.484.484,0,0,1-.5.5H6.219a.5.5,0,0,1,0-1.005h5.907A.509.509,0,0,1,12.629,36.229ZM2.7,32.291a.484.484,0,0,1,.5-.5h8.882a.5.5,0,1,1,0,1.005H3.245A.5.5,0,0,1,2.7,32.291Zm9.929,7.876a.484.484,0,0,1-.5.5H8.23a.5.5,0,1,1,0-1.005h3.9A.483.483,0,0,1,12.629,40.167Z"
      transform="translate(-2.7 -26.3)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <NounEmailComponent svgRef={ref} {...props} />
));
export default ForwardRef;
