/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = ({ svgRef }) => (
  <svg width={42} height={52} viewBox="0 0 52.767 52.775" ref={svgRef}>
    <defs>
      <style>{".ads{fill:#fff;}"}</style>
    </defs>
    <path
      className="ads"
      d="M69.131,55.984a6.252,6.252,0,0,1,1.8,4.719c0,3.6-2.921,5.842-5.393,8.089A121.07,121.07,0,0,0,82.613,85.646c2.247-2.247,4.494-5.393,8.089-5.393s11.235,6.741,11.235,11.235c0,3.6-3.82,6.741-6.292,8.988-4.269,3.82-12.583.449-20.9-5.842a92.011,92.011,0,0,1-18.2-17.976c-6.292-8.763-9.662-17.3-5.618-21.347C59.693,46.772,60.816,47.67,69.131,55.984Zm-1.124,1.124c-7.865-7.865-8.089-8.539-15.954-.674C48.682,59.8,51.828,67.669,57.9,75.759A88.592,88.592,0,0,0,75.647,93.51c7.865,5.842,15.5,8.988,19.1,5.842,2.022-1.8,5.618-4.943,5.618-7.865,0-3.6-6.966-9.662-9.662-9.662-3.146,0-6.067,3.82-8.089,5.842a116.7,116.7,0,0,1-18.875-19.1c2.022-2.022,5.842-4.719,5.842-7.865A4.471,4.471,0,0,0,68.007,57.108Z"
      transform="translate(-49.17 -49.314)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <GroupComponent svgRef={ref} {...props} />
));
export default ForwardRef;
