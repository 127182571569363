import { makeStyles } from '@material-ui/core/styles';



export const styles = makeStyles(theme => ({
    headerButtons: {
        display:"flex",
        flexWrap:"nowrap",
        justifyContent:"center",
        alignItems:"center",
        outline: "none",
        padding: "16px 2vw",
        // width:"207px",
        // outerHeight:"100%",
        margin: "0",
        border: "none",
        backgroundColor: "inherit",
        transitionDuration:"0.5s",
        fontSize:  "calc(8px + (26 - 14) * ((100vw - 300px) / (1600 - 300)))",
        lineHeight:  "calc(1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)))",
        
        color:"#5A5959",
        // boxShadow: "0 0 14px -2 black",
        '&:hover': {
            // transitionProperty:"backgroundColor",
            backgroundColor:"#FF6565",
            color:"#fff",
            // boxShadow: "0 0 7px -4px black"
        }
    },
    select:{
        paddingRight:"0px"
    },
    clickedHeaderButton: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
        padding: "16px 2vw",
        margin: "0",
        color: "#FF6565",
        backgroundColor: "inherit",
        transitionDuration:"0.5s",
        border: "none",
        borderBottom: "1px solid #FF6565",
        fontSize:  "calc(8px + (26 - 14) * ((100vw - 300px) / (1600 - 300)))",
        lineHeight:  "calc(1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)))",
        '&:hover': {
            backgroundColor: "#FF6565",
            color: "#fff",
        }
    }

}))


