/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PathComponent = ({ svgRef }) => (
  <svg width={11} height={11} viewBox="0 0 11.283 6.104" ref={svgRef}>
    <defs>
      <style>{".rrow{fill:#878181;stroke:#878181;stroke-width:0.2px;}"}</style>
    </defs>
    <path
      className="rrow"
      d="M.759,5.541,5.764.536A.314.314,0,1,0,5.321.092L.092,5.321a.313.313,0,0,0,0,.444L5.321,10.99a.316.316,0,0,0,.221.093.306.306,0,0,0,.221-.093.313.313,0,0,0,0-.444Z"
      transform="translate(0.1 5.979) rotate(-90)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
