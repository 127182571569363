// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.hover_content__qUWno{\n    max-width:1266px; \n    height:312px; \n    background-color:#fff; \n    margin: 7px auto;\n    border:1px solid lightgray;\n    position: relative;\n    -webkit-user-select: none;\n            user-select: none;\n    overflow: hidden;\n}\n.hover_categoryName__3Jinm{\n    color: #3D3D3D;\n    font-size: 18px;\n    font-weight: 500;\n    border: none;\n    background-color: inherit;\n    display: flex;\n    align-items: center;\n    transition-duration: 0.2s;\n}\n.hover_buttons__3qn9e{\n    max-width: 1266px;\n    margin: 50px auto 10px;\n    display: flex;\n    justify-content: flex-end;\n}\n.hover_childButton__147kv{\n    border: none;\n    background-color: inherit;\n    transition-duration: 0.2s;\n    text-align: left;\n    line-height: 22px;\n    font-size: 16px;\n    letter-spacing: 0.03em;\n    font-weight: 300;\n    color: #7e7e7e;\n}\n.hover_childButton__147kv:hover{\n    color:#9F1A1E;\n    padding-left: 70px !important;\n}\n.hover_categoryName__3Jinm:hover{\n    transform: scale(1.1);\n}\n.hover_categoryButton__2j07l{\n    border: none;\n    background-color: inherit;\n    transition-duration: 0.2s;\n}\n.hover_categoryButton__2j07l:hover{\n    color:#9F1A1E,\n}", ""]);
// Exports
exports.locals = {
	"content": "hover_content__qUWno",
	"categoryName": "hover_categoryName__3Jinm",
	"buttons": "hover_buttons__3qn9e",
	"childButton": "hover_childButton__147kv",
	"categoryButton": "hover_categoryButton__2j07l"
};
module.exports = exports;
