import React,{useEffect} from 'react'
import style from './singleJob.module.css'
import DateIcon from './DateIcon/NounMonthlycalendarComponent'
import TimeIcon from "./timeIcon/NounTimeComponent"
import { useHistory } from 'react-router-dom'
import langs from "../../stylesForLangs/langs.module.css"


const JobTypes = [
    { am: "Լրիվ դրույք", us: "Full Time", ru: "НА ПОСТОЯННОЙ ОСНОВЕ" },
    { am: "Կես դրույք", us: "Part Time", ru: "Неполная занятость" },
    { am: "Ազատ գրաֆիկ", us: "Free schedule", ru: "свободный график" },
]


export default function CardForJob(props) {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


    return <section style={{minHeight:"86vh"}}>
        {props.job && <div className={style.container}>
            <span className={`${style.title} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
                {props.job.title[props.flag]} 
                
                
            </span>
            <div style={{display:"flex", marginTop:"10px"}}>
            <span className={`${style.date} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
                    <DateIcon />
                &nbsp;&nbsp;
                {props.job.Deadline}
                </span>
                <span className={`${style.date} ${props.flag==="am" ? langs.armenianHeaders600 : langs.foreignHeaders600}`}>
                    <TimeIcon />
                &nbsp;&nbsp;
                {JobTypes[props.job.JobType - 1][props.flag]}
                </span>    
            </div>
            <span className={`${style.content} ${props.flag==="am" ? langs.armenianTexts : langs.foreignHeaders}`}>
                {/* <Dotdotdot clamp={2}> */}
                {props.job.content[props.flag]}
                {/* </Dotdotdot> */}
            </span>
            {!!props.job.skills && <div className={style.skills} style={{padding:"5px 0px 0px 10px", marginTop:"10px"}}>
            {/* <div className={style.title}>
                {props.flag === "us" ? "Skills" : props.flag === "ru" ? "Мастерство" : props.flag === "Պահանջներ"}
            </div> */}
                {!!props.job.skills[props.flag].length && props.job.skills[props.flag].map(item=>{
                    return <li>
                        {item}
                    </li>
                })}
            </div>}
            <button className={style.button} onClick={()=>{props.job.path && history.push(`/contacts`)}} >
                {props.flag === "us" ? "APPLY" : props.flag==="ru" ? "ПРИМЕНЯТЬ" : "ԴԻՄԵԼ"}
                </button>
        </div>}
        
    </section>
}
