// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".banner_banner__3D4wj{\n    width:100%;\n    height: 415px;\n    margin-top: 85px;\n    position: relative;\n}\n\n.banner_pageName__1BLeK{\n    position: absolute;\n    top: 135px;\n    left: 100px;\n    /*   */\n    font-size: 48px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 70px;\n    letter-spacing: 0.1em;\n    text-align: left;\n    color: #C7D8E5;\n}\n\n.banner_img__1zlYq{\n    width: 100%;\n    object-fit: cover;\n\n}\n\n.banner_gradient__RY1q5 {\n    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #477FB4 100%);\n    width: 100%;\n    height: 100%;\n    top: 0;\n    position: absolute;\n}\n\n@media only screen and (max-width:600px){\n    .banner_banner__3D4wj{\n        display: none;\n    }\n}", ""]);
// Exports
exports.locals = {
	"banner": "banner_banner__3D4wj",
	"pageName": "banner_pageName__1BLeK",
	"img": "banner_img__1zlYq",
	"gradient": "banner_gradient__RY1q5"
};
module.exports = exports;
