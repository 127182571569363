import  {makeStyles} from '@material-ui/core/styles';


export const styles = makeStyles(theme => ({
    section: {
        padding: 0,
        margin: 0,
        // height: "auto",
        // width: "100%",
        // maxWidth: "1366px", 
        // margin:"0 auto",
        overflowX:"hidden",
        // backgroundImage: `url("${slideImage}")`,
        // backgroundSize: "contain",
    },
      Image:{
          margin:0,
          padding: 0,
        
       }
    
    
}))