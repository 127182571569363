export const dates = {
    us:{
   "0":"January",
   "1":"February",
   "2":"March",
   "3":"April",
   "4":"May",
   "5":"June",
   "6":"July",
   "7":"August",
   "8":"September",
   "9":"October",
    "10":"November",
    "11":"December",
    },
    
     
     
    ru:{
   "0":"Январь",
   "1":"февраль",
   "2":"март",
   "3":"апрель",
   "4":"май",
   "5":"июнь",
   "6":"июль",
   "7":"август",
   "8":"сентябрь",
   "9":"октябрь",
    "10":"ноябрь",
    "11":"декабрь",
    },
     
     
    am:{
   "0":"հունվար",
   "1":"փետրվար",
   "2":"մարտ",
   "3":"ապրիլ",
   "4":"մայիս",
   "5":"հունիս",
   "6":"հուլիս",
   "7":"օգոստոս",
   "8":"սեպտեմբեր",
   "9":"հոկտեմբեր",
    "10":"նոյեմբեր",
    "11":"դեկտեմբեր",
    }}
 

 

 

 

 

 

 

 

 

 

 

 