/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";
import allstyles from "../../../bodyMain/MainPageProducts/ShowProductsfive.module.css"

const PathComponent = ({ svgRef }) => (
  <svg width={4.802} height={8.535} viewBox="0 0 4.802 8.535" ref={svgRef}>
    {/* <defs>
      <style>{".aaa{fill:#a8a8a8;stroke:#a8a8a8;stroke-width:0.5px;}"}</style>
    </defs> */}
    <path
      className={allstyles.aaa}
      d="M113.063,4.017,116.692.389a.227.227,0,0,0-.322-.322l-3.79,3.79a.227.227,0,0,0,0,.322l3.79,3.789a.229.229,0,0,0,.16.067.222.222,0,0,0,.16-.067.227.227,0,0,0,0-.322Z"
      transform="translate(-112.234 0.25)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
