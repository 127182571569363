// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".lastSeenProducts1_prductsContainer__2I26D{\n    padding: 0px 80px\n}\n\n.lastSeenProducts1_cardContainerForHover__XnODO{\n    width: 243px;\n    height: 325px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* background-color: teal; */\n}\n\n@media only screen and (max-width: 640px){\n    .lastSeenProducts1_prductsContainer__2I26D{\n        padding: 0px 20px\n    }\n}", ""]);
// Exports
exports.locals = {
	"prductsContainer": "lastSeenProducts1_prductsContainer__2I26D",
	"cardContainerForHover": "lastSeenProducts1_cardContainerForHover__XnODO"
};
module.exports = exports;
