/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PathComponent = ({ svgRef }) => (
  <svg width={30} height={30} viewBox="0 0 30.011 30.016" ref={svgRef}>
    <defs>
      <style>{".mobileDrawer{fill:#32406E;}"}</style>
    </defs>
    <path
      className="mobileDrawer"
      d="M60.522,53.108a3.556,3.556,0,0,1,1.022,2.684c0,2.045-1.661,3.323-3.067,4.6a68.858,68.858,0,0,0,9.713,9.585c1.278-1.278,2.556-3.067,4.6-3.067s6.39,3.834,6.39,6.39c0,2.045-2.173,3.834-3.578,5.112-2.428,2.173-7.157.256-11.885-3.323A52.331,52.331,0,0,1,53.366,64.865c-3.578-4.984-5.5-9.841-3.195-12.141C55.155,47.868,55.794,48.379,60.522,53.108Zm-.639.639c-4.473-4.473-4.6-4.856-9.074-.383-1.917,1.917-.128,6.39,3.323,10.991a50.386,50.386,0,0,0,10.1,10.1c4.473,3.323,8.818,5.112,10.863,3.323,1.15-1.022,3.195-2.812,3.195-4.473,0-2.045-3.962-5.5-5.5-5.5-1.789,0-3.451,2.173-4.6,3.323A66.372,66.372,0,0,1,57.455,60.264c1.15-1.15,3.323-2.684,3.323-4.473A2.543,2.543,0,0,0,59.883,53.747Z"
      transform="translate(-49.17 -49.314)"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <PathComponent svgRef={ref} {...props} />
));
export default ForwardRef;
