import 'isomorphic-fetch';

export default (resourceType, id) => {
  let url= id ? `${process.env.REACT_APP_API_URL}/${resourceType}?${id}` : `${process.env.REACT_APP_API_URL}/${resourceType}` 
  return fetch(url,{
  }).catch(err=>{
    console.log(`error`, err)
  })
    .then(res => {
      
      return res.json();
    })
    .then(data => {
      return data
    });
};