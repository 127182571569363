// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n\n.style_blog__Xvx6d{\n    font-size: 30px;\n    color: #5B5B5B;\n}\n.style_containerCardForNews__R3RdP{\n    width: 303px;\n    height: 241px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 33px;\n}\n\n.style_scroll__hw3qc{\n    scroll-behavior: smooth;\n}\n.style_blogContent__2Aqr9{\n    max-width: 293px; \n    margin-left: 120px\n}\n\n.style_allcontent__PjZ-W{\n    height: -moz-fit-content;\n    height: fit-content; \n    max-width: 100%; \n    margin: 0 auto; \n    display: flex ;\n    padding-bottom: 50px;\n}\n.style_blogContentforsmall__2aK7w{\n    display: none;\n}\n.style_allContainerr__1Jh1k{\n    max-width: 1286px; \n    padding: 0px 50px; \n    margin: 100px auto 0; \n    min-height: 100vh\n}\n\n@media only screen and (max-width: 600px){\n    .style_allContainerr__1Jh1k{\n        padding: 85px 16px 0px; \n    }\n    .style_blogContent__2Aqr9{\n        display: none;\n    }\n    \n}\n\n\n@media only screen and (max-width: 1024px) and (min-width: 601px) {\n    .style_allContainerr__1Jh1k{\n        padding: 0px 20px; \n    }\n    .style_blogContent__2Aqr9{\n        display: none;\n        max-width: 1266px; \n        margin: 0;\n    }\n    .style_blogContentforsmall__2aK7w{\n        display: flex;\n        max-width: 1266px; \n        margin: 0;\n    }\n    .style_blog__Xvx6d{\n        display: none;\n    }\n    .style_allcontent__PjZ-W{\n        display: block;\n    }\n  }\n\n  @media only screen and (max-width:820px){\n    .style_blogContentforsmall__2aK7w{\n        display: none;\n    }\n}", ""]);
// Exports
exports.locals = {
	"blog": "style_blog__Xvx6d",
	"containerCardForNews": "style_containerCardForNews__R3RdP",
	"scroll": "style_scroll__hw3qc",
	"blogContent": "style_blogContent__2Aqr9",
	"allcontent": "style_allcontent__PjZ-W",
	"blogContentforsmall": "style_blogContentforsmall__2aK7w",
	"allContainerr": "style_allContainerr__1Jh1k"
};
module.exports = exports;
