import React, {useEffect, useState} from 'react';
import  "./Card_for_product copy.css"
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom"
import langstyle from '../../stylesForLangs/langs.module.css'
import {Names} from "../../staticTexts"
import Dotdotdot from 'react-dotdotdot';
import { useSelector } from 'react-redux';


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FBFBFB',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


export default function OutlinedCard(props) {
  const { product, flag } = props;
  const [badge, setBadge] = useState({})
  const badgesFromRedux = useSelector(s=>s.badges)

  useEffect(()=>{
    if(product.badge){
      setBadge(badgesFromRedux.find(x=>x.id===product.badge))
    }
  },[product])

 
  return (
    <Link style={{width:"100%", height:"100%", textDecoration: "none" }} to={product.path}>
      <div className={"card"} style={props.related ? { width: "190px", height: "213px" } : {}} >
      <span className={"productBadge"}  >
        {/* {loading ? <Circular /> :  */}
        {badge && badge.Image ?  <img src={`${process.env.REACT_APP_API_URL}/originals/${badge.Image}`} className={"productBadgeImg"} alt="Item Image" /> : null }
        </span>
        <span onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setQuickView({bool:true, product:product})}} className={`quickview `} > <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z" fill="#E75D60"/>
        </svg>
        </span>
        <div className="imgcontainer">
          <img className="cardImg" alt="product" src={`${process.env.REACT_APP_IMAGES_URL}/150x150/${ product.Image ? Array.isArray(product.Image) ? product.Image[0] : product.Image : "defaultImage.png" }`} />
        </div>
        <div className="CardNameContainer">
        <p
          className={`${flag}M productName`}
        >
          {product.name[flag]}
        </p>
        <HtmlTooltip
          title={
            <React.Fragment>
              <span className={`${flag}R productShortTextTooltip`} >
                {product.shortText[flag]}
              </span>

            </React.Fragment>
          }
        >
          <p
            className={`${flag}R productShortText`}
          >
            {/* <Dotdotdot clamp={2}>
            {product.shortText[flag] && product.shortText[flag].length>80 ? product.shortText[flag].substr(0, 80) + "..." : product.shortText[flag]}
            </Dotdotdot> */}
            {product.shortText[flag]}
          </p>
        </HtmlTooltip>
        <button className={"seeAllCard"}>
          {Names.ShowAll[flag]}
        </button>
        </div>
      </div>
    </Link>

  )
}